import "./SecurityPage.css";
import headerPng from "../src/assets/FAQ_header.png";
import { useState } from "react";


const SecurityPage = () => {


    const [selectedIssue, setSelectedIssue] = useState(0)


    const issuesArr = [
        { title: "Gizlilik", content: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsumquia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariaturSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni." },
        { title: "KVKK Adınlatma Metni", content: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsumquia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariaturSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni." },
        { title: "Kullanım Koşulları", content: " doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsumquia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariaturSed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni." }
    ]





    const iconSvg = <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7 0.96875C10.7461 0.96875 13.7812 4.00391 13.7812 7.75C13.7812 11.4961 10.7461 14.5312 7 14.5312C3.25391 14.5312 0.21875 11.4961 0.21875 7.75C0.21875 4.00391 3.25391 0.96875 7 0.96875ZM3.82812 8.95312H7V10.8945C7 11.1953 7.35547 11.332 7.54688 11.1406L10.6641 7.99609C10.8008 7.85938 10.8008 7.66797 10.6641 7.53125L7.54688 4.38672C7.32812 4.16797 7 4.33203 7 4.63281V6.54688H3.82812C3.63672 6.54688 3.5 6.71094 3.5 6.875V8.625C3.5 8.81641 3.63672 8.95312 3.82812 8.95312Z" fill="#515365" />
    </svg>

    return (
        <div id={"securityPage"}>

            <div className="header">
                <h1>Gizlilik - Güvenlik</h1>
                <img src={headerPng} />
            </div>

            <div className="body">
                <div className="titlesWrapper">
                    <div className={"firstRow"}>
                        <h2>Konu Başlıkları</h2>
                    </div>
                    <div className={"titlesList"}>
                        <div className={selectedIssue === 0 ? "selected" : ""} onClick={() => setSelectedIssue(0)}>
                            {iconSvg}
                            <p>Gizlilik</p>
                        </div>
                        <div className={selectedIssue === 1 ? "selected" : ""} onClick={() => setSelectedIssue(1)}>
                            {iconSvg}
                            <p>KVKK Adınlatma Metni</p>
                        </div>
                        <div className={selectedIssue === 2 ? "selected" : ""} onClick={() => setSelectedIssue(2)}>
                            {iconSvg}
                            <p>Kullanım Koşulları</p>
                        </div>
                    </div>
                </div>

                <div className={"content"}>
                    <h1>{issuesArr[selectedIssue].title}</h1>
                    <p>{issuesArr[selectedIssue].content}</p>
                </div>
            </div>
        </div>
    )
}
export default SecurityPage;