import { useEffect, useState } from "react";
import "./WriterSearch.css";
import SimpleSelectInput from "./components/SimpleSelectInput";
import { calcAge, cities, findZodiacFromBirthDate, isValidAndNotEmptyArray, isValidObject, zodiakArr } from "./utils/utils";
import SearchedUserRow from "./components/SearchedUserRow";
import { modalStore, pendingStore, userStore } from "./Store";
import { dataBase } from "./fireBaseConfig";
import { child, equalTo, get, orderByChild, orderByValue, query, ref } from "firebase/database";
import SimpleInput from "./components/SimpleInput";
import { Pagination } from "@mui/material";
// import writerSearchSvg from "./assets/writerSearch.svg";

const WriterSearch = () => {
    const setPending = pendingStore((state) => state.setPending)
    const user = userStore((state) => state.user)

    const [selectedZodiak, setSelectedZodiak] = useState("");
    const [selectedGender, setSelectedGender] = useState("");
    const [selectedCity, setSelectedCity] = useState("");
    const [minAge, setMinAge] = useState(0);
    const [maxAge, setMaxAge] = useState(100);
    const [page, setPage] = useState(1);
    const [filteredUsers, setFilteredUsers] = useState([])
    const rowCountByPage = 4

    const setModal = modalStore(state => state.setModal)

    const filterUsers = async () => {
        setPending(true)
        if (isValidObject(selectedCity) && isValidObject(selectedZodiak)) {

            const messagesRef = query(ref(dataBase, `users/`),
                orderByChild("city"),
                equalTo(selectedCity?.value),
            )
            const snapshot = await get(messagesRef)

            let dummyFilteredUsers = []
            if (snapshot.exists()) {
                dummyFilteredUsers = Object.entries(snapshot.val())
            } else {
                dummyFilteredUsers = []
            }

            if (dummyFilteredUsers.length > 0) {
                // Kişinin kendisini çıkar
                dummyFilteredUsers = dummyFilteredUsers.filter((item) => item[0] !== user.uid)
                // Burçlara göre
                dummyFilteredUsers = dummyFilteredUsers.filter((item) => findZodiacFromBirthDate(item[1]?.birthDate) === selectedZodiak.label)
                // Yaşa göre
                dummyFilteredUsers = dummyFilteredUsers.filter((item) => Number(calcAge(item[1]?.birthDate)) > Number(minAge))

                // Cinsiyete göre
                if (isValidObject(selectedGender)) {
                    dummyFilteredUsers = dummyFilteredUsers.filter((item) => item[1]?.gender === selectedGender.value)
                }
            } else {
                dummyFilteredUsers = []
            }
            setFilteredUsers(dummyFilteredUsers)

            if (dummyFilteredUsers.length === 0) {
                setModal({
                    type: "warning",
                    status: true,
                    message: "Aradığınız kriterlerde hiç kimse yok.",
                })
            } else if (dummyFilteredUsers.length > 0) {
                setModal({
                    type: "success",
                    status: true,
                    message: `Aradığınız kriterlerde ${dummyFilteredUsers.length} kişi bulundu`,
                })
            }


        } else {
            setFilteredUsers([])
            setModal({
                type: "error",
                status: true,
                message: "Şehir ve burç seçmeniz gerekiyor.",
            })
        }
        setPending(false)
    }

    const genderArr = [{ value: "male", label: "erkek" }, { value: "feMale", label: "kadın" }]



    const writerSearchSvg = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M440-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T520-640q0-33-23.5-56.5T440-720q-33 0-56.5 23.5T360-640q0 33 23.5 56.5T440-560ZM884-20 756-148q-21 12-45 20t-51 8q-75 0-127.5-52.5T480-300q0-75 52.5-127.5T660-480q75 0 127.5 52.5T840-300q0 27-8 51t-20 45L940-76l-56 56ZM660-200q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm-540 40v-111q0-34 17-63t47-44q51-26 115-44t142-18q-12 18-20.5 38.5T407-359q-60 5-107 20.5T221-306q-10 5-15.5 14.5T200-271v31h207q5 22 13.5 42t20.5 38H120Zm320-480Zm-33 400Z" /></svg>

    return (

        <div id={"writerSearchPageWrapper"}>
            <div className={"container"}>
                <div className={"filterContainer"}>
                    <div className={"filterWrapper"}>
                        <SimpleSelectInput
                            options={genderArr}
                            set={setSelectedGender}
                            selectedOption={selectedGender.label}
                            defaultValue={""}
                            placeholder={"Cinsiyet"}
                            isDeletable={true}
                        />
                    </div>
                    <div className={"filterWrapper"}>
                        <SimpleSelectInput
                            options={zodiakArr}
                            set={setSelectedZodiak}
                            selectedOption={selectedZodiak.label}
                            isDeletable={true}
                            placeholder={"Burç"}
                            defaultValue={""}
                        />
                    </div>
                    <div className={"filterWrapper"}>
                        <SimpleSelectInput
                            options={cities}
                            set={setSelectedCity}
                            isDeletable={true}
                            placeholder={"Şehir"}
                            defaultValue={""}
                            selectedOption={selectedCity.label}
                        />
                    </div>
                    <div className={"ageSideWrapper"}>
                        <p>Yaş Aralığı</p>
                        <div className="ageInputWrapper">
                            <SimpleInput
                                set={setMinAge}
                                placeholder={"yaş"}
                                type={"number"}
                                value={minAge}
                            />
                        </div>
                        <p>ile</p>
                        <div className="ageInputWrapper">
                            <SimpleInput
                                set={setMaxAge}
                                placeholder={"yaş"}
                                type={"number"}
                                value={maxAge}
                            />
                        </div>
                    </div>
                    <button
                        id="writerSearchButton"
                        onClick={() => filterUsers()}
                    >

                        {writerSearchSvg}
                    </button>
                </div>

                <div className={"filteredWritersListWrapper"}>
                    {
                        filteredUsers && filteredUsers.filter((item, index) => (index + 1) > (page - 1) * rowCountByPage && (index + 1) <= page * rowCountByPage).map((item) => {
                            return <SearchedUserRow userUid={item[0]} />
                        })
                    }
                </div>
                {
                    isValidAndNotEmptyArray(filteredUsers) ?
                        <Pagination
                            size={window.innerWidth && window.innerWidth <= 800 ? "large" : "medium"}
                            sx={{
                                "& ul": {
                                    gap: "15px",
                                },
                                "& li>button.Mui-selected": {
                                    backgroundColor: "#FA6342",
                                },
                                "& li>button.Mui-selected:hover": {
                                    backgroundColor: "#ff9b85",
                                },
                                "& li>button": {
                                    backgroundColor: "#C4D6ED",
                                },

                            }}
                            onChange={(e, val) => setPage(val)}
                            count={Math.ceil(filteredUsers.length / rowCountByPage)}
                            color="primary"
                        />
                        : null
                }
            </div>
        </div>
    )
}

export default WriterSearch;