import React, { useEffect, useRef, useState } from 'react'

import { usersStore, userStore, postsStore, dataBaseUserStore, profileStore, usersOnlineStatusStore, hadnleProfilePageStore, navigationStore, pictureSelectorStrore, modalStore, selectedContentStore, handleMessagePageStore, selectedSubTitleStore } from "./Store"
import { Navigate, json } from 'react-router-dom'
import { child, get, onValue, push, ref, set } from 'firebase/database'
import { dataBase, handleSignOut, storage } from './fireBaseConfig'
import SideMenu from './components/SideMenu'
import Posts from "./Posts"
import "./Home.css"
import Modal from './components/Modal'
import { getAuth, signOut } from 'firebase/auth'
import Content from './components/Content'
import ContentsSide from './components/ContentsSide'
import AboutContent from './components/AboutContent'
import ProfilePage from './ProfilePage'
import Messages from './components/Messages'
import { getDownloadURL, ref as sRef, uploadBytes } from 'firebase/storage';
import { getProfilePhotoByUid, isValidObject } from './utils/utils'
import SmallMessages from './components/SmallMessages'
import StandardErrorBoundary from './ErrorBoundary'
import { ErrorBoundary } from 'react-error-boundary'
import DesctopContentsSide from './components/DesctopContentsSide'
import { Pagination, TablePagination } from '@mui/material'
import AdSense from './components/AdSense'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import SideBar from './components/SideBar'
import AboutContentPlaceHolder from './components/PlaceHolders/AboutContentPlaceHolder'
function Home() {

    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)


    const users = usersStore((state) => state.users)
    const setUsers = usersStore((state) => state.setUsers)

    const dataBasePosts = postsStore((state) => state.posts)
    const setDataBasePosts = postsStore((state) => state.setPosts)

    const profile = profileStore((state) => state.profile)

    const setModal = modalStore((state) => state.setModal)

    const content = selectedContentStore((state) => state.content)

    const messageData = handleMessagePageStore((state) => state.messageData)

    const profileData = hadnleProfilePageStore((state) => state.profileData)
    const setProfileData = hadnleProfilePageStore((state) => state.setProfileData)

    const usersOnlineStatus = usersOnlineStatusStore((state) => state.usersOnlineStatus)
    const setUsersOnlineStatus = usersOnlineStatusStore((state) => state.setUsersOnlineStatus)



    return (
        <>
            {
                isValidObject(users)
                    ? <div id='homePage'  >
                        
                        <AdSense />

                        <div className='home-body'>
                            <div className={"aboutAndPostsWrappper"}>

                                <div id='postSideWrapper'>
                                    {
                                        isValidObject(profile) ?
                                            <AboutContent />
                                            : <AboutContentPlaceHolder />
                                    }
                                    <div id={"ScrollViewAndSendPost"}>
                                        <Posts Height="auto" />
                                    </div>
                                </div>
                            </div>
                            <div className='dummy'></div>
                            {
                                !messageData.status ?
                                    <footer>
                                        <p>© Zodyaki {new Date().getFullYear()}</p>
                                        <a href="/">Gizlilik - Güvenlik ve  KVKK</a>
                                    </footer>
                                    : null
                            }
                        </div>

                    </div>
                    : null
            }
        </>

    )
}

export default Home
