import { whichSideStore } from "../Store"
import "./WhichSideButtons.css"


const WhichSideButtons = () => {
    const setWhichSide = whichSideStore(state => state.setWhichSide)
    const whichSide = whichSideStore(state => state.whichSide)

    const venusSvg = <svg width="25" height="36" viewBox="0 0 25 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.5312 12.4062C24.5312 18.3535 20.2246 23.207 14.6875 24.2324V26.625H17.9688C18.5156 26.625 19.0625 27.1035 19.0625 27.7188V29.9062C19.0625 30.4531 18.5156 31 17.9688 31H14.6875V34.2812C14.6875 34.8281 14.1406 35.375 13.5938 35.375H11.4062C10.791 35.375 10.3125 34.8281 10.3125 34.2812V31H7.03125C6.41602 31 5.9375 30.4531 5.9375 29.9062V27.7188C5.9375 27.1035 6.41602 26.625 7.03125 26.625H10.3125V24.2324C4.70703 23.207 0.46875 18.3535 0.46875 12.4062C0.46875 5.77539 5.80078 0.375 12.5 0.375C19.1309 0.375 24.5312 5.77539 24.5312 12.4062ZM12.5 18.9688C16.0547 18.9688 19.0625 16.0293 19.0625 12.4062C19.0625 8.85156 16.0547 5.84375 12.5 5.84375C8.87695 5.84375 5.9375 8.85156 5.9375 12.4062C5.9375 16.0293 8.87695 18.9688 12.5 18.9688Z" fill="white" />
    </svg>
    const marsSvg = <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M31.6504 0.5625C32.2656 0.5625 32.8125 1.10938 32.8125 1.65625V9.38086C32.8125 10.3379 31.9922 11.0215 31.1035 11.0215C30.6934 11.0215 30.2832 10.8848 29.9414 10.543L27.959 8.49219L24.0625 12.3887C27.3438 17.0371 26.8652 23.5312 22.6953 27.7012C20.3027 30.0254 17.2266 31.1875 14.1504 31.1875C11.0059 31.1875 7.86133 30.0254 5.46875 27.4961C1.09375 23.0527 0.957031 15.7383 5.12695 11.0898C7.51953 8.42383 10.8008 7.125 14.082 7.125C16.4746 7.125 18.8672 7.80859 20.918 9.24414L24.8145 5.34766L22.7637 3.36523C21.7383 2.27148 22.4902 0.5625 23.9258 0.5625H31.6504ZM18.7988 23.8047C21.3965 21.2754 21.3965 17.1055 18.7988 14.5762C17.5 13.2773 15.8594 12.6621 14.1504 12.6621C12.4414 12.6621 10.8008 13.2773 9.50195 14.5762C6.9043 17.1055 6.9043 21.2754 9.50195 23.8047C10.8008 25.1035 12.4414 25.7188 14.1504 25.7188C15.8594 25.7188 17.5 25.1035 18.7988 23.8047Z" fill="white" />
    </svg>
    return (
        <div className='whichsideButtonsWrapper'>
            <button
                onClick={() => setWhichSide("male")}
                className={whichSide === "male" ? 'whichSideButton maleSide' : "whichSideButton"}
            >
                {marsSvg}
                {/* Erkeğe Göre */}
            </button>
            <button
                onClick={() => setWhichSide("feMale")}
                className={whichSide === "feMale" ? 'whichSideButton feMaleSide' : "whichSideButton"}
            >
                {venusSvg}
                {/* Kadına Göre */}
            </button>
        </div>
    )
}

export default WhichSideButtons