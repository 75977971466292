import "./Profile.css"
import { userStore, modalStore, dataBaseUserStore, profileStore } from '../Store';
import { useEffect, useState } from "react";
import { useRef } from "react";
import TimePicker from "./TimePicker";
import Switch from '@mui/material/Switch';

// Select Input
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import SelectorInput from "./SelectorInput";
import { child, equalTo, get, onValue, orderByChild, query, ref, set } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import { IconButton, Menu } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import zodiakSigns from "../assets/svgs";
import { isValidAndNotEmptyArray, isValidObject, zodiakIndexArr } from "../utils/utils";

// ------------

const Profile = () => {

    const [profiles, setProfiles] = useState([])
    const [isProfiles, setIsProfile] = useState(false)


    const [selectedProfile, setSelectedProfile] = useState(null)
    const profile = profileStore((state) => state.profile)
    const setProfile = profileStore((state) => state.setProfile)

    const user = userStore((state) => state.user)

    useEffect(() => {
        if (isValidObject(user.profiles) && !isValidObject(profile)) {
            setProfile(Object.entries(user.profiles)[0][1])
        }

        if (!isValidObject(user.profiles) && isValidObject(profile)) {
            setProfiles([])
            setProfile("")
            localStorage.removeItem("profile")
        }
    }, [user])




    useEffect(() => {
        if (isValidObject(profile) && isValidObject(user.profiles) && !isValidAndNotEmptyArray(Object.entries(user.profiles).filter((item) => item[1].label === profile.label))) {
            setProfile(Object.entries(user.profiles)[0][1])
        }
    }, [profile, user.profiles])


    function deleteProfileFromDataBase(itemToBeDeleted) {
        const recentPostsRef = ref(dataBase, `users/${user.uid}/profiles/${itemToBeDeleted[0]}`)
        set(recentPostsRef, null)

        console.info("profiles: ", profile.label, itemToBeDeleted[1].label, user.profiles)
        if (profile.label === itemToBeDeleted[1].label) {
        }
    }

    return (
        <>
            {/* <p style={{ color: "red" }}>{JSON.stringify(profile)}</p> */}
            <div className={"savedProfilesAndTitle"}>

                <h1 className={"savedProfilesH1"}>
                    Kayıtlı Eşleşmeleriniz
                </h1>
                <div className={"savedProfileWrapper"}>
                    {
                        user.profiles && Object.entries(user.profiles).map((item) => {
                            return (
                                <div
                                    key={item[0]}
                                    className={item[1]?.label === profile?.label ? "profilesWrapper selected" : "profilesWrapper"}
                                >
                                    <div
                                        className={"profileWrapper"}
                                        onClick={() => setProfile({ value: item[1].value, label: item[1].label })}
                                    >
                                        <div className="zodiacSignsWrapper">
                                            <div className="zodiacSign">
                                                {zodiakSigns[zodiakIndexArr.indexOf(item[1]?.value?.split("-")[0]?.toLowerCase())]}
                                            </div>
                                            <div className="zodiacSign feMale">
                                                {zodiakSigns[zodiakIndexArr.indexOf(item[1]?.value?.split("-")[1]?.toLowerCase())]}
                                            </div>
                                        </div>
                                        <p className="profileLabel">
                                            {item[1].label}
                                        </p>
                                    </div>
                                    {
                                        <IconButton
                                            onClick={() => deleteProfileFromDataBase(item)}
                                            aria-label="delete"
                                            sx={{ "&": { position: "absolute", transform: "translateY(-50%)", right: 0, top: "50%", zIndex: 9 }, }}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    }
                                </div>
                            )
                        })

                    }
                    {
                        user.profiles && Object.entries(user.profiles).map((item) => {
                            return (
                                <MenuItem key={item[0]} sx={{ "&": { display: "none" }, }} value={item[1].label + "toBeShow"}>
                                    <p>{item[1].label}</p>
                                </MenuItem>
                            )
                        })

                    }
                </div>
            </div>

        </>
    )
}

export default Profile;