import "./FAQ.css";
import headerPng from "../src/assets/FAQ_header.png";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import { dataBase } from "./fireBaseConfig";
import { child, onValue, push, ref, set } from "firebase/database";
import { modalStore, pendingStore, userStore } from "./Store";
import { isValidAndNotEmptyArray, isValidAndNotEmptyString, isValidObject } from "./utils/utils";
import ReactQuill from "react-quill";
import parse from 'html-react-parser';
import CustomReactQuill from "./components/CustomReactQuill";
import { PlusOne } from "@mui/icons-material";

const FAQ = () => {
  const user = userStore((state) => state.user)

  const isPending = pendingStore((state) => state.isPending)
  const setPending = pendingStore((state) => state.setPending)

  const setModal = modalStore((state) => state.setModal)

  const [editModal, setEditModal] = useState(false);
  const [questionModal, setQuestionModal] = useState(false);
  const [addQuestionModal, setAddQuestionModal] = useState(false);


  const [selectedIssue, setSelectedIssue] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState([]);

  const [questions, setQuestions] = useState([]);


  const [expanded, setExpanded] = useState(false);

  const [title, setTitle] = useState("")
  const [html, setHtml] = useState("")
  const [p, setP] = useState("")

  const [question, setQuestion] = useState("");
  const [answerHtml, setAnswerHtml] = useState("")
  const [answerText, setAnswerText] = useState("")


  const handleChange = (item, panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setSelectedQuestion(item)
  };

  const addIssue = async () => {
    setPending(true);

    if (isValidAndNotEmptyString(p) && isValidAndNotEmptyString(html) && isValidAndNotEmptyString(title)) {
      try {
        await push(ref(dataBase, "FAQ/"), {
          title: title,
          text: p,
          textHtml: html,
        })
        setModal({
          type: "success",
          status: true,
          message: "Yeni soru başarıyla eklendi."
        })

      } catch (err) {
        console.error("add question failed, error: " + err)
        setModal({
          type: "error",
          status: true,
          message: "add question failed, error: " + err
        })
      }
    }

    setPending(false);
  }

  const updateQuestion = async (inIssueKey, toBeUpdatedQuestionKey) => {
    setPending(true);

    if (isValidAndNotEmptyString(question) && isValidAndNotEmptyString(answerText) && isValidAndNotEmptyString(answerHtml) && isValidObject(selectedIssue)) {
      try {
        await set(ref(dataBase, "FAQ/" + inIssueKey + "/questions/" + toBeUpdatedQuestionKey), {
          question: question,
          answer: answerText,
          textHtml: answerHtml,
        })
        setModal({
          type: "success",
          status: true,
          message: "Seçili konuya yeni soru başarıyla eklendi."
        })

      } catch (err) {
        console.error("Update question failed, error: " + err)
        setModal({
          type: "error",
          status: true,
          message: "Update question failed, error: " + err
        })
      }
    }

    setPending(false);
  }
  const addQuestion = async () => {
    setPending(true);

    if (isValidAndNotEmptyString(question) && isValidAndNotEmptyString(answerText) && isValidAndNotEmptyString(answerHtml) && isValidObject(selectedIssue)) {
      try {
        await push(ref(dataBase, "FAQ/" + selectedIssue[0] + "/questions/"), {
          question: question,
          answer: answerText,
          textHtml: answerHtml,
        })
        setModal({
          type: "success",
          status: true,
          message: "Seçili konuya yeni soru başarıyla eklendi."
        })

      } catch (err) {
        console.error("Update question failed, error: " + err)
        setModal({
          type: "error",
          status: true,
          message: "Update question failed, error: " + err
        })
      }
    }

    setPending(false);
  }


  const updateIssue = async () => {
    setPending(true);

    if (isValidAndNotEmptyString(p) && isValidAndNotEmptyString(html) && isValidAndNotEmptyString(title) && isValidObject(selectedIssue)) {
      try {
        await set(ref(dataBase, "FAQ/" + selectedIssue[0] + "/"), {
          title: title,
          text: p,
          textHtml: html,
        })
        setModal({
          type: "success",
          status: true,
          message: "Soru başarıyla güncellendi."
        })

      } catch (err) {
        console.error("Update question failed, error: " + err)
        setModal({
          type: "error",
          status: true,
          message: "Update question failed, error: " + err
        })
      }
    }

    setPending(false);
  }



  const getIssues = async () => {
    setPending(true);

    try {
      const FAQRef = child(ref(dataBase), `FAQ/`)
      await onValue(FAQRef, (snapshot) => {
        if (snapshot.val()) {
          setQuestions(snapshot.val())
          
        }
      });

    } catch (err) {
      console.error("get question failed, error: " + err)
      setModal({
        type: "error",
        status: true,
        message: "get question failed, error: " + err
      })
    }

    setPending(false);
  }

  useEffect(() => {
    getIssues()
  }, [])

  useEffect(() => {
    if (isValidObject(questions)) {
      const same = Object.entries(questions).filter(item => item[0] === selectedIssue[0] && item !== selectedIssue)
      const isThere = same.length > 0;
      if (isThere) {
        setSelectedIssue(same[0])
      }

      // Eğer seçili bir konu yoksa ilk konuyu seçer.
      if (!isValidAndNotEmptyArray(selectedIssue)) {
        setSelectedIssue(Object.entries(questions)[0])
      }
    }
  }, [questions])


  useEffect(() => {
    if (editModal === true) {
      setTitle(selectedIssue[1].title)
      setHtml(selectedIssue[1].textHtml)
    }
  }, [editModal])

  useEffect(() => {
    if (editModal === true && isValidAndNotEmptyArray(selectedQuestion)) {
      setAnswerHtml(selectedQuestion[1].textHtml)
      setAnswerText(selectedQuestion[1].answer)
      setQuestion(selectedQuestion[1].question)
    }
  }, [editModal, selectedQuestion])


  const iconSvg = <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0.96875C10.7461 0.96875 13.7812 4.00391 13.7812 7.75C13.7812 11.4961 10.7461 14.5312 7 14.5312C3.25391 14.5312 0.21875 11.4961 0.21875 7.75C0.21875 4.00391 3.25391 0.96875 7 0.96875ZM3.82812 8.95312H7V10.8945C7 11.1953 7.35547 11.332 7.54688 11.1406L10.6641 7.99609C10.8008 7.85938 10.8008 7.66797 10.6641 7.53125L7.54688 4.38672C7.32812 4.16797 7 4.33203 7 4.63281V6.54688H3.82812C3.63672 6.54688 3.5 6.71094 3.5 6.875V8.625C3.5 8.81641 3.63672 8.95312 3.82812 8.95312Z" fill="#515365" />
  </svg>

  const minusSvg = <svg width="12" height="3" viewBox="0 0 12 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9375 2.625H1.3125C0.820312 2.625 0.4375 2.24219 0.4375 1.75C0.4375 1.28516 0.820312 0.875 1.3125 0.875H10.9375C11.4023 0.875 11.8125 1.28516 11.8125 1.75C11.8125 2.24219 11.4023 2.625 10.9375 2.625Z" fill="white" />
  </svg>
  const plussSvg = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.8125 5.75C11.8125 6.24219 11.4023 6.65234 10.9375 6.65234H7V10.5898C7 11.0547 6.58984 11.4375 6.125 11.4375C5.63281 11.4375 5.25 11.0547 5.25 10.5898V6.65234H1.3125C0.820312 6.65234 0.4375 6.24219 0.4375 5.75C0.4375 5.28516 0.820312 4.90234 1.3125 4.90234H5.25V0.964844C5.25 0.472656 5.63281 0.0625 6.125 0.0625C6.58984 0.0625 7 0.472656 7 0.964844V4.90234H10.9375C11.4023 4.875 11.8125 5.28516 11.8125 5.75Z" fill="#515365" />
  </svg>

  return (
    <div id={"FAQPage"}>
      {/* ------------------------------ konu modal ------------------------------ */}
      {
        user.isAdmin && questionModal === true
          ?
          <div className={"addQuestionsModal"}>
            <h1>{editModal === false ? "Yeni Konu Ekle" : "Mevcut Konuyu Güncelle"}</h1>
            <input onChange={(e) => setTitle(e.target.value)} value={title} placeholder="konu başlığı" />

            <CustomReactQuill html={html} setHtml={setHtml} setP={setP} />
            <div className="buttonsWrapper">
              <button onClick={() => { setQuestionModal(false); setEditModal(false) }}>Kapat</button>
              <button onClick={() => editModal === true ? updateIssue() : addIssue()}>{editModal === true ? "Güncelle" : "Konu ekle"}</button>
            </div>

          </div>
          : null
      }
      {/* ------------------------------ ^^^^^^^^^^^^^^^^ ------------------------------ */}

      {/* ------------------------------ soru modal ------------------------------ */}
      {
        user.isAdmin && addQuestionModal === true
          ?
          <div className={"addQuestionsModal"}>
            <h1>{editModal === false ? "Yeni Soru Ekle" : "Mevcut Soruyu Güncelle"}</h1>
            <input onChange={(e) => setQuestion(e.target.value)} value={question} placeholder="Soru" />

            <CustomReactQuill html={answerHtml} setHtml={setAnswerHtml} setP={setAnswerText} />
            <div className="buttonsWrapper">
              <button onClick={() => { setAddQuestionModal(false); setEditModal(false) }}>Kapat</button>
              <button onClick={() => editModal === true ? updateQuestion(selectedIssue[0], selectedQuestion[0]) : addQuestion()}>{editModal === true ? "Güncelle" : "Soru Ekle"}</button>
            </div>

          </div>
          : null
      }
      {/* ------------------------------ ^^^^^^^^^^^^^^^^ ------------------------------ */}

      <div className="header">
        <h1>Sıkça Sorulan Sorular</h1>
        <img src={headerPng} />
      </div>

      <div className="body">
        <div className="titlesWrapper">
          <div className={"firstRow"}>
            <h2>Konu Başlıkları</h2>

            {
              user.isAdmin
                ? <button onClick={() => setQuestionModal(true)}>ekle</button>
                : null
            }


          </div>
          <div className={"titlesList"}>
            {
              isValidObject(questions)
              &&
              Object.entries(questions).map((item) => {
                return <div key={item[0]} className={selectedIssue[0] === item[0] ? "selected" : ""} onClick={() => setSelectedIssue(item)}>
                  {iconSvg}
                  <p>{isValidAndNotEmptyArray(item) ? item[1].title : ""}</p>
                </div>
              })
            }



          </div>
        </div>
        <div className={"answerWrapper ql-snow"}>
          {isValidAndNotEmptyArray(selectedIssue) ?
            <>
              <h1>
                {selectedIssue[1].title}

                {
                  user.isAdmin
                    ? <button onClick={() => { setEditModal(true); setQuestionModal(true) }}>Edit</button>
                    : null
                }

              </h1>
              <div className="view ql-editor">
                {
                  parse(selectedIssue[1].textHtml)

                }
              </div>

              <div className="questionsList">
                {
                  user.isAdmin
                    ? <button onClick={() => { setAddQuestionModal(true) }}>Soru Ekle</button>
                    : null
                }

                {
                  isValidObject(selectedIssue[1].questions)
                  &&
                  Object.entries(selectedIssue[1].questions).map((item) => {
                    return <Accordion
                      key={item[0]}
                      sx={{
                        "&": {
                          width: "100%",
                        },
                        "& div": {
                          color: expanded === item[1].question ? "#fff" : "#515365"
                        },
                        "& .MuiAccordionSummary-gutters": {
                          background: expanded === item[1].question ? "#FA6342" : "#fff",
                          borderRadius: "inherit",
                        },
                        "& .Mui-expanded": {
                        },
                        ".css-o4b71y-MuiAccordionSummary-content": {
                          fontSize: 14,
                        }
                      }}
                      expanded={expanded === item[1].question}
                      onChange={handleChange(item, item[1].question)}
                    >
                      <AccordionSummary
                        expandIcon={
                          expanded === item[1].question
                            ? minusSvg
                            : plussSvg
                        }
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        {item[1].question}
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="ql-snow">
                          <div className={"view ql-editor"}>
                            {parse(item[1].textHtml)}
                          </div>
                        </div>
                      </AccordionDetails>
                      {
                        user.isAdmin && isValidObject(selectedQuestion) && selectedQuestion[0] === item[0]
                          ?
                          <button
                            onClick={() => { setAddQuestionModal(true); setEditModal(true) }}
                          >
                            Güncelle
                          </button>
                          : null
                      }
                    </Accordion>
                  })
                }

              </div>
            </>
            : null
          }

        </div>
      </div>
    </div>
  )
}

export default FAQ;