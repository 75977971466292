import "../../node_modules/react-quill/dist/quill.snow.css";
import "./AboutContent.css"
import giftIcon from "../assets/gift.svg"
import Content from "./Content";
import { aboutDirectionStore, postsDirectionStore, profileStore, selectedContentStore, selectedSubTitleStore, selectedTitleStore, userStore, whichSideStore } from "../Store";
import { useEffect, useState } from "react";
import { child, onValue, push, ref, set } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import { useResolvedPath } from "react-router-dom";
import { useRef } from "react"
import ProfileCircle from "./ProfileCircle";
import { isValidAndNotEmptyString } from "../utils/utils";
import WhichSideButtons from "./WhichSideButtons";
import AdminAboutEditForm from "./AdminAboutEditForm";
import parse from 'html-react-parser';

const AboutContent = () => {

    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const content = selectedContentStore((state) => state.content)
    const setContent = selectedContentStore((state) => state.setContent)

    const selectedTitle = selectedTitleStore((state) => state.title)
    const setSelectedTitle = selectedTitleStore((state) => state.setTitle)

    const whichSide = whichSideStore(state => state.whichSide)

    const profile = profileStore((state) => state.profile)

    const postsDirection = postsDirectionStore((state) => state.postsDirection)
    const setPostsDirection = postsDirectionStore((state) => state.setPostsDirection)


    const aboutDirection = aboutDirectionStore((state) => state.aboutDirection)
    const setAboutDirection = aboutDirectionStore((state) => state.setAboutDirection)

    


    const selectedSubTitle = selectedSubTitleStore((state) => state.selectedSubTitle)
    const setSelectedSubTitle = selectedSubTitleStore((state) => state.setSelectedSubTitle)


    const [about, setAbout] = useState("")



    useEffect(() => {
        const aboutRef = child(ref(dataBase), "abouts/" + `${aboutDirection}${whichSide}`)

        onValue(aboutRef, (snapshot) => {
            if (snapshot.exists()) {
                setAbout(snapshot.val())
            }
            else {
                setAbout("")
                console.error("about content yok", aboutDirection, aboutDirection, aboutDirection, aboutDirection, whichSide)
            }
        });
    }, [profile, selectedSubTitle, aboutDirection, whichSide])

    const paragraph = useRef("")
    const [paragraphHeight, setParagraphHeight] = useState(0)
    useEffect(() => {
        console.warn("= = = = = = about = = = = = =", paragraph?.current)
        if (paragraph.current && paragraph.current.scrollHeight) {
            setParagraphHeight(paragraph.current.scrollHeight)
            
        }
    }, [about, paragraph])

    const [fullHeight, setFullHeight] = useState(false)
    const [edit, setEdit] = useState(false)

    const userSvg = <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.875 12.375C14.6641 12.375 17.75 15.4609 17.75 19.25C17.75 19.9531 17.1641 20.5 16.5 20.5H1.5C0.796875 20.5 0.25 19.9531 0.25 19.25C0.25 15.4609 3.29688 12.375 7.125 12.375H10.875ZM2.125 18.625H15.8359C15.5234 16.1641 13.4141 14.25 10.875 14.25H7.125C4.54688 14.25 2.4375 16.1641 2.125 18.625ZM9 10.5C6.22656 10.5 4 8.27344 4 5.5C4 2.76562 6.22656 0.5 9 0.5C11.7344 0.5 14 2.76562 14 5.5C14 8.27344 11.7344 10.5 9 10.5ZM9 2.375C7.24219 2.375 5.875 3.78125 5.875 5.5C5.875 7.25781 7.24219 8.625 9 8.625C10.7188 8.625 12.125 7.25781 12.125 5.5C12.125 3.78125 10.7188 2.375 9 2.375Z" fill="white" />
    </svg>


    return (

        <div id='aboutContentWrapper' style={{ background: whichSide === "feMale" ? "#FC8DCA" : "#74bfff" }}>
            {
                about.title && about?.paragraph && !edit
                    ? <>
                        <div className="header">
                            <div className="iconAndTitle">
                                {userSvg}
                                <h1 className="aboutContentTitle">{about.title}</h1>
                            </div>

                            <WhichSideButtons />
                        </div>
                        <span className='divider' />
                        <div className="ql-snow">
                            <span
                                style={{
                                    whiteSpace: "pre-line",
                                }}
                                ref={paragraph}

                                className={fullHeight ? "view ql-editor aboutParagraph full" : "view ql-editor aboutParagraph"} >
                                {/* <ProfileCircle onlineEnable={false} userUid={user?.uid} /> */}
                                {/* {
                                fullHeight || about?.paragraph.length < 160
                                    ? about.paragraph
                                    : about?.paragraph?.slice(0, 160) + "..."
                            }
                             */}

                                {
                                    about.hasOwnProperty("html") ? parse(about.html) : null
                                }

                            </span>
                        </div>
                        {
                            paragraphHeight > 150
                                ? <span onClick={() => setFullHeight(!fullHeight)} className={"showMoreButton"}>
                                    {fullHeight ? "daha az" : "daha çok"}
                                </span>
                                : null
                        }
                        <div>

                        </div>




                        {
                            user.isAdmin === true
                                ? <button className="contentEditButton" onClick={() => setEdit(!edit)}>edit</button>
                                : null
                        }

                    </>
                    : <AdminAboutEditForm setEdit={setEdit} edit={edit} paragraph={about.paragraph} currentHtml={about.hasOwnProperty("html") ? about.html : null} title={about.title} />
            }

        </div>
    )
}

export default AboutContent;