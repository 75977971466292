
import React, { useEffect, useRef, useState } from 'react'

import { usersStore, userStore, postsStore, dataBaseUserStore, profileStore, modalStore, hadnleProfilePageStore, handleMessagePageStore, usersOnlineStatusStore, navigationStore, pictureSelectorStrore, myPostsStore, myFollowerStore } from "./Store"
import { child, get, onValue, push, ref, set } from 'firebase/database'
import { auth, dataBase, handleSignOut } from './fireBaseConfig'
import SideMenu from './components/SideMenu'
import Posts from "./Posts"
import "./ProfilePage.css"
import Modal from './components/Modal'
import { getAuth, signOut } from 'firebase/auth'
import Content from './components/Content'
import ContentsSide from './components/ContentsSide'
import AboutContent from './components/AboutContent'
import Post from './Post'

import { getCityLabelFromCityValue, getProfileBannerByUid, getProfilePhotoByUid, getProfileUserCityByUid, getUsersNameByUid, handleFallow, isValidAndNotEmptyString, isValidObject, stringToMatColor, validatePhoneNumber } from "./utils/utils"
import PictureSelector from './components/PictureSelector'
import Followers from './components/Followers'
import Messages from './components/Messages'
import CustumCrop from './components/CustomCrop'
import ProfilePagePosts from "./components/ProfilePagePosts"
import AboutMe from './components/AboutMe'
import IFollow from './components/IFollow'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { updatePassword } from "firebase/auth";
import ChangePassword from './components/ChangeAcountSettingsInputs/ChangePassword'
import { Box, Breadcrumbs, Skeleton, Tab, Tooltip, box } from '@mui/material'
import Typography from '@mui/material/Typography';
import { TabList, TabContext, TabPanel } from '@mui/lab'
import GoHomePageLink from './components/FloatingNavButtons/GoHomePageLink'
import ProfileCircle from './components/ProfileCircle'
import ProfileSettings from './ProfileSettings'
import BannerPlaceHolder from './components/PlaceHolders/BannerPlaceHolder'



const ProfilePage = () => {

    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const setPage = navigationStore((state) => state.setPage)
    const page = navigationStore((state) => state.page)

    const profileData = hadnleProfilePageStore((state) => state.profileData)
    const setProfileData = hadnleProfilePageStore((state) => state.setProfileData)


    const messageData = handleMessagePageStore((state) => state.messageData)
    const setMessageData = handleMessagePageStore((state) => state.setMessageData)

    const myPosts = myPostsStore(state => state.myPosts)
    const followers = myFollowerStore(state => state.followers)


    let { state } = useLocation();

const location = useLocation()



    // const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)

    const usersOnlineStatus = usersOnlineStatusStore((state) => state.usersOnlineStatus)
    const setUsersOnlineStatus = usersOnlineStatusStore((state) => state.setUsersOnlineStatus)


    const [profileName, setProfileName] = useState("")

    const profilePhotoRef = useRef("")
    const profileBannerRef = useRef("")

    const isMyPage = () => { return isValidAndNotEmptyString(state.whom) && state.whom === user.uid }

    function updatePassword() {
        const user = auth.currentUser;

        const newPassword = 123456123456;

        updatePassword(user, newPassword).then(() => {
            // Update successful.

        }).catch((error) => {
            // An error ocurred
            // ...
        });
    }

    const [isProfilePhoto, setIsProfilePhoto] = useState(false)
    const [profilePhoto, setProfilePhoto] = useState("")
    const [city, setCity] = useState("")

    const [bannerUrl, setBannerUrl] = useState("")

    useEffect(() => {
        if (isValidObject(state) && isValidAndNotEmptyString(state?.whom)) {
            getUsersNameByUid(state.whom).then(res => setProfileName(res))
            // emin değilim bundan  "profileData.whom  || user?.uid"
            getProfilePhotoByUid(state.whom).then((url) => {
                if (url?.length > 0) {
                    setIsProfilePhoto(true)
                    setProfilePhoto(url)
                    // profilePhotoRef?.current.setAttribute("src", url)
                } else {
                    // console.log(url)
                    setIsProfilePhoto(false)
                }
            })


            getProfileUserCityByUid(state.whom).then(city => setCity(getCityLabelFromCityValue(Number(city))))
        }

    }, [state, user.profilePhoto, user.bannerPhoto])

    useEffect(() => {
        if(isValidObject(state) && isValidAndNotEmptyString(state.whom)) {
            getProfileBannerByUid(state.whom).then((url) => setBannerUrl(url))
        }
    }, [state])
    const closeSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-448.001 266.923-234.924q-6.692 6.693-15.807 6.885-9.116.192-16.192-6.885-7.077-7.076-7.077-15.999 0-8.923 7.077-16L448.001-480 234.924-693.077q-6.693-6.692-6.885-15.807-.192-9.116 6.885-16.192 7.076-7.077 15.999-7.077 8.923 0 16 7.077L480-511.999l213.077-213.077q6.692-6.693 15.807-6.885 9.116-.192 16.192 6.885 7.077 7.076 7.077 15.999 0 8.923-7.077 16L511.999-480l213.077 213.077q6.693 6.692 6.885 15.807.192 9.116-6.885 16.192-7.076 7.077-15.999 7.077-8.923 0-16-7.077L480-448.001Z" /></svg>

    // useEffect(() => {
    //     const bannerItem = document.querySelector("#profile-banner");
    //     const defaultBannerUrl = ""

    //     const profileItem = document.querySelector(".profilePicWrapper > img");
    //     const defaultProfileUrl = ""

    //     if (bannerItem) {
    //         bannerItem.setAttribute("src", defaultBannerUrl)
    //     }
    //     if (profileItem)
    //         profileItem.setAttribute("src", defaultProfileUrl)

    // }, [document.querySelector("#profile-banner")])

    const [isIFollow, setIsIFollow] = useState(false)



    useEffect(() => {
        console.log(location)
        if(location.search.split("?")[1] === "ayarlar") {
            setProfileNav("settings")
        } else if(location.search === "") {
            setProfileNav("posts")
        }
    }, [location])

    useEffect(() => {
        if (!user.i_follow) {
            setIsIFollow(false)
            return
        } else {

        }
        {
            isValidObject(state) && state.hasOwnProperty("whom") && Object.keys(user?.i_follow)?.includes(state.whom)
                ? setIsIFollow(true)
                : setIsIFollow(false)
        }
    }, [user])



    const setPictureSelector = pictureSelectorStrore((state) => state.setPictureSelector)
    const pictureSelector = pictureSelectorStrore((state) => state.pictureSelector)

    useEffect(() => {
        console.info("pictureSelector: ", pictureSelector)
    }, [pictureSelector])
    const profileEditSvg = <svg className='editSvg' height="48" viewBox="0 -960 960 960" width="48"><path d="M197.694-32.002q-23.529 0-40.611-17.081-17.082-17.082-17.082-40.61v-564.613q0-23.529 17.082-40.611 17.082-17.082 40.611-17.082H574.23l-45.384 45.384H197.694q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v564.612q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h564.612q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-333.383l45.384-45.384v378.767q0 23.53-17.082 40.61Q785.835-32 762.306-32H197.694ZM480-372Zm192.385-334.306L705-674.307 425.385-395.692v78.307h77.307l281-280.23 31.614 31.23-277.691 279.076q-7.231 6.846-17.193 11.077-9.961 4.231-20.422 4.231h-91.152q-12.606 0-20.726-8.121-8.121-8.12-8.121-20.726V-392q0-10.461 4.231-20.422 4.231-9.962 11.462-17.193l276.691-276.691Zm143.082 139.921L672.385-706.306l91.538-91.538q16.231-16.231 40.577-16.039 24.346.193 40.961 17.423l59.383 59.614q16.616 17.667 16.616 41.18 0 23.512-17.616 40.127l-88.377 89.154Z" /></svg>
    const bannerEditSvg = <svg className='editSvg' height="48" viewBox="0 -960 960 960" width="48"><path d="M197.694-32.002q-23.529 0-40.611-17.081-17.082-17.082-17.082-40.61v-564.613q0-23.529 17.082-40.611 17.082-17.082 40.611-17.082H574.23l-45.384 45.384H197.694q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v564.612q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h564.612q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-333.383l45.384-45.384v378.767q0 23.53-17.082 40.61Q785.835-32 762.306-32H197.694ZM480-372Zm192.385-334.306L705-674.307 425.385-395.692v78.307h77.307l281-280.23 31.614 31.23-277.691 279.076q-7.231 6.846-17.193 11.077-9.961 4.231-20.422 4.231h-91.152q-12.606 0-20.726-8.121-8.121-8.12-8.121-20.726V-392q0-10.461 4.231-20.422 4.231-9.962 11.462-17.193l276.691-276.691Zm143.082 139.921L672.385-706.306l91.538-91.538q16.231-16.231 40.577-16.039 24.346.193 40.961 17.423l59.383 59.614q16.616 17.667 16.616 41.18 0 23.512-17.616 40.127l-88.377 89.154Z" /></svg>

    const [profileNav, setProfileNav] = useState("posts")
    const [tooltip, setTooltip] = useState(false)

    const threeDotSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M479.858-160Q460-160 446-174.142q-14-14.141-14-34Q432-228 446.142-242q14.141-14 34-14Q500-256 514-241.858q14 14.141 14 34Q528-188 513.858-174q-14.141 14-34 14Zm0-272Q460-432 446-446.142q-14-14.141-14-34Q432-500 446.142-514q14.141-14 34-14Q500-528 514-513.858q14 14.141 14 34Q528-460 513.858-446q-14.141 14-34 14Zm0-272Q460-704 446-718.142q-14-14.141-14-34Q432-772 446.142-786q14.141-14 34-14Q500-800 514-785.858q14 14.141 14 34Q528-732 513.858-718q-14.141 14-34 14Z" /></svg>


    const followSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M730-400v-130H600v-60h130v-130h60v130h130v60H790v130h-60Zm-370-81q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM40-160v-94q0-35 17.5-63.5T108-360q75-33 133.338-46.5 58.339-13.5 118.5-13.5Q420-420 478-406.5 536-393 611-360q33 15 51 43t18 63v94H40Zm60-60h520v-34q0-16-9-30.5T587-306q-71-33-120-43.5T360-360q-58 0-107.5 10.5T132-306q-15 7-23.5 21.5T100-254v34Zm260-321q39 0 64.5-25.5T450-631q0-39-25.5-64.5T360-721q-39 0-64.5 25.5T270-631q0 39 25.5 64.5T360-541Zm0-90Zm0 411Z" /></svg>
    const premiumSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m422-440 58-43 57 43q9 7 18 1t6-17l-22-71 61-47q8-7 4.5-17T590-601h-73l-23-74q-3-11-13.5-11T466-675l-24 74h-72q-11 0-14.5 10t4.5 17l61 47-22 71q-3 11 6 17t17-1ZM284-53q-14 5-27-4t-13-25v-262q-45-47-64.5-103T160-560q0-136 92-228t228-92q136 0 228 92t92 228q0 57-19.5 113T716-344v262q0 16-13 25t-27 4l-196-66-196 66Zm196-247q109 0 184.5-75.5T740-560q0-109-75.5-184.5T480-820q-109 0-184.5 75.5T220-560q0 109 75.5 184.5T480-300ZM304-124l176-55 176 55v-171q-40 29-86 42t-90 13q-44 0-90-13t-86-42v171Zm176-86Z" /></svg>
    const mailSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm680-525L496-473q-4 2-7.5 3.5T480-468q-5 0-8.5-1.5T464-473L140-685v465h680v-465ZM480-522l336-218H145l335 218ZM140-685v7-39.317.732V-740v23-.912V-678v-7 465-465Z" /></svg>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Zodyaki | Profil</title>
            </Helmet>
            {
                isValidObject(state) ?
                    <div id={"profile-page-wrapper"}>
                        <PictureSelector which={"bannerPhoto"} />
                        {/* <PictureSelector which={"profilePhoto"} /> */}
                        {/* <Link to="/home" id='page-close' >{closeSvg}</Link> */}

                        <div className={"profilePageContainer"}>
                            <div id={"profile-page-header"}>
                                <div
                                    id={"profile-banner-wrapper"}
                                    className={isMyPage() ? "my" : null}
                                >
                                    {
                                        isValidAndNotEmptyString(bannerUrl)
                                            ? <>
                                                <img
                                                    id={"profile-banner"}
                                                    onClick={() => isMyPage() ? setPictureSelector({ status: !pictureSelector.status, which: "bannerPhoto", aspect: 3.9 }) : null}
                                                    className={isMyPage() ? "my" : null}
                                                    src={bannerUrl || ""}
                                                // ref={profileBannerRef}
                                                />
                                                {isMyPage()
                                                    ? bannerEditSvg
                                                    : null}
                                            </>
                                            : <BannerPlaceHolder />
                                    }

                                    <div
                                        onClick={() => isMyPage() ? setPictureSelector({ status: !pictureSelector.status, which: "profilePhoto", aspect: 1 }) : null}
                                        className={isMyPage() ? "profilePicWrapper my" : "profilePicWrapper"}>
                                        <ProfileCircle size={"100%"} userUid={state?.whom} />
                                        {
                                            isMyPage()
                                                ? profileEditSvg
                                                : null
                                        }
                                    </div>
                                </div>

                                <div id={"profile-page-header-end"}>
                                    {
                                        !isMyPage()
                                            ?
                                            <div id={"fallowAndMessageWrapper"}>

                                                <Tooltip title={isIFollow ? "Takipi bırak" : "Takip et"} placement="top">
                                                    <div onClick={() => handleFallow(user?.uid, state.whom, user?.i_follow)} className={isIFollow ? "follow-action-warapper followed" : 'follow-action-warapper'}>
                                                        {followSvg}
                                                        {/* <button  >{isIFollow ? "Takipi bırak" : "Takip et"}</button> */}
                                                    </div>
                                                </Tooltip>



                                                <Tooltip title={"Mesaj At"} placement="top">
                                                    <div
                                                        onClick={() => setMessageData({ whom: state.whom, status: true })}
                                                        className={true ? "message-action-warapper" : 'message-action-warapper disabled'}>
                                                        {mailSvg}
                                                    </div>
                                                </Tooltip>

                                            </div>
                                            : null
                                    }

                                    <div id={"profileNameAndActions"}>
                                        <div id={"profilePhotoAndName"}>
                                            <h1 id='profile-name'>{profileName}</h1>
                                            <p id="profile-locality-p">{city}, TR</p>
                                        </div>

                                        {/* {
                                        state.whom !== user.uid
                                            ?
                                            null
                                            :
                                            window.innerWidth >= 600 ?
                                                <div className='premium-action-wrapper'>
                                                    {premiumSvg}
                                                    <button className='getPremiumButton'>Premium</button>
                                                </div>
                                                : null

                                    } */}

                                    </div>


                                    {/* <div className='profile-nav-buttons'>
                                    <button className={profileNav === "posts" ? "active" : null} onClick={() => setProfileNav("posts")}>Gönderiler</button>
                                    <button className={profileNav === "followers" ? "active" : null} onClick={() => setProfileNav("followers")}>Takipçiler</button>
                                    <button className={profileNav === "i_follow" ? "active" : null} onClick={() => setProfileNav("i_follow")}>Takip Edilenler</button>
                                    <button className={profileNav === "aboutMe" ? "active" : null} onClick={() => setProfileNav("aboutMe")}>Hakkında</button>
                                </div> */}
                                    <div className='profile-nav-wrapper'>
                                        <TabContext value={profileNav}>
                                            <Box sx={{ borderBottom: 1, borderColor: 'transparent', maxWidth: window.innerWidth <= 600 ? "100%" : null, }}>
                                                <TabList
                                                    onChange={(event, value) => setProfileNav(value)}
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                    aria-label="scrollable auto tabs example"
                                                >
                                                    <Tab label="Gönderiler" value="posts" />
                                                    <Tab label="Hakkında" value="aboutMe" />
                                                    <Tab label="Takipçiler" value="followers" />
                                                    <Tab label="Takip Edilenler" value="i_follow" />
                                                    {
                                                        isMyPage()
                                                            ? <Tab label="Ayarlar" value="settings" />
                                                            : null
                                                    }
                                                </TabList>
                                            </Box>
                                        </TabContext>
                                    </div>
                                    {
                                        !isMyPage()
                                            ?
                                            <div className='profile-actions-tooltip-wrapper'>
                                                <div className={tooltip ? 'profile-actions-tooltip-body active' : 'profile-actions-tooltip-body'}>
                                                    <button>Engelle</button>
                                                    <button>Şikayet et</button>
                                                </div>
                                                <button className={tooltip ? "active" : null} onClick={() => setTooltip(!tooltip)}>{threeDotSvg}</button>
                                            </div>
                                            : null
                                    }

                                    <div id={"posts-follow-counts-wrapper"}>
                                        <div className="posts-count-wrapper">
                                            <h1>Gönderiler</h1>
                                            <p>{isValidObject(myPosts) ? Object.entries(myPosts)?.length : "-"}</p>
                                        </div>
                                        <div className="follow-count-wrapper">
                                            <h1>Takipçiler</h1>
                                            <p>{user?.followers ? Object.keys(user?.followers).length : 0}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id={"profile-page-body"}>

                                {
                                    profileNav == "posts"
                                        ? <ProfilePagePosts uid={state.whom} />
                                        : profileNav == "followers"
                                            ? <Followers uid={state.whom} />
                                            : profileNav == "i_follow"
                                                ? <IFollow uid={state.whom} />
                                                : profileNav == "aboutMe"
                                                    ? <AboutMe uid={state.whom} isMyPage={isMyPage()} />
                                                    : profileNav == "settings" && user.uid === state?.whom
                                                        ? <ProfileSettings uid={state.whom} />
                                                        : null
                                }
                            </div>
                        </div>
                    </div > :
                    null
            }
        </>
    )
}

export default ProfilePage;