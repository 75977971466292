import React, { useEffect, useRef, useState } from 'react'
import { usersStore, userStore, postsStore, dataBaseUserStore, profileStore, postsDirectionStore, selectedSubTitleStore, hadnleProfilePageStore, contentDirectionStore, mostLikedPostStore, testStore, selectedContentStore, currentMostLikedPostStore, modalStore, whichSideStore } from "./Store"
import { json } from 'react-router-dom'
import { child, equalTo, get, onValue, orderByChild, orderByValue, push, query, ref, set } from 'firebase/database'
import { dataBase } from './fireBaseConfig'
import SideMenu from './components/SideMenu'
import Post from "./Post"
import "./Posts.css"
import { dateFormatter, isValidAndNotEmptyArray, isValidAndNotEmptyString, isValidObject } from './utils/utils'
import { Avatar, Box, Button, Skeleton, Tab } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import Textarea from '@mui/joy/Textarea';
import AboutContent from './components/AboutContent'
import EmojiPicker from 'emoji-picker-react'
import OutsideClickHandler from 'react-outside-click-handler';
import emptyBoxesPng from './assets/empyt-boxes.png';
import ProfileCircle from './components/ProfileCircle'
import Pagination from "./components/Pagination"
import YouHaveNoProfile from './components/YouHaveNoProfile'
import PostPlaceHolder from './components/PlaceHolders/PostPlaceHolder'

const Posts = (props) => {


    const { Height } = props

    const user = userStore((state) => state.user)
    // const setUser = userStore((state) => state.setUser)
    const profile = profileStore((state) => state.profile)
    const setProfile = profileStore((state) => state.setProfile)

    // const [currentUser, setCurrentUser] = useState(null)
    const postsDirection = postsDirectionStore((state) => state.postsDirection)
    const whichSide = whichSideStore(state => state.whichSide)

    const [sortingBy, setSortingBy] = useState("time")

    // const user = dataBaseUserStore((state) => state.user)

    const scrollDivRef = useRef()

    const users = usersStore((state) => state.users)
    // const setUsers = usersStore((state) => state.setUsers)


    const dataBasePosts = postsStore((state) => state.posts)
    const setDataBasePosts = postsStore((state) => state.setPosts)


    const selectedSubTitle = selectedSubTitleStore((state) => state.selectedSubTitle)
    const setSelectedSubTitle = selectedSubTitleStore((state) => state.setSelectedSubTitle)

    // const findCurrentUserData = () => {
    //     if (users && Object.values(users)?.length > 0) {

    //         let currentUserObject = Object.values(users).filter((item) => item?.uid == localStorage.getItem("uid"))
    //         setCurrentUser(currentUserObject[0])
    //         // 
    //     }

    // }

    const [breakLineIndexArr, setBreakLineIndexArr] = useState([])

    const test = testStore(state => state.test)
    // useEffect(() => console.log("profile", test), [test])


    const handleScrollDown = () => {
        if (scrollDivRef?.current?.scrollHeight != null && scrollDivRef?.current?.scrollHeight - Math.round(scrollDivRef.current.scrollTop) < 1000) {
            scrollDivRef.current.scrollTop = scrollDivRef.current.scrollHeight
        }
    }


    function handleSortingPostsByLikesCount(DataBasePosts) {
        if (DataBasePosts) {
            const sortedPostByLikesCount = Object.fromEntries(
                Object.entries(DataBasePosts).sort((a, b) => {
                    let alike = a[1]?.like ? Object.keys(a[1]?.like)?.length : 0;
                    let blike = b[1]?.like ? Object.keys(b[1]?.like)?.length : 0;
                    // 
                    return blike - alike;
                })
            );
            setDataBasePosts(sortedPostByLikesCount)

        } else {
            setDataBasePosts([])
        }

    }
    function handleSortingPostsByTimes(DataBasePosts) {
        if (DataBasePosts) {
            const sortedPostByLikesCount = Object.fromEntries(
                Object.entries(DataBasePosts).sort((a, b) => {
                    let alike = a[1]?.date ? a[1]?.date : 0
                    let blike = b[1]?.date ? b[1]?.date : 0
                    return blike - alike;
                })
            );
            setDataBasePosts(sortedPostByLikesCount)

        } else {
            setDataBasePosts([])
        }


    }

    const [mostLikedPost, setMostLikedPosts] = useState([])
    function findMostLikedPost(posts) {
        setMostLikedPosts(Object.entries(posts).reduce((maxPost, currentPost) => {
            // 
            let maxLike = (maxPost != null && maxPost[1].like) ? Object.keys(maxPost[1].like).length : 0
            let currentLike = currentPost[1].like ? Object.keys(currentPost[1].like).length : 0

            let maxWhen = (maxPost != null && maxPost[1].date) ? maxPost[1].date : 0
            let currentWhen = (currentPost != null && currentPost[1].date) ? currentPost[1].date : 0


            return (currentLike > maxLike || (currentLike === maxLike && currentWhen < maxWhen))
                ? currentPost
                : maxPost
        }, null))



        // 
    }

    function handleSortingPosts(DataBasePosts) { sortingBy === "likesCount" ? handleSortingPostsByLikesCount(DataBasePosts) : handleSortingPostsByTimes(DataBasePosts) }

    const [count, setCount] = useState(0)
    useEffect(() => {
        // console.warn("profile", postsDirection)


        if (postsDirection != "") {

            // const postsRef = child(ref(dataBase), "contents/" + postsDirection)
            const postsRef = query(ref(dataBase, `posts/`),
                orderByChild("postDirection"),
                equalTo(`${postsDirection}`),
            )
            console.info("postDirections", postsDirection)
            onValue(postsRef, (snapshot) => {
                // console.error("postdirec", "contents/" + postsDirection)
                if (snapshot.exists()) {
                    // console.error("post var", snapshot.val())
                    console.error("post", snapshot.val())
                    // setDataBasePosts(snapshot.val())
                    handleSortingPosts(snapshot.val())
                    setCount(count + 1)
                    findMostLikedPost(snapshot.val())
                    // console.error("yeni posts", snapshot.val(), postsDirection)
                }
                else {
                    handleSortingPosts({})
                }
            });
        }
    }, [profile, selectedSubTitle, postsDirection, sortingBy]) //sortingBy burada çünki aşağıdaki kendi useEffectinde iken subtitle her değiştiğinde likeı eşit olan postlar yer değiştiriyordu

    // useEffect(() => { sortingBy === "likesCount" ? handleSortingPostsByLikesCount(dataBasePosts) : handleSortingPostsByTimes(dataBasePosts) }, [sortingBy])

    useEffect(() => {
        console.warn("DataBasePosts", dataBasePosts, postsDirection)
    }, [dataBasePosts])



    useEffect(() => {
        handleScrollDown()
    }, [profile])

    const [minRows, setMinRows] = useState(1)
    const [postValue, setPostValue] = useState("")

    const postRef = useRef()

    const setModal = modalStore((state) => state.setModal)
    const sendPost = (e) => {
        const date = new Date().getTime()
        if (postValue != "" > 0) {

            push(ref(dataBase, "posts/"), {
                postDirection: postsDirection,
                post: postValue,
                date: date,
                userName: user.name,
                userUid: user.uid,
                whichSide: whichSide,
            }).then(() => {
                setPostValue("")
                setMinRows(1)
                setBreakLineIndexArr([])
                setIsPlaceHolder(true)
            })
            // push(ref(dataBase, "users/" + `${user.uid}/posts`), {
            //     post: postValue,
            //     date: date,
            //     userName: user.name,
            //     userUid: user.uid,
            // }).then(() => {
            //     setPostValue("")
            //     setBreakLineIndexArr([])
            //     setMinRows(1)
            //     setIsPlaceHolder(true)
            // })
        }
    }

    // useEffect(() => console.log(breakLineIndexArr), [breakLineIndexArr])

    const handleEnterPress = (e) => {
        setMinRows(1)
        let key = e.keyCode || e.which;
        if (key === 13) {
        } else {
        }
    }

    const contentDirection = contentDirectionStore((state) => state.contentDirection)

    const content = selectedContentStore((state) => state.content)

    const standPost = mostLikedPostStore((state) => state.mostLikedPost)
    const setStandPost = mostLikedPostStore((state) => state.setMostLikedPost)

    const currentMostLikedPost = currentMostLikedPostStore(state => state.currentMostLikedPost)
    const setCurrentMostLikedPost = currentMostLikedPostStore(state => state.setCurrentMostLikedPost)

    useEffect(() => {
        const mostLikedPostRef = child(ref(dataBase), "mostLikedPosts/" + `${contentDirection.replaceAll("/", "|").slice(0, -1)}/`)
        onValue(mostLikedPostRef, (snapshot) => {
            if (snapshot.exists()) {

                // console.log("most", snapshot.val())
                setCurrentMostLikedPost(snapshot.val())
                // if (new Date().getTime() - snapshot.val().since > 10000) {
                //     set(ref(dataBase, "contents/" + contentDirection + "mostLikedPost/isStand/"), true)
                //     setStandPost(snapshot.val())
                // }
            }

        });

    }, [contentDirection])


    const emojiSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M626-533q22.5 0 38.25-15.75T680-587q0-22.5-15.75-38.25T626-641q-22.5 0-38.25 15.75T572-587q0 22.5 15.75 38.25T626-533Zm-292 0q22.5 0 38.25-15.75T388-587q0-22.5-15.75-38.25T334-641q-22.5 0-38.25 15.75T280-587q0 22.5 15.75 38.25T334-533ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-.496-181Q537-261 585.5-287q48.5-26 78.5-72.4 6-11.6-.75-22.6-6.75-11-20.25-11H316.918Q303-393 296.5-382t-.5 22.6q30 46.4 78.5 72.4 48.5 26 105.004 26Z" /></svg>


    const [emoji, setEmoji] = useState(false)
    const [page, setPage] = useState(1)
    const [isPlaceHolder, setIsPlaceHolder] = useState(true)

    function handleChangePage(newPage) {
        setPage(newPage)
    }

    const boxStyle = {
        border: "1px solid #EDE9E9",
        borderColor: 'divider',
        backgroundColor: "#fff",
        height: 70,
        borderRadius: "5px",
        marginTop: "12px",
        marginBottom: "12px",

        ".css-1aquho2-MuiTabs-indicator": {
            display: "none"
        }
    }
    const tabListStyle = {
        height: 70,
    }
    const tabStyle = {
        height: 70,
        "&.Mui-selected": {
            color: "#FA6342",
        },

    }

    return (
        <>
            {
                isValidObject(user) && isValidAndNotEmptyString(user.uid) && isValidObject(profile) && isValidAndNotEmptyArray(content) ?
                    <>
                        <div id="sendPost-AboutContent">

                            <div className='sendPostWrapper'>
                                <ProfileCircle size={33} onlineEnable={false} userUid={user?.uid} />

                                <div className={"textAreaAndSendButtonWrapper"}>
                                    <div className={"texareaAndEmojiWrapper"}>
                                        <Textarea
                                            slotProps={{
                                                textarea: {
                                                    id: 'sendPostTextArea',
                                                    ref: postRef,
                                                }
                                            }}
                                            value={postValue}
                                            minRows={3}
                                            maxRows={4}
                                            onFocus={() => setIsPlaceHolder(false)}
                                            onBlur={() => postValue === "" ? setIsPlaceHolder(true) : null}
                                            sx={{
                                                '&': {
                                                    padding: 0,
                                                    background: "#EDF2F6",
                                                    border: "1px solid #E4E4E4",
                                                    width: "100%",
                                                },
                                                '&:focus-within': {
                                                    outline: 'none',
                                                    border: "none",
                                                },
                                                "&:focus-within::before": {
                                                    boxShadow: "none",
                                                }
                                            }}
                                            onKeyPress={(e) => handleEnterPress(e)}
                                            onChange={(val) => setPostValue(val.target.value)}
                                        >
                                        </Textarea>
                                        <p className={isPlaceHolder ? "" : "none"}>Ne Düşünüyorsun ?</p>
                                        {/* <button onClick={() => setEmoji(!emoji)} className={"emojiButton"}>
                                            {emojiSvg}
                                        </button> */}

                                    </div>
                                    <button id='sendPostButton' onClick={() => sendPost()}>
                                        Gönder
                                    </button>
                                </div>

                                {
                                    emoji
                                        ? <OutsideClickHandler
                                            onOutsideClick={() => {
                                                if (!emoji) return
                                                setEmoji(false)
                                            }}
                                        >
                                            <EmojiPicker
                                                className="post-emoji"
                                                onEmojiClick={(val) => setPostValue(postValue + val.emoji)}
                                            />
                                        </OutsideClickHandler>
                                        : null
                                }
                            </div>

                        </div>

                        <div className={"postsHeader"}>
                            <div className={"sortingButtonsWrapper"}>
                                <button
                                    onClick={() => setSortingBy("likesCount")}
                                    className={sortingBy === "likesCount" ? 'sortingButton active' : "sortingButton"}
                                >EN POPÜLER</button>
                                <button
                                    onClick={() => setSortingBy("time")}
                                    className={sortingBy === "time" ? 'sortingButton active' : "sortingButton"}
                                >EN YENİLER</button>
                            </div>

                            {
                                isValidObject(dataBasePosts) ?
                                    <Pagination
                                        count={Math.ceil(Object.entries(dataBasePosts).filter((item, index) => item[1].whichSide === whichSide).length / 20)}
                                        page={page}
                                        setPage={handleChangePage}
                                        rowsPerPage={20}
                                    />
                                    : null
                            }
                        </div>



                        <div ref={scrollDivRef} className={"postsScrollView"} style={{ height: "auto" }}>
                            <div className='fadeEffect'></div>
                            {
                                isValidObject(dataBasePosts) ?
                                    (
                                        Object.entries(dataBasePosts).filter((item, index) => item[1].whichSide === whichSide).filter((item, index) => index <= (page) * 20 - 1 && index > (page - 1) * 20 - 1).map((post) => {
                                            return <>
                                                <Post key={post[0]} isMostLiked={mostLikedPost && mostLikedPost[0] == post[0] ? true : false} postUid={post[0]} postMessage={post[1]?.post} postTime={dateFormatter(post[1]?.date)} userName={post[1]?.userName} userUid={post[1]?.userUid} />
                                            </>
                                        })
                                    ) : <>
                                       <PostPlaceHolder howMany={10} />
                                    </>
                            }
                        </div>
                        {
                            isValidObject(dataBasePosts) ?
                                <div className={"postsFooter"}>
                                    <Pagination
                                        count={Math.ceil(Object.entries(dataBasePosts).filter((item, index) => item[1].whichSide === whichSide).length / 20)}
                                        page={page}
                                        setPage={handleChangePage}
                                        rowsPerPage={20}
                                        way={"up"}
                                    />
                                </div>
                                : null
                        }

                    </> : <YouHaveNoProfile />
            }
        </>

    )
}

export default Posts