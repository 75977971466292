import "./MobilMenu.css"

const MobilMenu = (props) => {
    const {isOpen} = props
    return (
        <div id={"mobileMenu"} className={isOpen ? "open" : ""}>

        </div>
        )
}

export default MobilMenu;