import React, { useEffect, useState } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Link,
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  BrowserRouter,
  Routes,
  redirect,
  Navigate,
  useLocation
} from "react-router-dom";

import { aboutDirectionStore, contentDirectionStore, hadnleProfilePageStore, handleMessagePageStore, interactStore, modalStore, navigationStore, pendingStore, postsDirectionStore, postsStore, profileStore, selectedContentStore, userStore, usersOnlineStatusStore } from "./Store"
import { getDatabase, ref, onValue, child, set, onDisconnect, get, equalTo, query } from "firebase/database"
import { auth, dataBase, handleSignOut } from "./fireBaseConfig"
import { usersStore, loginStore } from "./Store"


import Home from "./Home"
import Login from "./Login"
import Register from "./Register"
import ForgotPassword from "./ForgotPassword"
import { getAuth, onAuthStateChanged, signOut, updateProfile } from 'firebase/auth';
import ProfilePage from './ProfilePage';
import Messages from './components/Messages';
import Header from './components/Header';
import { BarLoader } from 'react-spinners';
import Tips from './components/Tips';
import ProfileSettings from './ProfileSettings';
import StandardErrorBoundary from "./ErrorBoundary";
import AddProfileModal from './components/AddProfileModal';
import { SnackbarProvider, useSnackbar } from 'notistack';
import SideBar from './components/SideBar';
import WriterSearch from './WriterSearch';
import { CircularProgress } from '@mui/material';
import Modal from './components/Modal';
import MembershipPlans from './MembershipPlans';
import { allRoutes, deleteAllAboutParagraphs, isPrivate, isValidAndNotEmptyString, isValidObject } from './utils/utils';
import FAQ from './FAQ';
import SecurityPage from './SecurityPage';

const App = () => {

  if (false) {
    console.log = () => { }
    console.warn = () => { }
  }

 const { enqueueSnackbar } = useSnackbar();

    const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)

    useEffect(() => {
        console.info(`%cmodal.status: ${modal.status}, ${modal.message}`, "color:green");
        if (modal.status === true) {
            const variant = modal.type
            enqueueSnackbar(modal.message, { variant });
        }
            setModal({status: false, message: ""})
    }, [modal.status])


  const interact = interactStore(state => state.interact)
  const setInteract = interactStore(state => state.setInteract)

  useEffect(() => {
    if (interact) {
      window.addEventListener("mousemove", () => setInteract(true))
    }
  }, [])

  useEffect(() => {
    if (page.name == "" || page.name === "login") {
      document.querySelector("body").setAttribute("id", "loginPage")
    } else if (page.name == "home") {
      document.querySelector("body").setAttribute("id", "homePage")
    } else if (page.name == "forgot-password") {
      document.querySelector("body").setAttribute("id", "forgotPasswordPage")
    } else if (page.name == "Register") {
      document.querySelector("body").setAttribute("id", "registerPage")
    }
  }, [])

  const location = useLocation();

  const user = userStore((state) => state.user)
  const setUser = userStore((state) => state.setUser)
  const setUserAll = userStore((state) => state.setUserAll)

  const login = loginStore((state) => state.login)
  const setLogin = loginStore((state) => state.setLogin)

  const users = usersStore((state) => state.users)
  const setUsers = usersStore((state) => state.setUsers)

  const content = selectedContentStore((state) => state.content)
  const setContent = selectedContentStore((state) => state.setContent)

  const userRef = child(ref(dataBase), `users/`)
  const messageData = handleMessagePageStore((state) => state.messageData)


  const usersOnlineStatus = usersOnlineStatusStore((state) => state.usersOnlineStatus)
  const setUsersOnlineStatus = usersOnlineStatusStore((state) => state.setUsersOnlineStatus)


  useEffect(() => {
    const handleBeforeUnload = () => {
      if (sessionStorage.getItem("rememberMe") === "false") {
        setUserAll({})
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);



  useEffect(() => {
    console.warn("=========================content========================", content)
  }, [content]);

  useEffect(() => {
    if (isValidObject(user) && isValidAndNotEmptyString(user.uid)) {
      const userRef = child(ref(dataBase), `users/` + `${user.uid}`)
      onValue(userRef, (snapshot) => {
        if (snapshot.val()) {
          console.warn("********************************")
          console.warn("user", snapshot.val())
          console.warn("********************************")
          setUserAll(snapshot.val())
        }

      });

      const usersOnlineStatsRef = child(ref(dataBase), `is-online/`)
      onValue(usersOnlineStatsRef, (snapshot) => {
        if (snapshot.val()) {
          setUsersOnlineStatus(snapshot.val())

        }

      });


      if (user?.uid) {

        // To detect when browser tab is closed
        const presenceRef = ref(dataBase, "is-online/" + `${user?.uid}/`);
        onDisconnect(presenceRef).set(false);


        // To detect when enternet gone or came
        const connectedRef = ref(dataBase, ".info/connected");
        onValue(connectedRef, (snap) => {
          if (snap.val() === true) {
            set(ref(dataBase, "is-online/" + `${user?.uid}/`), true)
          } else {
            set(ref(dataBase, "is-online/" + `${user?.uid}/`), false)
          }
        });
      } else {
        // set(ref(dataBase, "is-online/" + `${user?.uid}/`), false)
        // login page e gidince de false versin(buna bakacağım)
      }

    }
  }, [user.uid, sessionStorage.getItem("rememberMe")])


  const profile = profileStore((state) => state.profile)
  const dataBasePosts = postsStore((state) => state.posts)
  const setDataBasePosts = postsStore((state) => state.setPosts)


  useEffect(() => {
    if (auth.currentUser) {
      const userRef = child(ref(dataBase), `users/${auth.currentUser.uid}`)

      onValue(userRef, (snapshot) =>
        snapshot.exists()
          ? setUser(snapshot.val())
          : null
      );
    } else {
      setUser({})
    }

    const usersRef = child(ref(dataBase), `users/`)

    onValue(usersRef, (snapshot) => {

      if (snapshot.exists()) {
        setUsers(snapshot.val())
      }
    });


  }, [auth.currentUser, user.uid])





  const profileData = hadnleProfilePageStore((state) => state.profileData)
  const setProfileData = hadnleProfilePageStore((state) => state.setProfileData)



  const page = navigationStore((state) => state.page)
  const setPage = navigationStore((state) => state.setPage)

  const navigate = useNavigate()

  const isPending = pendingStore((state) => state.isPending)
  const setPending = pendingStore((state) => state.setPending)


  useEffect(() => console.log("auth.currentUser", auth.currentUser), [auth.currentUser])

  setTimeout(() => {
    setPending(false)
  }, 1000)

  function isAllReady() {
    return auth && auth.currentUser && user && user.uid
  }


  const setContentDirection = contentDirectionStore((state) => state.setContentDirection)
  const setAboutDirection = aboutDirectionStore((state) => state.setAboutDirection)
  const setPostsDirection = postsDirectionStore((state) => state.setPostsDirection)

  useEffect(() => {
    if (localStorage.getItem("selectedContentPath")) {
      let selectedContentPath = localStorage.getItem("selectedContentPath")
      if (selectedContentPath) {
        setPostsDirection(`${selectedContentPath}/posts/`)
        setAboutDirection(`${selectedContentPath}/about/`)
        setContentDirection(`${selectedContentPath}/`)
      }
      console.info("selectedContentPath: ", selectedContentPath)
      console.info("selectedContentPath: ", selectedContentPath)
    }

  }, [])

  useEffect(() => {
    console.info("auth", auth.currentUser)
  }, [auth])

  const a = async () => {
    if (
      window && window.location &&
      window.location.pathname &&
      isValidAndNotEmptyString(window.location.pathname)
    ) {
      const path = window.location.pathname
      if (path === "/login" || path === "/forgotPassword" || path === "/register") {
        let ress = await signOut(auth)
        await set(ref(dataBase, "is-online/" + `${user?.uid}/`), false)
        localStorage.clear();
        setUserAll({})
      }
    }
  }
  useEffect(() => { a() }, [location.pathname])

  return (
    <>
      {
        auth
          ?
          <div id='AllWrapper' style={{ width: "100%", height: "100%", overflowY: messageData.status ? "hidden" : "auto" }}>
            <Routes>
              <Route exact path={"/"} element={isValidObject(user) ? <Home /> : <Navigate to="/login" replace={true} />} />
              <Route exact path={"/login"} element={<Login />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/forgot-password" element={<ForgotPassword />} />
              <Route
                path="/home"
                element={isValidObject(user)
                  ?
                  <Home />
                  :
                  <Navigate to="/" replace={true} />
                }
              />

              <Route exact path="/profil"
                element={isValidObject(user) ?
                  <ProfilePage /> :
                  <Navigate to="/" replace={true} />
                }
              />

              <Route exact path="/profile-settings"
                element={isValidObject(user) ?
                  <ProfileSettings /> :
                  <Navigate to="/" replace={true} />
                }
              />

              <Route exact path="/writer-search"
                element={isValidObject(user) ?
                  <WriterSearch /> :
                  <Navigate to="/" replace={true} />
                }
              />
              <Route exact path="/membership-plans"
                element={isValidObject(user) ?
                  <MembershipPlans /> :
                  <Navigate to="/" replace={true} />
                }
              />
              <Route exact path="/S.S.S"
                element={isValidObject(user) ?
                  <FAQ /> :
                  <Navigate to="/" replace={true} />
                }
              />
              <Route exact path="/security"
                element={isValidObject(user) ?
                  <SecurityPage /> :
                  <Navigate to="/" replace={true} />
                }
              /> 

            </Routes>
            {
              isValidObject(user) && isPrivate(window.location.pathname)
                ? <Header />
                : null
            }
            {
              isValidObject(user)
                ? <AddProfileModal />
                : null
            }
            {
              isValidObject(user)
                ? <Messages />
                : null
            }
            {
              isPrivate(window.location.pathname)
                ? <SideBar />
                : null
            }
            
            {
              isPending ?
                <>
                  <div id='spinnerWrapper' className={isPending ? "pending" : ""}>
                  </div>
                  <BarLoader color="#fff" />
                </>
                : null
            }

          </div>
          :
          null
      }

    </>
  )
}

export default App
