import { create } from 'zustand'
import React, { useEffect } from 'react';

// || {
//     isLoggedIn: false,
//     uid: null,
//     birthDate: null,
//     email: null,
//     gender: null,
//     name: null,
//     password: null,
//     profile: null,
// },
const userStore = create((set) => ({
    user: JSON.parse(localStorage.getItem("userData") || "{}"),
    setUserAll: (updatedUser) => set({ user: updatedUser }),
    setUser: (updatedUser) => set((prevState) => ({ user: { ...prevState.user, ...updatedUser } })),
}));
userStore.subscribe((state) => {
    localStorage.setItem("userData", JSON.stringify({ ...state.user }))
})

const interactStore = create((set) => ({
    interact: false,
    setInteract: (data) => set({ interact: data }),
}));

const modalStore = create((set) => ({
    modal: {
        type: "",
        status: false,
        message: "",
    },
    setModal: (update) => set({ modal: update }),
}));

const usersStore = create((set) => ({
    users: null,
    setUsers: (update) => set({ users: update }),
}));

const postsStore = create((set) => ({
    posts: null,
    setPosts: (update) => set({ posts: update }),
}));

const profileStore = create((set) => ({
    profile: JSON.parse(localStorage.getItem("profileStore") || "{}"),
    setProfile: (update) => set({ profile: update }),
}));
profileStore.subscribe((state) => {
    localStorage.setItem("profileStore", JSON.stringify({ ...state.profile }))
})


const testStore = create((set) => ({
    test: "",
    setTest: (update) => set({ test: update }),
}));

const selectedContentStore = create((set) => ({
    content: {},
    setContent: (update) => set({ content: update }),
}));

const selectedTitleStore = create((set) => ({
    title: JSON.parse(localStorage.getItem("selectedTitle") || "{}"),
    setTitle: (update) => set({ title: update }),
}));
selectedTitleStore.subscribe((state) => {
    localStorage.setItem("selectedTitle", JSON.stringify({ ...state.title }))
})


const selectedSubTitleStore = create((set) => ({
    selectedSubTitle: JSON.parse(localStorage.getItem("selectedSubTitle") || "{}"),
    setSelectedSubTitle: (update) => set({ selectedSubTitle: update }),
}));
selectedSubTitleStore.subscribe((state) => {
    localStorage.setItem("selectedSubTitle", JSON.stringify({ ...state.selectedSubTitle }))
})

const loginStore = create((set) => ({
    login: {
        isLoggedIn: false,
        uid: null,
    },
    setLogin: (update) => set({ login: update }),
}));

const postsDirectionStore = create((set) => ({
    postsDirection: "",
    setPostsDirection: (update) => set({ postsDirection: update }),
}));

const aboutDirectionStore = create((set) => ({
    aboutDirection: "",
    setAboutDirection: (update) => set({ aboutDirection: update }),
}));

const contentDirectionStore = create((set) => ({
    contentDirection: "",
    setContentDirection: (update) => set({ contentDirection: update }),
}));


const hadnleProfilePageStore = create((set) => ({
    profileData: {
        whom: "",
        status: ""
    },
    setProfileData: (update) => set((prevState) => ({ profileData: { ...prevState.profileData, ...update } })),
}));

const handleMessagePageStore = create((set) => ({
    messageData: {
        whom: "",
        status: false
    },
    setMessageData: (update) => set((prevState) => ({ messageData: { ...prevState.messageData, ...update } })),
}));


const usersOnlineStatusStore = create((set) => ({
    usersOnlineStatus: [],
    setUsersOnlineStatus: (data) => set({ usersOnlineStatus: data }),
}));


const navigationStore = create((set) => ({
    page: { name: "login", data: null },
    setPage: (update) => set((prev) => ({ page: { ...prev.page, ...update } })),
}));


const pictureSelectorStrore = create((set) => ({
    pictureSelector: { status: false },
    setPictureSelector: (update) => set((prev) => ({ pictureSelector: { ...prev.page, ...update } })),
}));

const messagesStore = create((set) => ({
    selectedMessages: [],
    allMessages: [],
    setAllMessages: (data) => set({ allMessages: data }),
    setSelectedMesages: (data) => set({ allMessages: data }),
}));


const mostLikedPostStore = create((set) => ({
    mostLikedPost: [],
    setMostLikedPost: (update) => set({ mostLikedPost: update }),
}));

const currentMostLikedPostStore = create((set) => ({
    currentMostLikedPost: {},
    setCurrentMostLikedPost: (update) => set({ currentMostLikedPost: update }),
}));

const addProfileStatusStore = create((set) => ({
    addProfile: false,
    setAddProfile: (val) => set({ addProfile: val }),
}));

const totalUnreadedMessagesCountStore = create((set) => ({
    totalUnreadedMessagesCount: 0,
    setTotalUnreadedMessagesCount: (val) => set({ totalUnreadedMessagesCount: val }),
}));

const myPostsStore = create((set) => ({
    myPosts: [],
    setMyPosts: (val) => set({ myPosts: val }),
}));
const myFollowerStore = create((set) => ({
    followers: [],
    setFollowers: (val) => set({ followers: val }),
}));

const pendingStore = create((set) => ({
    isPending: true,
    setPending: (val) => set({ isPending: val }),
}));


const whichSideStore = create((set) => ({
    whichSide: localStorage.getItem("whichSide") || userStore.getState().user?.gender || "male",
    setWhichSide: (data) => set({ whichSide: data }),
}));
whichSideStore.subscribe((state) => {
    localStorage.setItem("whichSide", state.whichSide)
})

export {
    userStore, loginStore, modalStore, usersStore,
    postsStore, profileStore, selectedContentStore,
    selectedTitleStore, postsDirectionStore, contentDirectionStore,
    selectedSubTitleStore,
    aboutDirectionStore, hadnleProfilePageStore,
    handleMessagePageStore, usersOnlineStatusStore,
    navigationStore, pictureSelectorStrore, messagesStore,
    mostLikedPostStore, addProfileStatusStore, testStore,
    currentMostLikedPostStore, totalUnreadedMessagesCountStore,
    myPostsStore, myFollowerStore, pendingStore, whichSideStore, interactStore
}