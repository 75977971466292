import { useEffect, useState } from "react";
import { getWhenFromTimeStamp } from "../utils/utils";

const TimeDiff = (props) => {
    const { timeStamp, className } = props;
    const [when, setWhen] = useState("");

    useEffect(() => {
        setWhen(getWhenFromTimeStamp(timeStamp));
        // console.log("when", when)
    }, [timeStamp]);


    useEffect(() => {
        // 1 dakikada bir işlemi çalıştırmak için setInterval kullanımı
        const interval = setInterval(() => {
            setWhen(getWhenFromTimeStamp(timeStamp));
        }, 60000); // 60 saniye (1 dakika) aralıklarla çalıştır

        // Komponent kaldırıldığında setInterval'i temizle
        return () => clearInterval(interval);
    }, []);

 

    return (
        <div className={className}>
            <p>{when}</p>
        </div>
    );
}

export default TimeDiff;