import React from 'react'
import Select from 'react-select'
import "./SelectorInput.css"



const SelectorInput = (props) => {
    const { set, label, options, defaultValue, saveToLocalStore, isSearchable } = props

    const handleChange = (val) => {

        {
            saveToLocalStore === true ?
                set(val.value) :
                set(val.value)
        }
    }

    return (
        <>
            {
                defaultValue != null ?
                    <Select placeholder={label} defaultValue={defaultValue} isSearchable={isSearchable} options={options} onChange={(val) => handleChange(val)} /> :
                    <Select placeholder={label} options={options} isSearchable={isSearchable} onChange={(val) => handleChange(val)} />

            }
        </>
    )
}

export default SelectorInput;