import { useEffect, useState } from "react";
import "./SimpleSelectInput.css"
import OutsideClickHandler from 'react-outside-click-handler';

const SimpleSelectInput = (props) => {

    const { options, set, defaultValue, selectedOption, placeholder, isDeletable, width } = props;

    const [dropDown, setDropDown] = useState(false)
    const [dropDownHeight, setDropDownHeight] = useState(0)


    useEffect(() => {
        if (options && options?.length > 0 && dropDown && dropDown === true) {
            setDropDownHeight((options?.length + 1) * 35)
        } else {
            setDropDownHeight(0)
        }
    }, [options, dropDown])


    const caretSvg = <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.2207 0.5H8.75C9.27734 0.5 9.54102 1.14453 9.16016 1.52539L5.41016 5.27539C5.17578 5.50977 4.79492 5.50977 4.56055 5.27539L0.810547 1.52539C0.429688 1.14453 0.693359 0.5 1.2207 0.5Z" fill="#515365" />
    </svg>
    const deleteSvg =
        <svg
            onClick={() => set(defaultValue)}
            className="deleteSvg"
            xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z" /></svg>

    return (
        <OutsideClickHandler
            onOutsideClick={() => setDropDown(false)}
        >
            <div onClick={() => setDropDown(!dropDown)} style={{ width: width || "auto" }} className={"SimpleSelectInputWrapper"}>
                <div className={"selectedOptionWrapper"}>
                    <p>{selectedOption || placeholder}</p>
                    <div className={"svgsWrapper"}>
                        {caretSvg}
                        {/* {
                        isDeletable
                            ? deleteSvg
                            : null
                    } */}
                    </div>
                </div>
                <div
                    className={dropDown ? "optionsWrapper open" : "optionsWrapper"}
                    style={{ height: dropDownHeight }}
                >
                    <button
                        key={"emty"}
                        className="option"
                        onClick={() => set("")}
                    >
                        {"Hiçbiri"}
                    </button>
                    {
                        options && options.map(option => {
                            return <button
                                key={option.value}
                                className="option"
                                onClick={() => set(option)}
                            >{option.label}</button>

                        })
                    }
                </div>
            </div>
        </OutsideClickHandler>
    )
}

export default SimpleSelectInput;