import "./Followers.css"
import { userStore, modalStore, handleMessagePageStore } from '../Store';
import { useEffect, useState } from "react";
import { useRef } from "react";
import { child, get, ref } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import { calcAge, getBurc, getCityLabelFromCityValue, getProfilePhotoByUid, getProfileUserCityByUid, getUsersNameByUid, getZodiacSign, zodiakIndexArr } from "../utils/utils";
import { Link } from "react-router-dom";
import ProfileCircle from "./ProfileCircle";
import zodiakSigns from "../assets/svgs";
import { Tooltip } from "@mui/material";


const Follower = (props) => {

    const { uid } = props
    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)
    const [profilePhoto, setProfilePhoto] = useState("")
    const [city, setCity] = useState("")
    const [data, setData] = useState("")

    useEffect(() => {
        const userRef = child(ref(dataBase), `users/` + `${uid}`)

        get(userRef).then((snapshot) => {
            if (snapshot.exists()) {
                setData(snapshot.val())
            } else {
            }
        })

        getProfilePhotoByUid(uid).then(url => setProfilePhoto(url))
        getProfileUserCityByUid(uid).then(data => setCity(data))


    }, [uid])

    const setMessageData = handleMessagePageStore((state) => state.setMessageData)


    const mailSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M140-160q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H140Zm680-525L496-473q-4 2-7.5 3.5T480-468q-5 0-8.5-1.5T464-473L140-685v465h680v-465ZM480-522l336-218H145l335 218ZM140-685v7-39.317.732V-740v23-.912V-678v-7 465-465Z" /></svg>
    const profileSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160-160v-94q0-38 19-65t49-41q67-30 128.5-45T480-420q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800-292 800-254v94H160Zm60-60h520v-34q0-16-9.5-30.5T707-306q-64-31-117-42.5T480-360q-57 0-111 11.5T252-306q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570-631q0-39-25.5-64.5T480-721q-39 0-64.5 25.5T390-631q0 39 25.5 64.5T480-541Zm0-90Zm0 411Z" /></svg>
    return (
        <>
            {
                data
                    ? <div id={"followerWrapper"}>
                        < div className="followerHeader" >
                            <div className="left">
                                <ProfileCircle userUid={data?.uid} />
                                <div className={"nameAndInformationsWrapper"}>
                                    <p className="followerName">{data?.name}</p>
                                    <div className="cityAgeZodiacWrapper">
                                        <p>{getCityLabelFromCityValue(data?.city)},</p>
                                        <p>{calcAge(data?.birthDate)},</p>
                                        <p>{getZodiacSign(data?.birthDate, "tr")}</p>
                                    </div>
                                </div>
                            </div>
                            {/* {
                                data.gender === "male" || data.gender === "Erkek" ?
                                    <div className={"zodiakSignWrapper male"}>
                                        {zodiakSigns[zodiakIndexArr.indexOf(getZodiacSign(data?.birthDate, "tr").toLowerCase())]}
                                    </div>
                                    : <div className={`zodiakSignWrapper feMale`}>
                                        {zodiakSigns[zodiakIndexArr.indexOf(getZodiacSign(data?.birthDate, "tr").toLowerCase())]}
                                    </div>
                            } */}

                            {/*
                             <div className="followerBody">
                                {/* <p>{data?.bio?.aboutMe}</p>
                                <p className="infoText">YAŞ <span></span></p>
                                <span className="verticalLine"></span>
                                <p className="infoText">ŞEHİR <span></span></p>
                                <span className="verticalLine"></span>
                                <p className="infoText">BURÇ <span>{getZodiacSign(data?.birthDate, "tr")}</span></p>

                            </div> 
                            */}
                            <div className="right">
                                <Tooltip title={"Mesaj At"} placement="top">
                                    <button className="followerMessageButton"
                                        onClick={() => setMessageData({ whom: uid, status: true })}
                                    >
                                        {mailSvg}
                                    </button>
                                </Tooltip>
                                <Tooltip title={"Profili Gör"} placement="top">
                                    <Link to="/profil" state={{ whom: uid }}>
                                        <button className="followerMessageButton" >{profileSvg}</button>
                                    </Link>
                                </Tooltip>
                            </div>
                        </div >



                    </div >
                    : null
            }
        </>
    )
}

export default Follower;