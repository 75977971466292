import { useEffect, useRef, useState } from "react";
import "./Messages.css"
import { hadnleProfilePageStore, handleMessagePageStore, messagesStore, totalUnreadedMessagesCountStore, userStore, usersStore } from "../Store";
import { child, endAt, equalTo, get, onValue, orderByChild, orderByKey, push, query, ref, set, startAt } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import { dateFormatter, getIsOnlineByUid, getProfilePhotoByUid, getUsersNameByUid, getWhenFromTimeStamp, isValidAndNotEmptyArray, isValidArray, isValidObject, playMessageSendedSound } from "../utils/utils";
import ProfileCircle from "./ProfileCircle";
import { Textarea } from "@mui/joy";
import EmojiPicker from "emoji-picker-react";
import OutsideClickHandler from 'react-outside-click-handler';
import CloseButton from "./FloatingNavButtons/CloseButton";
import GoBackButton from "./FloatingNavButtons/GoBackButton";
import MessagesSearchInput from "./MessagesSearchInput.jsx";
import TimeDiff from "./TimeDiff";
import PersonInfoModal from "./PersonInfoModal";

const Messages = () => {

    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const users = usersStore((state) => state.users)
    const setUsers = usersStore((state) => state.setUsers)

    const profileData = hadnleProfilePageStore((state) => state.profileData)
    const setProfileData = hadnleProfilePageStore((state) => state.setProfileData)

    const messageData = handleMessagePageStore((state) => state.messageData)
    const setMessageData = handleMessagePageStore((state) => state.setMessageData)


    useEffect(() => console.warn("messageData", messageData), [messageData])
    const [toggleMessages, setToggleMessages] = useState(false)
    // const [myContacts, setMyContacts] = useState([])


    const allMessages = messagesStore(state => state.allMessages)
    const setAllMessages = messagesStore(state => state.setAllMessages)
    // const selectedMessages = messagesStore(state => state.selectedMessages)
    // const setSelectedMessages = messagesStore(state => state.setSelectedMessages)

    const [messageDirection, setMessagesDirection] = useState("")
    const [unReadedMessages, setUnReadedMessages] = useState([])
    const [selectedMessages, setSelectedMessages] = useState([])

    const [selectedPersonName, setSelectedPersonName] = useState("")
    const [selectedIsOnline, setSelectedPersonIsOnline] = useState("")

    const [direction, setDirection] = useState("")
    useEffect(() => {
        const date = new Date()

        if (messageData?.status && user.uid && messageData.whom !== "") {
            const mergedUid = [user.uid, messageData.whom].sort().join("")
            set(ref(dataBase, `messages/${mergedUid}/readed/${user.uid}`), {
                who: user.uid,
                when: date.getTime(),
            })
        }


    }, [selectedMessages, messageData])
    const messageInputRef = useRef()

    const [messagesOne, setMessagesOne] = useState([])
    const [messagesTwo, setMessagesTwo] = useState([])

    useEffect(() => {
        console.info("messajlar", user.uid)
        try {
            if (!isValidObject(user?.messages)) {
                throw new Error("Hiç mesaj yok, aga beee")
            }
            const messagesRef = query(ref(dataBase, `messages/`),
                orderByChild("personOne"),
                equalTo(`${user.uid}`),
            )
            onValue(messagesRef, (snapshot) => {
                if (snapshot.exists()) {
                    const a = Object.entries(snapshot.val())
                    setMessagesOne(a)
                } else {
                    console.info("messaj bulunamadı")
                    setMessagesOne([])
                }
            })
            const messagesRef2 = query(ref(dataBase, `messages/`),
                orderByChild("personTwo"),
                equalTo(`${user.uid}`)
            )

            onValue(messagesRef2, (snapshot) => {
                if (snapshot.exists()) {

                    if (snapshot.exists()) {
                        const a = Object.entries(snapshot.val())
                        setMessagesTwo(a)
                    } else {
                        console.info("messaj bulunamadı")
                        setMessagesTwo([])
                    }
                }
            })
        } catch (err) {
            console.error("messages err: ", err)
            setMessagesOne([])
            setMessagesTwo([])
        }
    }, [user?.messages, user.uid])


    function allMessagesIncludes(test) {
        if (allMessages && typeof allMessages === "object" && allMessages.length) {

            let a = Object.values(allMessages)?.map(item => item[0] == test[0])
            if (a.filter(item => item === true).length > 0) {
                return true
            } else {
                return false
            }
        } else {
            return false

        }
    }


    // function finalIncludes(test) {
    //     if (allMessages && typeof allMessages === "object" && allMessages.length) {

    //         let a = Object.values(allMessages)?.map(item => item[0] == test[0])
    //         if (a.filter(item => item === true).length > 0) {
    //             return true
    //         } else {
    //             return false
    //         }
    //     } else {
    //         return false

    //     }
    // }

    useEffect(() => {
        const united = messagesOne.concat(messagesTwo)
        console.info("messagesOne: ", messagesOne, "messagesTwo: ", messagesTwo, "united: ", united)
        // const final = []
        // united.forEach((item) => {
        //     if (final.filter(a => a[0] === item[0]).length === 0) {
        //         final.push(item)
        //     }
        // })
        // console.error(final, Object.values(allMessages))
        // if(final !== Object.values(allMessages)) {
        setAllMessages(united)
        // }
    }, [messagesOne, messagesTwo])


    useEffect(() => {
        if (isValidObject(allMessages)) {

            let a = 0
            Object.values(allMessages)?.forEach(item => {
                if (isValidObject(item[1].messages)) {
                    a = a + Object.entries(item[1].messages).length
                }
            }
            )



            // selectedMessages ismini allMessages length ile değiştirilecek
            setSelectedMessages(a)
        }
    }, [allMessages])


    // useEffect(() => {
    //     console.warn("selectedMessages", selectedMessages)

    // }, [selectedMessages])

    useEffect(() => {
        if (messageData.whom !== "") {
            handleOpenMessage(messageData.whom)
            getUsersNameByUid(messageData.whom).then(name => setSelectedPersonName(name))
            getIsOnlineByUid(messageData.whom).then(stat => setSelectedPersonIsOnline(stat))
        }

    }, [messageData.whom])
    const handleOpenMessage = (you) => {
        set(ref(dataBase, "users/" + `${user.uid}/` + "messages/" + `${you}`), true)
        set(ref(dataBase, "users/" + `${you}/` + "messages/" + `${user.uid}`), true)

        setToggleMessages(true)
        setProfileData({ status: false })
    }

    const date = new Date()
    const sendMessage = async () => {
        if (messageData.whom && messageInputRef.current.value) {
            const mergedUid = [user.uid, messageData.whom].sort().join("");
            set(ref(dataBase, "messages/" + `${mergedUid}/personOne`),
                [user.uid, messageData.whom].sort()[0]
            )

            set(ref(dataBase, "messages/" + `${mergedUid}/personTwo`),
                [user.uid, messageData.whom].sort()[1]
            )

            await push(ref(dataBase, "messages/" + `${mergedUid}/messages/`),
                {
                    toWho: messageData.whom,
                    who: user.uid,
                    when: date.getTime(),
                    message: messageInputRef.current.value,
                }
            )

            playMessageSendedSound()
            scrollDown()
            messageInputRef.current.value = null
        }
    }







    const handleEnterPress = (e) => {
        // 
        let key = e.keyCode || e.which;
        if (key === 13) {
            e.preventDefault()
            sendMessage()
            messageInputRef.current.value = null
        } else {

        }
    }
    const scrollDivRef = useRef("")


    const scrollDown = () => {
        if (messageData.whom !== "") {
            scrollDivRef.current.scrollTop = scrollDivRef.current.scrollHeight
        }
    }

    useEffect(() => { scrollDown() }, [messageData.whom])
    // useEffect(() => console.log("allMessages", allMessages), [allMessages])

    const closeSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m480-438 129 129q9 9 21 9t21-9q9-9 9-21t-9-21L522-480l129-129q9-9 9-21t-9-21q-9-9-21-9t-21 9L480-522 351-651q-9-9-21-9t-21 9q-9 9-9 21t9 21l129 129-129 129q-9 9-9 21t9 21q9 9 21 9t21-9l129-129Zm0 358q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z" /></svg>
    const messageTailOut = <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.7821 7.83303C12.3663 8.22982 12.3663 9.09069 11.7821 9.48748L1.81188 16.2596C1.14794 16.7106 0.25 16.235 0.25 15.4324L0.25 1.88811C0.25 1.08549 1.14793 0.609909 1.81188 1.06088L11.7821 7.83303Z" fill="#DBF4FD" />
    </svg>

    const massageTailIn = <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.21787 9.16697C0.633707 8.77018 0.633706 7.90931 1.21787 7.51252L11.1881 0.740373C11.8521 0.289399 12.75 0.764974 12.75 1.56759L12.75 15.1119C12.75 15.9145 11.8521 16.3901 11.1881 15.9391L1.21787 9.16697Z" fill="#DAE7F1" />
    </svg>

    const backSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="m113-480 315 315q11 11 11 27.5T428-109q-12 12-28.5 12T371-109L42-438q-9-9-13-20t-4-22q0-11 4-22t13-20l330-330q12-12 28-11.5t28 12.5q11 12 11.5 28T428-795L113-480Z" /></svg>
    const emojiSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M626-533q22.5 0 38.25-15.75T680-587q0-22.5-15.75-38.25T626-641q-22.5 0-38.25 15.75T572-587q0 22.5 15.75 38.25T626-533Zm-292 0q22.5 0 38.25-15.75T388-587q0-22.5-15.75-38.25T334-641q-22.5 0-38.25 15.75T280-587q0 22.5 15.75 38.25T334-533ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-.496-181Q537-261 585.5-287q48.5-26 78.5-72.4 6-11.6-.75-22.6-6.75-11-20.25-11H316.918Q303-393 296.5-382t-.5 22.6q30 46.4 78.5 72.4 48.5 26 105.004 26Z" /></svg>

    const [emoji, setEmoji] = useState(false)

    const [ac, setAc] = useState([])
    const totalUnreadedMessagesCount = totalUnreadedMessagesCountStore(state => state.totalUnreadedMessagesCount)
    const setTotalUnreadedMessagesCount = totalUnreadedMessagesCountStore(state => state.setTotalUnreadedMessagesCount)

    useEffect(() => {
        const dummy = []
        console.info("girdi", allMessages)
        if (isValidObject(allMessages)) {
            Object.values(allMessages).map((item) => {
                if (isValidAndNotEmptyArray(item) && item.length === 2) {
                    if (isValidObject(item[1]) && item[1].hasOwnProperty("readed") && item[1].hasOwnProperty("messages")) {
                        if (isValidObject(item[1].readed)) {
                            const readedArr = Object.entries(item[1].readed)
                            const myLastReadArr = readedArr.find(item => item[0] === user.uid)
                            let myLastRead = 0
                            if (isValidAndNotEmptyArray(myLastReadArr) && myLastReadArr.length === 2 && myLastReadArr[1].hasOwnProperty("when")) {
                                myLastRead = myLastReadArr[1].when
                            }
                            if (isValidObject(item[1].messages)) {
                                const unreadedMessagesArr = Object.entries(item[1].messages).filter((item) => item[1].when > myLastRead)
                                dummy.push(unreadedMessagesArr.length)
                            }

                        }
                    }
                }
            })
        }
        const sum = dummy.map(item => item).reduce(function (a, b) {
            return a + b;
        }, 0)
        setTotalUnreadedMessagesCount(sum)


        // Object.values(allMessages).filter(item => item[1].readed !== undefined || item[1].readed !== null).map(item => {
        //     // console.log(Object.entries(item[1].readed).filter((item) => item[0] === user?.uid)[0][1].when)
        //     const isOk = Object.entries(item[1].readed).filter((item) => item[0] === user?.uid)[0] !== undefined ? true : false
        //     const myLastReadTime = isOk ? Object.entries(item[1].readed).filter((item) => item[0] === user?.uid)[0][1].when : 0
        //     const fromWho = item[0].replace(user.uid, "") || ""
        //     const unreadedMessagesCount = isValidAndNotEmptyArray(item) && isValidObject(item[1]) && item[1].hasOwnProperty("messages") ? Object.entries(item[1].messages).filter(item => item[1].who !== user?.uid && item[1].when > myLastReadTime).length : 0
        //     if (fromWho !== undefined) {
        //         dummy.push([fromWho, unreadedMessagesCount])
        //     }
        //     console.log("message", allMessages)
        // })
        // setAc(dummy)
        // const sum = dummy.map(item => item[1]).reduce(function (a, b) {
        //     return a + b;
        // }, 0)
        // console.error(sum)

        // setTotalUnreadedMessagesCount(sum)
    }, [allMessages])





    const settingsSvg = <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.4023 6.12109C17.543 6.4375 17.4375 6.75391 17.1914 7L15.6797 8.37109C15.7148 8.65234 15.75 8.96875 15.75 9.25C15.75 9.56641 15.7148 9.88281 15.6797 10.1641L17.1914 11.5352C17.4375 11.7812 17.543 12.0977 17.4023 12.4141C17.2617 12.8359 17.0859 13.2227 16.875 13.6094L16.6992 13.8906C16.4531 14.2773 16.207 14.6641 15.9258 15.0156C15.7148 15.2617 15.3633 15.332 15.0469 15.2266L13.1133 14.6289C12.6211 14.9805 12.0938 15.2617 11.5664 15.5078L11.1094 17.5117C11.0391 17.8281 10.793 18.0742 10.4766 18.1445C9.98438 18.2148 9.49219 18.25 8.96484 18.25C8.47266 18.25 7.98047 18.2148 7.48828 18.1445C7.17188 18.0742 6.92578 17.8281 6.85547 17.5117L6.39844 15.5078C5.87109 15.2617 5.34375 14.9805 4.85156 14.6289L2.91797 15.2266C2.60156 15.332 2.25 15.2617 2.03906 15.0156C1.75781 14.6641 1.51172 14.2773 1.26562 13.8906L1.08984 13.6094C0.878906 13.2227 0.703125 12.8359 0.5625 12.4141C0.421875 12.0977 0.527344 11.7812 0.773438 11.5352L2.28516 10.1641C2.25 9.88281 2.25 9.56641 2.25 9.25C2.25 8.96875 2.25 8.65234 2.28516 8.37109L0.773438 7C0.527344 6.75391 0.421875 6.4375 0.5625 6.12109C0.703125 5.69922 0.878906 5.3125 1.08984 4.92578L1.26562 4.64453C1.51172 4.25781 1.75781 3.87109 2.03906 3.51953C2.25 3.27344 2.60156 3.20312 2.91797 3.30859L4.85156 3.90625C5.34375 3.55469 5.87109 3.23828 6.39844 3.02734L6.85547 1.02344C6.92578 0.707031 7.17188 0.460938 7.48828 0.390625C7.98047 0.320312 8.47266 0.25 9 0.25C9.49219 0.25 9.98438 0.320312 10.4766 0.390625C10.793 0.460938 11.0391 0.707031 11.1094 1.02344L11.5664 3.02734C12.0938 3.23828 12.6211 3.55469 13.1133 3.90625L15.0469 3.30859C15.3633 3.20312 15.7148 3.27344 15.9258 3.51953C16.207 3.87109 16.4531 4.25781 16.6992 4.64453L16.875 4.92578C17.0859 5.3125 17.2617 5.69922 17.4023 6.12109ZM9 12.0625C10.5469 12.0625 11.8125 10.832 11.8125 9.25C11.8125 7.70312 10.5469 6.4375 9 6.4375C7.41797 6.4375 6.1875 7.70312 6.1875 9.25C6.1875 10.832 7.41797 12.0625 9 12.0625Z" fill="#535165" />
    </svg>

    const readedCheckSvg = <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#23D2E2" d="M13.8223 5.06836C14.0508 5.32227 14.0508 5.70312 13.7969 5.93164L6.28125 13.4473C6.05273 13.7012 5.67188 13.7012 5.44336 13.4473L1.17773 9.18164C0.923828 8.95312 0.923828 8.57227 1.17773 8.31836L2.16797 7.32812C2.42188 7.07422 2.80273 7.07422 3.03125 7.32812L5.875 10.1465L11.9434 4.07812C12.1719 3.82422 12.5781 3.82422 12.8066 4.07812L13.8223 5.06836ZM5.57031 7.75977L2.72656 4.91602C2.57422 4.76367 2.57422 4.50977 2.72656 4.33203L3.89453 3.18945C4.04688 3.03711 4.30078 3.03711 4.45312 3.18945L5.875 4.61133L9.70898 0.751953C9.86133 0.599609 10.1152 0.599609 10.293 0.751953L11.4355 1.89453C11.5879 2.07227 11.5879 2.32617 11.4355 2.47852L6.1543 7.75977C6.00195 7.9375 5.72266 7.9375 5.57031 7.75977Z" />
    </svg>

    const unReadedCheckSvg = <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill="#838195" d="M13.8223 5.06836C14.0508 5.32227 14.0508 5.70312 13.7969 5.93164L6.28125 13.4473C6.05273 13.7012 5.67188 13.7012 5.44336 13.4473L1.17773 9.18164C0.923828 8.95312 0.923828 8.57227 1.17773 8.31836L2.16797 7.32812C2.42188 7.07422 2.80273 7.07422 3.03125 7.32812L5.875 10.1465L11.9434 4.07812C12.1719 3.82422 12.5781 3.82422 12.8066 4.07812L13.8223 5.06836ZM5.57031 7.75977L2.72656 4.91602C2.57422 4.76367 2.57422 4.50977 2.72656 4.33203L3.89453 3.18945C4.04688 3.03711 4.30078 3.03711 4.45312 3.18945L5.875 4.61133L9.70898 0.751953C9.86133 0.599609 10.1152 0.599609 10.293 0.751953L11.4355 1.89453C11.5879 2.07227 11.5879 2.32617 11.4355 2.47852L6.1543 7.75977C6.00195 7.9375 5.72266 7.9375 5.57031 7.75977Z" />
    </svg>
    const sendMessageSvg = <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.7656 1.18945C22.293 0.291016 24.2246 1.59375 23.9551 3.39062L21.3047 20.7754C21.1699 21.4492 20.8105 21.9883 20.2266 22.3477C19.8672 22.5273 19.5078 22.6172 19.1484 22.6172C18.8789 22.6172 18.6094 22.5723 18.3398 22.4375L13.3086 20.3711L11.332 23.0215C10.1191 24.6836 7.46875 23.8301 7.46875 21.7188V17.9453L2.30273 15.834C0.640625 15.1602 0.505859 12.8691 2.07812 11.9707L20.7656 1.18945ZM9.625 21.7188L11.2422 19.5176L9.625 18.8438V21.7188ZM19.1484 20.4609L21.8438 3.03125L3.15625 13.8125L7.96289 15.834L17.4863 7.56836C18.1152 6.98438 19.0137 7.79297 18.5645 8.51172L12.2305 17.5859L19.1484 20.4609Z" fill="#515365" />
    </svg>

    const infoSvg = <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0714 1.28571H1.92857C0.863437 1.28571 0 2.14914 0 3.21428V17.3571C0 18.4223 0.863437 19.2857 1.92857 19.2857H16.0714C17.1366 19.2857 18 18.4223 18 17.3571V3.21428C18 2.14914 17.1366 1.28571 16.0714 1.28571ZM9 4.74106C9.93198 4.74106 10.6875 5.49658 10.6875 6.42856C10.6875 7.36054 9.93198 8.11606 9 8.11606C8.06802 8.11606 7.3125 7.36054 7.3125 6.42856C7.3125 5.49658 8.06802 4.74106 9 4.74106ZM11.25 14.9464C11.25 15.2127 11.0341 15.4286 10.7679 15.4286H7.23214C6.96588 15.4286 6.75 15.2127 6.75 14.9464V13.9821C6.75 13.7159 6.96588 13.5 7.23214 13.5H7.71429V10.9286H7.23214C6.96588 10.9286 6.75 10.7127 6.75 10.4464V9.48213C6.75 9.21587 6.96588 8.99999 7.23214 8.99999H9.80357C10.0698 8.99999 10.2857 9.21587 10.2857 9.48213V13.5H10.7679C11.0341 13.5 11.25 13.7159 11.25 13.9821V14.9464Z" fill="#FA6342" />
    </svg>


    return (
        <div id="messages">
            {/* <button onClick={() => setMessageData({ status: true })} >Messages</button> */}
            {

                <div className={messageData.status ? "messagesWrapper" : "messagesWrapper close"}>
                    {
                        messageData?.whom !== ""
                            ? <PersonInfoModal
                                uid={messageData?.whom}
                                name={selectedPersonName}
                                isOnline={selectedIsOnline}
                            />
                            : <div id="dummyPersonInfoModal"></div>
                    }

                    {
                        window.innerWidth > 800 || messageData.whom === ""
                            ? <div className="contactSide">
                                <div id={"contactSideHeader"}>
                                    <div className={"row-one"}>
                                        <h1>Chat Messenger</h1>
                                        {settingsSvg}
                                    </div>
                                    <div className={"row-two"}>
                                        <MessagesSearchInput placeHolder={"Arama"} />
                                    </div>

                                </div>
                                {
                                    isValidObject(user?.messages) && Object.entries(user?.messages).filter(item => item[0] !== user.uid)?.map((item) => {
                                        const mergedUid = [user?.uid, item[0]].sort().join("")

                                        let unReadedMessagesCount = 0
                                        if (ac.filter(a => a[0] === item[0])[0] !== undefined) {
                                            unReadedMessagesCount = ac.filter(a => a[0] === item[0])[0][1]

                                        }
                                        let hasMessage = Object.values(allMessages)?.filter(item => item[1].messages !== undefined && item[0].includes(user.uid))
                                        // console.info(hasMessage.filter(item => item[0] === mergedUid)[0])
                                        let isOk = hasMessage.length > 0 && hasMessage.filter(item => item[0] === mergedUid)[0] !== undefined
                                            ? true
                                            : false
                                        let lastIndex = isOk ? Object.entries(hasMessage.filter(item => item[0] === mergedUid)[0][1].messages)?.length - 1 : 0
                                        let lastMessagesTimeStamp = isOk ? Object.entries(hasMessage.filter(item => item[0] === mergedUid)[0][1].messages)[lastIndex][1].when : 0
                                        let lastMessage = isOk ? Object.entries(hasMessage.filter(item => item[0] === mergedUid)[0][1].messages)[lastIndex][1].message : 0


                                        return (
                                            <div
                                                className={messageData.whom === item[0] ? "messageUserRow selected" : "messageUserRow"}
                                                onClick={() => setMessageData({ whom: item[0] })}
                                            >
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: "18px" }}>
                                                    <ProfileCircle userUid={item[0]} />
                                                    <div className="nameAndLastMessageWrapper">
                                                        <p key={item[0]} className="messagerName">{users ? Object.entries(users).find((a) => a[0] == item[0])[1].name : "yükleniyor..."}</p>

                                                        <div className={"lastMessageAndWhenWrapper"}>
                                                            <p className="lastMessage">{lastMessage}</p>
                                                            <span>&nbsp;-&nbsp;</span>
                                                            <TimeDiff timeStamp={lastMessagesTimeStamp} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="unReadedMessagesCount">
                                                    <p>{unReadedMessagesCount}</p>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            : null
                    }
                    {
                        window.innerWidth > 800 || messageData.whom !== ""
                            ?
                            <div id="messagesBody">
                                <div id="messagesHeader">
                                    <div className="selectedPersonAndBack">
                                        {
                                            messageData.whom
                                                ? <>
                                                    <GoBackButton onClick={() => setMessageData({ whom: "" })} height={40} />
                                                    <ProfileCircle size={42} userUid={messageData.whom} />
                                                    <div className={"nameAndIsOnline"}>
                                                        <p>{selectedPersonName}</p>
                                                        <p>{selectedIsOnline ? "Online" : "Offline"}</p>
                                                    </div>
                                                </>
                                                : null
                                        }
                                    </div>

                                    {/* <button
                                     className={"infoButton"}
                                     >
                                        {infoSvg}
                                    </button> */}
                                    {/* <CloseButton
                                height="60%"
                                onClick={() => {
                                    setMessageData({ whom: "", status: false })
                                }}
                            /> */}

                                    {/* <button onClick={() => setMessageData({ status: !messageData.status })}>{closeSvg}</button> */}
                                </div>

                                <div className="contextSide">
                                    <div ref={scrollDivRef} className="messagesListWrapper">
                                        {
                                            messageData.whom !== "" && isValidObject(allMessages) && Object.values(allMessages)?.filter(item => item[0].includes(messageData.whom)).sort((a, b) => { return a[1].when - b[1].when }).map((item) => {
                                                let otherUserLastReadTime = item[1] && item[1]?.readed !== undefined && item[1].readed !== null && Object.entries(item[1].readed).length > 1 ? Object.entries(item[1]?.readed).filter(item => item[0] !== user?.uid)[0][1].when : undefined
                                                return item !== undefined && item !== null && item[1] && item[1]?.messages && Object.entries(item[1].messages).map((item) => {

                                                    return (
                                                        <div className={item[1].who !== user.uid ? "messageWrapper" : "messageWrapper left"}>
                                                            <ProfileCircle size={27} onlineEnable={false} userUid={item[1].who} />
                                                            <div className={"mesageBoxAndDateWrapper"}>
                                                                <div className={item[1].who !== user.uid ? "messageBox" : "messageBox left"}>
                                                                    {item[1].who !== user.uid ? massageTailIn : messageTailOut}
                                                                    <p className="message-text" key={item[0]}>{item[1].message}</p>

                                                                </div>
                                                                <div className={"messageCheckAndDateWrapper"}>
                                                                    {
                                                                        item[1].who === user.uid ?
                                                                            otherUserLastReadTime !== undefined && otherUserLastReadTime > item[1].when
                                                                                ? readedCheckSvg
                                                                                : unReadedCheckSvg
                                                                            : null
                                                                    }
                                                                    <p className="message-date">{dateFormatter(item[1].when)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })


                                            })
                                        }
                                    </div>
                                    {
                                        messageData.whom
                                            ? <div id='sendMassageWrapper'>
                                                <div className={"texareaAndEmojiWrapper"}>

                                                    <Textarea
                                                        slotProps={{
                                                            textarea: {
                                                                // id: 'sendPostTextArea',
                                                                ref: messageInputRef,
                                                            }
                                                        }}
                                                        placeholder={"Mesajınızı buraya yazın..."}
                                                        minRows={1}
                                                        maxRows={4}
                                                        sx={{
                                                            '&': {
                                                                background: "transparent",
                                                                width: "100%",
                                                                height: "100%",
                                                                padding: "15px 0px 15px 37px",
                                                                border: "none",
                                                            },
                                                            '& textarea': {
                                                                // paddingTop: "30px",
                                                            },
                                                            '&:focus-within': {
                                                                outline: 'none',
                                                                border: "none",
                                                            },
                                                            "&:focus-within::before": {
                                                                boxShadow: "none",
                                                            }
                                                        }}
                                                        onKeyPress={(e) => handleEnterPress(e)}
                                                    // onChange={(val) => console.log("sdaasd", messageInputRef.current.value)}
                                                    >
                                                    </Textarea>

                                                </div>
                                                <div className={"messageButtonsWrapper"}>
                                                    <button id={"sendMessageButton"} onClick={() => sendMessage()}>
                                                        {sendMessageSvg}
                                                    </button>
                                                    <button onClick={() => setEmoji(!emoji)} className={"emojiButton"}>
                                                        {emojiSvg}
                                                    </button>
                                                </div>
                                                {
                                                    emoji
                                                        ? <OutsideClickHandler
                                                            onOutsideClick={() => {
                                                                if (!emoji) return
                                                                setEmoji(false)
                                                            }}
                                                        >
                                                            <EmojiPicker
                                                                className="post-emoji"
                                                                onEmojiClick={(val) => messageInputRef.current.value = messageInputRef.current.value + val.emoji}
                                                            />
                                                        </OutsideClickHandler>
                                                        : null
                                                }
                                            </div>
                                            : null
                                    }

                                </div>

                                {/* <div style={{ boxShadow: "2.5px 0px 5px 1px rgba(172, 172, 172, 0.8)", zIndex: 9, width: "1px", height: "100%" }} className={"line-shadow"} /> */}

                            </div>
                            : null
                    }
                </div>
            }
        </div >
    )
}

export default Messages;