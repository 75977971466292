import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import Button from '@mui/material/Button';
import { SnackbarProvider, SnackbarContent } from 'notistack';
import { styled } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));

const StyledSnackbarProvider = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    background-color: orange;
  }
`;

const successClass = "SnackbarContent-root.SnackbarItem-contentRoot.SnackbarItem-variantSuccess.SnackbarItem-lessPadding.css-hped4j"



root.render(
  <GoogleOAuthProvider clientId="272230614814-91inh1dm6gq2ugqee9l558dvn9kqr2e4.apps.googleusercontent.com">
  <SnackbarProvider
  autoHideDuration={4000}
    // iconVariant={{
    //   success: '✅',
    //   error: '✖️',
    //   warning: '⚠️',
    // }}
    sx={{
      "& .SnackbarItem-variantSuccess": {
        backgroundColor: "#fff",
        borderBottom: "3px solid rgba(79, 204, 123, 1)",
        gap: 50,
      },
      "& .SnackbarItem-variantWarning": {
        backgroundColor: "#fff",
        borderBottom: "3px solid #FA6342",
        gap: 50,
      },
      "& .SnackbarItem-variantError": {
        backgroundColor: "#fff",
        borderBottom: "3px solid red",
        gap: 50,
      },
    }}
    Components={{
      success: StyledSnackbarProvider,
      error: StyledSnackbarProvider,
      info: StyledSnackbarProvider,
    }}
    
    maxSnack={3}
    >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </SnackbarProvider>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
