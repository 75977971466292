import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { usersOnlineStatusStore, usersStore } from "../Store"
import "./ProfileCircle.css"
import { getProfilePhotoByUid, isValidAndNotEmptyArray, isValidAndNotEmptyString, isValidObject, stringToMatColor } from "../utils/utils"



const ProfileCircle = (props) => {


    const { userUid, userProfilePhoto, onlineEnable, size } = props

    const usersOnlineStatus = usersOnlineStatusStore((state) => state.usersOnlineStatus)
    const setUsersOnlineStatus = usersOnlineStatusStore((state) => state.setUsersOnlineStatus)
    const users = usersStore((state) => state.users)

    const [url, setUrl] = useState("")
    const [profilePhoto, setProfilePhoto] = useState("")
    const [profileName, setProfileName] = useState("")

    function getOnlineStatusOfUserByUid(uid) {
        if (isValidObject(usersOnlineStatus)) {
            const isOnlineUser = Object.entries(usersOnlineStatus).find((item) => item[0] == uid) || ""
            
            if (isValidAndNotEmptyArray(isOnlineUser)) {
                return isOnlineUser[1]
            }
        }
    }

    useEffect(() => {
        if (isValidAndNotEmptyString(userUid) && isValidObject(users)) {
            const a = Object.entries(users).find((a) => a[0] === userUid)
            if (a && a.length) {
                const b = a.length ? a : undefined
                if (b) {
                    setProfilePhoto(a[1].profilePhoto)
                    setProfileName(a[1].name)
                }
            }
        }
    }, [userUid, users])

    useEffect(() => {
        // console.log(profilePhoto, profileName)
    }, [profilePhoto, profileName])

    return (
        <>
            {
                users && typeof users === "object" ?
                    <Link
                        to="/profil"
                        id='profilePageIcon'
                        style={size ? { width: size, height: size } : null}
                        state={{ whom: userUid }}
                    >

                        {
                            profilePhoto !== "" && profilePhoto !== null && profilePhoto !== undefined
                                ?
                                <div id="profilePhotoWrapper">
                                    {/* <img src={url} /> */}
                                    <img src={profilePhoto} />
                                </div>
                                :
                                <div id="profilePhotoWrapper">
                                    <div className='letter-wrapper' style={{ background: `${stringToMatColor(profileName) || "#EDE9E9"}` }}>
                                        <p className='letter big'
                                            style={{ fontSize: `${size * 50 / 100}px` }}
                                        >
                                            {profileName?.charAt(0)}
                                        </p>
                                    </div>
                                </div>

                        }

                        {
                            onlineEnable === false
                                ? null
                                : getOnlineStatusOfUserByUid(userUid)
                                    ? <span className={"isOnline"}></span>
                                    : <span className={"isOfline"}></span>

                        }
                    </Link >
                    : null
            }
        </>
    )

}

export default ProfileCircle