import React, { useEffect, useRef, useState } from 'react'
import {
    Link,
    Navigate,
    BrowserRouter as Router,
    useNavigate
} from "react-router-dom";

import "./Register.css"
import Input from './components/Input';
import SelectorInput from './components/SelectorInput';
import { auth, fireStore, dataBase, storage } from "./fireBaseConfig"
import { createUserWithEmailAndPassword, sendEmailVerification, sendSignInLinkToEmail } from 'firebase/auth';
import { set, ref } from "firebase/database";
import TimePicker from "./components/TimePicker"
import { doc, getDoc } from "firebase/firestore";
import Modal from './components/Modal';
import { userStore, modalStore } from './Store';
import { cities, getProfileBannerByUid, getProfilePhotoByUid, getUserByEmail, getUserByUid, isValidObject } from './utils/utils';
import { getDownloadURL, ref as sRef, uploadBytes } from 'firebase/storage';
import { Helmet } from "react-helmet";
import loginCircle from "./assets/login-circle.svg";
import filePenSvg from "./assets/file-pen.svg";
import squareCheckSvg from "./assets/square-check.svg";
import zodyakiSvg from "./assets/zodyaki.svg";
import usersSvg from "./assets/users.svg";
import VanillaDateInput from './components/VanillaDateInput';


const Login = () => {
    const navigate = useNavigate()
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [passwordConfirm, setPasswordConfirm] = useState(null)
    const [gender, setGender] = useState(null)
    const [birthDate, setBirthDate] = useState(null)
    const [togglePassword, setTogglePassword] = useState(false)
    const [stage, setStage] = useState("register")
    const [city, setCity] = useState(null)


    const aaaa = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)


    useEffect(() => {

    }, [email, password, passwordConfirm, gender, birthDate])


    // async function handleFotos(uid, which, url) {
    //     
    //     await set(ref(dataBase, "users/" + `${uid}/${which}`), url)
    // }

    const saveUserToDatabase = async (uid) => {

        set(ref(dataBase, 'users/' + `${uid}`), {
            name: name,
            email: email,
            password: password,
            gender: gender,
            birthDate: birthDate,
            city: city,
            uid: uid,
        });

        // await getProfileBannerByUid("default").then((url) => handleFotos(uid, "bannerPhoto", url))
        // await getProfilePhotoByUid("default").then((url) => handleFotos(uid, "profilePhoto", url))
    }


    const createUser = async () => {
        if (email != null && password != null && name != null && gender != null && birthDate != null && password == passwordConfirm) {
         
            try {
                let a = await getUserByEmail(email)
                let isAllReadyMember = isValidObject(a) || false
                if(isAllReadyMember) {
                    throw new Error("Email kullanımda!")
                }
                let userCredential = await createUserWithEmailAndPassword(auth, email.toLowerCase(), password)
                const user = userCredential.user;
               
               
                try {
                    await sendEmailVerification(auth.currentUser)
                } catch (err) {
                    console.error("email sender err: ", err)
                    setModal({
                        type: "error",
                        status: true,
                        message: "Doğrulama emaili gönderilemedi!"
                    })
                }

                
                await saveUserToDatabase(user.uid)
                setModal({
                    type: "success",
                    status: true,
                    message: "Aramıza hoş geldin!"
                })
            } catch (err) {
                setModal({
                    type: "error",
                    status: true,
                    message: err
                })
            }

        } else {
            if (password != passwordConfirm) {
                setModal({
                    type: "error",
                    status: true,
                    message: "İki şifre'de aynı olmak zorunda!"
                })
            } else {
                setModal({
                    type: "error",
                    status: true,
                    message: "Lütfen tüm alanları doldurunuz"
                })
            }
        }



    }

    const genderOptions = [
        { value: 'male', label: 'Erkek' },
        { value: 'feMale', label: 'Kadın' },
    ]

    const keySvg = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35 0H25C22.2383 0 20 2.23828 20 5V17L0.366455 36.6336C-0.121826 37.1219 -0.121826 37.9133 0.366455 38.4016L1.59849 39.6336C2.08677 40.1219 2.87817 40.1219 3.36646 39.6336L6.19849 36.8016L9.01958 39.6227C9.50786 40.1109 10.2993 40.1109 10.7875 39.6227L14.1594 36.2508C14.6477 35.7625 14.6477 34.9711 14.1594 34.4828L11.3383 31.6617L13.75 29.25L16.6336 32.1336C17.1219 32.6219 17.9133 32.6219 18.4016 32.1336L19.6336 30.9016C20.1219 30.4133 20.1219 29.6219 19.6336 29.1336L16.75 26.25L23 20H35C37.7618 20 40 17.7617 40 15V5C40 2.23828 37.7618 0 35 0ZM29.268 14.268C28.2915 15.2445 26.7086 15.2445 25.7321 14.268C24.7555 13.2914 24.7555 11.7086 25.7321 10.7328C26.7086 9.75625 28.2915 9.75625 29.268 10.7328C30.2438 11.7086 30.2438 13.2914 29.268 14.268ZM34.268 9.26797C33.2915 10.2445 31.7086 10.2445 30.7321 9.26797C29.7555 8.29141 29.7555 6.70859 30.7321 5.73281C31.7086 4.75625 33.2915 4.75625 34.268 5.73281C35.2438 6.70859 35.2438 8.29141 34.268 9.26797Z" fill="white" />
    </svg>
    return (
        <div
            id={"registerPage"}
        >
            <Helmet>
                <meta charSet="utf-8" />
                <title>Zodyaki | Kayıt Ol</title>
            </Helmet>
            {/* 
            <div className={"loginStatsWrapper"}>
                <img src={zodyakiSvg} />
                <h1>Zodyaki’ye Hoşgeldiniz</h1>
                <p>
                    Zodyaki bir sosyal platformdur.Burç eşleşmeleriyle birbirinize
                    bir platformdur.Hemen üye ol.
                </p>
                <div className={"statsWrapper"}>
                    <div className={"statWrapper"}>
                        <div>
                            <img src={usersSvg} />
                        </div>
                        <p>Kayıtlı Kişiler</p>
                        <p>36.278</p>
                    </div>
                    <div className={"statWrapper"}>
                        <div>
                            <img src={squareCheckSvg} />
                        </div>
                        <p>Kayıtlı Kişiler</p>
                        <p>36.278</p>
                    </div>
                    <div className={"statWrapper"}>
                        <div>
                            <img src={filePenSvg} />
                        </div>
                        <p>Kayıtlı Kişiler</p>
                        <p>36.278</p>
                    </div>
                </div>
            </div> */}
            
            <div id={"login-container"} className='register'>
                <h1 className='title'>
                    {keySvg}
                    Üye Ol
                </h1>
                <div className="form-wrapper">
                    <span className='line1'></span>
                    <span className='line2'></span>
                    <span className='line3'></span>

                    <Input set={setName} label={"Adınız Soyadınız"} type={"text"} />
                    <Input set={setEmail} label={"Mail Adresiniz"} type={"email"} />
                    <Input set={setPassword} value={password} label={"Şifre"} toggle={setTogglePassword} type={togglePassword ? "password" : "text"} />
                    <Input set={setPasswordConfirm} label={"Şifre Tekrar"} toggle={setTogglePassword} type={togglePassword ? "password" : "text"} />
                    {/* <SimpleSelectInput
                            options={genderOptions}
                            set={setGender}
                            selectedOption={gender.label}
                            placeholder={"Cinsiyetiniz"}
                        /> */}
                    <SelectorInput options={genderOptions} set={setGender} label={"Cinsiyetiniz"} />
                    <SelectorInput options={cities} set={setCity} label={"Şehriniz"} />
                    <VanillaDateInput labelBackground={"#FA6342"} set={setBirthDate} />
                    {/* <TimePicker set={setBirthDate} label={"Doğum Tarihiniz"} /> */}

                </div>

                <div className={"register-buttons-wrapper"}>
                    <button className="button" onClick={() => createUser()}>Kayıt OL</button>
                    <div className='register-forgot-password-wraper'>
                        <Link to='/login'>Giriş Yap</Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Login
