import "./AddProfileModal.css"
import { addProfileStatusStore, testStore } from "../Store";
import "./SideMenu.css"
import { userStore, modalStore, dataBaseUserStore } from '../Store';
import { useEffect, useState } from "react";
import TimePicker from "./TimePicker";

import Profile from "./Profile"
import CustomSwitch from "./CustomSwitch";
import SelectorInput from "./SelectorInput";
import { dataBase } from "../fireBaseConfig";
import { push, ref, set } from "firebase/database";
import Input from "./Input";

import { calcAge, getZodiacSign, handleSwipe, handlers, isValidObject, zodiakIndexArr } from "../utils/utils"
import { Box, Button, Tab, TextField } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import VanillaDateInput from "./VanillaDateInput";
import VanillaSelectInput from "./VanillaSelectInput";
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import CloseButton from "./FloatingNavButtons/CloseButton";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import horoscopeSvg from '../assets/profile_select_method_horoscope.svg';
import dateSvg from '../assets/profile_select_method_date.svg';
import horoscopeSelectedSvg from '../assets/profile_select_method_horoscope_selected.svg';
import dateSelectedSvg from '../assets/profile_select_method_date_selected.svg';
import SimpleInput from "./SimpleInput";
import SimpleSelectInput from "./SimpleSelectInput";
import zodiakSigns from "../assets/svgs";





const AddProfileModal = () => {

    const addProfile = addProfileStatusStore((state) => state.addProfile)
    const setAddProfile = addProfileStatusStore((state) => state.setAddProfile)


    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)

    const [birthDate1, setBirthDate1] = useState(null)
    const [birthDate2, setBirthDate2] = useState(null)

    const [isChecked, setIsChecked] = useState(0);

    const [male, setHoroscope1] = useState(null);
    const [feMale, setHoroscope2] = useState(null);

    const [profileName, setProfileName] = useState(null)



    const [nameError, setNameError] = useState({ status: false, message: "" })
    const [maleError, setMaleError] = useState({ status: false, message: "" })
    const [femaleError, setFemaleError] = useState({ status: false, message: "" })



    useEffect(() => {
        // console.error(nameError, maleError, femaleError)
    }, [nameError, maleError, maleError])
    useEffect(() => {
        if (!birthDate1) return
        setHoroscope1(getZodiacSign(birthDate1))
        
    }, [birthDate1])
    useEffect(() => {
        if (!birthDate2) return
        setHoroscope2(getZodiacSign(birthDate2))
    }, [birthDate2])



    const checkIfHasSameNameProfile = (name) => {
        let sameNameArr = isValidObject(user.profiles) ? Object.entries(user.profiles).filter(item => item[1].profileName === name) : []
        if(sameNameArr.length > 0) {
            return true
        } else {
            return false
        }
    }

    const handleAddProfile = () => {


        setNameError({ status: !(profileName !== null && profileName?.length > 0), message: profileName !== null && profileName?.length > 0 ? "" : "profilinize bir isim vermediniz." })
        setMaleError({ status: !(male !== null && male?.length > 0), message: male !== null && male?.length > 0 ? "" : "burç seçmediniz." })
        setFemaleError({ status: !(feMale !== null && male?.length > 0), message: feMale !== null && male?.length > 0 ? "" : "burç seçmediniz." })

        if (!checkIfHasSameNameProfile(profileName) && profileName && feMale && male && profileName.length && feMale?.label.length && male?.label.length) {
            push(ref(dataBase, 'users/' + `${user.uid}/` + "profiles/"), {
                profileName: profileName,
                male: male.label,
                feMale: feMale.label,
                value: `${male.label}-${feMale.label}`,
                label: profileName
            }).then(() => {
                setModal({
                    type: "success",
                    status: true,
                    message: "Profil başarıyla eklendi!"
                })
            })
        } else {
            setModal({
                type: "error",
                status: true,
                message: "Profil kayıt edilemedi"
            })
        }
    }

    const handleAddProfileWithDate = () => {

        if (!checkIfHasSameNameProfile(profileName) && profileName && feMale && male && profileName.length && feMale.length && male.length) {
            push(ref(dataBase, 'users/' + `${user.uid}/` + "profiles/"), {
                profileName: profileName,
                male: male,
                feMale: feMale,
                value: `${male}-${feMale}`,
                label: profileName
            }).then(() => {
                setModal({
                    type: "success",
                    status: true,
                    message: "Profil başarıyla eklendi!"
                })
            })
        } else {
            setModal({
                type: "error",
                status: true,
                message: "Profil kayıt edilemedi"
            })
        }
    }

    const horoscopeArr = [
        { label: "Koç", value: 0 },
        { label: "Boğa", value: 1 },
        { label: "İkizler", value: 2 },
        { label: "Yengeç", value: 3 },
        { label: "Aslan", value: 4 },
        { label: "Başak", value: 5 },
        { label: "Terazi", value: 6 },
        { label: "Akrep", value: 7 },
        { label: "Yay", value: 8 },
        { label: "Oğlak", value: 9 },
        { label: "Kova", value: 10 },
        { label: "Balık", value: 11 }
    ];

    const inputColor = "#2873b8"

    const inputStyle = {
        "&": {
            width: "95%",
        },
        "& #standard-basic": {
            color: inputColor
        },
        "& label": {
            color: inputColor
        },
        "& div:before": {
            borderBottom: `2px solid ${inputColor}`
        },
        "& .css-v4u5dn-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: `2px solid ${"#fff"}`
        },
    }



    return (
        <div id={"add-profile-modal"} className={addProfile ? "open" : "close"}>
            <div className={"container"}>
                <div className="left">
                    <Profile />


                </div>
                <div className="right">
                    <div className={"textWrapper"}>
                        <h1>Eşleşme Ekleme</h1>
                        <p>Burç ile eşleşme ekleme</p>
                    </div>
                    <div
                        id="profileSelectMethodWrapper"
                    >
                        <button
                            onClick={() => setIsChecked(0)}
                            className={isChecked === 0 ? "profileSelectMethodButton selected" : "profileSelectMethodButton"}
                        >
                            <img src={isChecked === 0 ? horoscopeSelectedSvg : horoscopeSvg} />
                            <p>Burç ile ekle</p>
                        </button>
                        <button
                            onClick={() => setIsChecked(1)}
                            className={isChecked === 1 ? "profileSelectMethodButton selected" : "profileSelectMethodButton"}
                        >
                            <img src={isChecked === 1 ? dateSelectedSvg : dateSvg} />
                            <p>Doğum tarihi ile ekle</p>
                        </button>
                    </div>
                    <div className={"formWrapper"}>
                        {
                            isChecked === 1 ?
                                <>
                                    {/* <Input set={setProfileName} label={"profile isim ver"} type={"text"} /> */}
                                    <SimpleInput
                                        label={"Eşleşme İsmi"}
                                        placeholder={"Eşleşmeye isim ver"}
                                        set={setProfileName}
                                        type={"text"}
                                    />
                                    <div className="horoscopeSideWrapper">
                                        <div className="labelAndInput">
                                            <label>Erkeğin Doğum Tarihi</label>
                                            <div className={"inputAndZodiac"}>
                                                <VanillaDateInput set={setBirthDate1} />

                                                <div className="zodiacSign">
                                                    {zodiakSigns[zodiakIndexArr.indexOf(male?.label?.toLowerCase())] || <p style={{ color: "#fff" }}>-</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="labelAndInput">
                                            <label>Kadının Doğum Tarihi</label>
                                            <div className={"inputAndZodiac"}>
                                                <VanillaDateInput set={setBirthDate2} />

                                                <div className="zodiacSign feMale">
                                                    {zodiakSigns[zodiakIndexArr.indexOf(feMale?.label?.toLowerCase())] || <p style={{ color: "#fff" }}>-</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>



                                </> :
                                <>
                                    <SimpleInput
                                        label={"Eşleşme İsmi"}
                                        placeholder={"Eşleşmeye isim ver"}
                                        set={setProfileName}
                                    />
                                    <div className="horoscopeSideWrapper">
                                        <div className="labelAndInput">
                                            <label>Erkeğin Burcu</label>
                                            <div className={"inputAndZodiac"}>
                                                <SimpleSelectInput options={horoscopeArr} set={setHoroscope1} selectedOption={male?.label || "Seçmek için tıklayın"} />
                                                <div className="zodiacSign">
                                                    {zodiakSigns[zodiakIndexArr.indexOf(male?.label?.toLowerCase())] || <p style={{ color: "#fff" }}>-</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="labelAndInput">
                                            <label>Kadının burcu</label>
                                            <div className={"inputAndZodiac"}>
                                                <SimpleSelectInput options={horoscopeArr} set={setHoroscope2} selectedOption={feMale?.label || "Seçmek için tıklayın"} />
                                                <div className="zodiacSign feMale">
                                                    {zodiakSigns[zodiakIndexArr.indexOf(feMale?.label?.toLowerCase())] || <p style={{ color: "#fff" }}>-</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                        </div>
                                    </div>
                                </>
                        }
                    </div>

                    <div className={"saveAndCloseButtonsWrapper"}>
                        <Button
                            variant="text"
                            size="large"
                            onClick={() => setAddProfile(false)}
                            sx={{
                                "&": {
                                    backgroundColor: "#888DA8",
                                    color: "#fff",
                                    width: 80,
                                    height: 30,
                                    borderRadius: "100px",
                                    fontSize: "14px"
                                },
                                "&:hover": {
                                    backgroundColor: "#888DA8",

                                },
                            }}
                        >
                            Kapat
                        </Button>
                        <Button
                            variant="text"
                            size="large"
                            onClick={isChecked === 0 ? () => handleAddProfile(profileName) : () => handleAddProfileWithDate()}
                            sx={{
                                "&": {
                                    backgroundColor: "#FA6342",
                                    width: 80,
                                    height: 30,
                                    borderRadius: "100px",
                                    color: "#fff",
                                    fontSize: "14px"
                                },
                                "&:hover": {
                                    backgroundColor: "#FA6342",

                                },
                            }}
                        >
                            Kaydet
                        </Button>

                    </div>
                    {/* <button className="button"  style={{ fontFamily: "sans-serif", fontWeight: "100" }}>Eşleştir</button> */}

                </div>
            </div>
        </div>
    )
}

export default AddProfileModal;