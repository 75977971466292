import { profileStore, userStore } from "../Store";
import "./Header.css"
import OutsideClickHandler from 'react-outside-click-handler';
import Button from '@mui/material/Button';
import zodiakSigns from "../assets/svgs"
import { useEffect, useState } from "react";
import { isValidObject, zodiakIndexArr } from "../utils/utils";

const ProfilesMenu = (props) => {
    const { profilesDropDown, setProfilesDropDown } = props;
    const user = userStore((state) => state.user)
    const profile = profileStore((state) => state.profile)
    const setProfile = profileStore((state) => state.setProfile)

    const triangleSvg = <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.17278 1.21787C7.56956 0.633707 8.43044 0.633706 8.82722 1.21787L15.5994 11.1881C16.0503 11.8521 15.5748 12.75 14.7722 12.75H1.22785C0.425231 12.75 -0.0503451 11.8521 0.400628 11.1881L7.17278 1.21787Z" fill="white" />
    </svg>
    const questionMarkSvg = <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 0.5C15.5078 0.5 20 4.99219 20 10.5C20 16.0469 15.5078 20.5 10 20.5C4.45312 20.5 0 16.0469 0 10.5C0 4.99219 4.45312 0.5 10 0.5ZM10 18.625C14.4531 18.625 18.125 14.9922 18.125 10.5C18.125 6.04688 14.4531 2.375 10 2.375C5.50781 2.375 1.875 6.04688 1.875 10.5C1.875 14.9922 5.50781 18.625 10 18.625ZM10 13.625C10.6641 13.625 11.25 14.1719 11.25 14.875C11.25 15.5781 10.6641 16.125 10 16.125C9.25781 16.125 8.75 15.5781 8.75 14.875C8.75 14.1719 9.29688 13.625 10 13.625ZM11.2891 5.5C12.8516 5.5 14.0625 6.71094 14.0234 8.23438C14.0234 9.17188 13.5156 10.0703 12.6953 10.5781L10.9375 11.6719V11.75C10.9375 12.2578 10.5078 12.6875 10 12.6875C9.49219 12.6875 9.0625 12.2578 9.0625 11.75V11.125C9.0625 10.8125 9.21875 10.5 9.53125 10.3047L11.7578 8.97656C12.0312 8.82031 12.1875 8.54688 12.1875 8.23438C12.1875 7.76562 11.7578 7.375 11.25 7.375H9.25781C8.78906 7.375 8.4375 7.76562 8.4375 8.23438C8.4375 8.74219 8.00781 9.17188 7.5 9.17188C6.99219 9.17188 6.5625 8.74219 6.5625 8.23438C6.5625 6.71094 7.77344 5.5 9.29688 5.5H11.2891Z" fill="#F3F3F3" />
    </svg>



    useEffect(() => {
        if (!isValidObject(profile) && isValidObject(user.profiles)) {
            const item = Object.entries(user?.profiles)[0]
            setProfile({ value: `${item[1].male}-${item[1].feMale}`, label: `${item[1].profileName}` })
        }
        console.info(user.gender)
    }, [])

    return (
        <div id="profileSelectWrapper">
            <OutsideClickHandler
                onOutsideClick={() => setProfilesDropDown(false)}
            >
                <div onClick={() => isValidObject(profile) ? setProfilesDropDown(!profilesDropDown) : null} id="selectedProfileWrapper">
                    {
                        isValidObject(profile)
                            ?
                            <div id={"selectedProfileWrapper"}>
                                {
                                    user.gender === "male" || user.gender === "Erkek" ?
                                        <div className={"zodiakSignWrapper male"}>
                                            {zodiakSigns[zodiakIndexArr.indexOf(profile?.value?.split("-")[0]?.toLowerCase())]}
                                        </div>
                                        : <div className={`zodiakSignWrapper feMale`}>
                                            {zodiakSigns[zodiakIndexArr.indexOf(profile?.value?.split("-")[1]?.toLowerCase())]}
                                        </div>
                                }

                                <div className="selectedProfileNameWrapper">
                                    <p
                                        id="selectedProfileLabel"
                                        className={profile.label.length > 12 ? "" : "fit"}
                                    >
                                        {profile.label}
                                    </p>
                                </div>
                                {
                                    user.gender === "male" || user.gender === "Erkek" ?
                                        <div className={`zodiakSignWrapper feMale`}>
                                            {zodiakSigns[zodiakIndexArr.indexOf(profile?.value?.split("-")[1]?.toLowerCase())]}
                                        </div>

                                        : <div className={"zodiakSignWrapper male"}>
                                            {zodiakSigns[zodiakIndexArr.indexOf(profile?.value?.split("-")[0]?.toLowerCase())]}
                                        </div>
                                }
                                <div className={profilesDropDown ? "down" : "up"}>
                                    {triangleSvg}
                                </div>
                            </div>
                            : <div id={"dosentHaveProfileWrapper"}>
                                <p>Henüz partner eklemediniz</p>
                                {questionMarkSvg}
                            </div>
                    }

                </div>
                <div className={profilesDropDown ? "profle-dropdown open" : "profle-dropdown"}>
                    {triangleSvg}
                    {
                        isValidObject(user.profiles)
                            ? Object.entries(user?.profiles)
                                .map((item) => {
                                    return (
                                        item[1]?.profileName
                                            ?
                                            <Button
                                                key={item[0]}
                                                sx={{
                                                    "&": {
                                                        width: "100%",
                                                        borderWidth: 0,
                                                        backgroundColor: "#fff",
                                                        transition: ".15s",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                        paddingLeft: "20px",
                                                        paddingRight: "20px",
                                                        borderBottom: "1px solid #EDE9E9",
                                                        color: "#515365",
                                                        boxShadow: "none"
                                                        // backgroundColor: profile.label === item[1].label ? "#2873b8" : "#eef7ff",
                                                    },
                                                    "&:hover": {
                                                        boxShadow: "none",
                                                        borderTop: "none",
                                                        borderLeft: "none",
                                                        borderRight: "none",
                                                        borderBottom: "1px solid #EDE9E9",
                                                        backgroundColor: profile.label === item[1].label ? "transparent" : "transparent",
                                                    }
                                                }}
                                                variant={profile.label === item[1].label ? "contained" : "outlined"}
                                                onClick={() => setProfile({ value: `${item[1].male}-${item[1].feMale}`, label: `${item[1].profileName}` })}
                                            >


                                                {
                                                    user.gender === "male" || user.gender === "Erkek" ?
                                                        <div className={"zodiakSignWrapper male"}>
                                                            {zodiakSigns[zodiakIndexArr.indexOf(item[1].male.toLowerCase())]}
                                                        </div>
                                                        : <div className={`zodiakSignWrapper feMale`}>
                                                            {zodiakSigns[zodiakIndexArr.indexOf(item[1].feMale.toLowerCase())]}
                                                        </div>
                                                }
                                                <div className={"profileNamePWrapper"}>
                                                    <p className={item[1]?.profileName?.length < 18 ? 'profileNameP fit' : 'profileNameP'}>
                                                        {item[1]?.profileName}
                                                    </p>
                                                </div>
                                                {
                                                    user.gender === "male" || user.gender === "Erkek" ?

                                                        <div className={`zodiakSignWrapper feMale`}>
                                                            {zodiakSigns[zodiakIndexArr.indexOf(item[1].feMale.toLowerCase())]}
                                                        </div>

                                                        : <div className={"zodiakSignWrapper male"}>
                                                            {zodiakSigns[zodiakIndexArr.indexOf(item[1].male.toLowerCase())]}
                                                        </div>
                                                }

                                            </Button>

                                            : null
                                    )
                                })
                            : <p className='empty-profile-text'>kayıtlı profiliniz bulunmuyor</p>
                    }
                </div>
            </OutsideClickHandler>
        </div>
    )
}

export default ProfilesMenu;