import { Avatar, Box, Skeleton } from "@mui/material";
import "./PostPlaceHolder.css";

const PostPlaceHolder = (props) => {
    const { howMany } = props;


    const postPlaceHolderCountArr = []
    for (let i = 0; i < howMany; i++) {
        postPlaceHolderCountArr.push("dummy")
    }

    const BoxStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        background: "#fff",
        padding: 2,
        borderRadius: 1
    }
    const HeaderStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 2,
    }
    return (
        <div className={"PostPlaceHoldersWrapper"}>
            {
                postPlaceHolderCountArr.map((item, index) => {
                    return (
                        <Box sx={BoxStyle}>
                            <Box sx={HeaderStyle}>
                                <Skeleton variant="circular">
                                    <Avatar />
                                </Skeleton>
                                <Skeleton variant="rectangle" Height={5} width={100} />
                            </Box>

                            <Skeleton variant="rectangle" Height={10} />
                            <Skeleton variant="rectangle" Height={10} />
                            <Skeleton variant="rectangle" Height={10} />
                        </Box>
                    )
                })
            }
        </div>

    )
}

export default PostPlaceHolder;