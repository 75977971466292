import { ref, set } from "firebase/database"
import { useEffect, useState } from "react"
import { auth, dataBase } from "../../fireBaseConfig"
import { modalStore, userStore } from "../../Store"
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail } from "firebase/auth"
import { ClipLoader } from "react-spinners"
import SimpleInput from "../SimpleInput"




const EmailChangeProfileInfoRow = () => {

    const user = userStore((state) => state.user)
    const [newEmail, setNewEmail] = useState("")
    const [currentPassword, setCurrentPassword] = useState("")

    const [passwordNecessary, setPasswordNecessary] = useState(false)
    const [pending, setPending] = useState(false)

    useEffect(() => {
        if (!user) return
        setNewEmail(user.email)
    }, [user])
    const setModal = modalStore((state) => state.setModal)

    const updateUserEmail = async () => {
        setPending(true)
        const currentUser = auth.currentUser;

        const credential = EmailAuthProvider.credential(
            currentUser.email,
            currentPassword
        )

        try {
            await reauthenticateWithCredential(currentUser, credential).then(() => {
                updateEmail(auth.currentUser, newEmail).then(() => {
                    set(ref(dataBase, 'users/' + `${user.uid}/email/`), newEmail)
                })
            })
            setModal({
                type: "success",
                status: true,
                message: `Email değiştirildi.`
            })
        } catch (e) {
            setModal({
                type: "error",
                status: true,
                message: `${e.message}`
            })
        }
        setPending(false)
    }

    const [passwordVisible, setPasswordVisible] = useState(false)
    const tableLampSvg = <svg onClick={() => setPasswordVisible(!passwordVisible)} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M520-120v-60h320v60H520ZM191-580h189v-200H278l-87 200Zm459 360v-420q0-21.25-14.375-35.625T600-690H440v140q0 12.75-8.625 21.375T410-520H145q-16.739 0-25.87-13.5Q110-547 117-562l106-242q8-17 22.548-26.5Q260.095-840 278-840h102q24.75 0 42.375 17.625T440-780v30h160q45.833 0 77.917 32.083Q710-685.833 710-640v420h-60ZM191-580h189-189Z" /></svg>
    const tableLampActiveSvg = <svg onClick={() => setPasswordVisible(!passwordVisible)} xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M550-120q-12.75 0-21.375-8.675-8.625-8.676-8.625-21.5 0-12.825 8.625-21.325T550-180h260q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H550Zm129.825-100Q667-220 658.5-228.625T650-250v-390q0-21.25-14.375-35.625T600-690H440v140q0 12.75-8.625 21.375T410-520H145q-16.739 0-25.87-13.5Q110-547 117-562l106-242q8-17 22.548-26.5Q260.095-840 278-840h102q24.75 0 42.375 17.625T440-780v30h160q45.833 0 77.917 32.083Q710-685.833 710-640v390q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z" /></svg>

    const editSvg = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.1172 3.04688L13.5781 5.50781C13.6875 5.61719 13.6875 5.78125 13.5781 5.89062L7.61719 11.8516L5.07422 12.125C4.74609 12.1797 4.44531 11.8789 4.5 11.5508L4.77344 9.00781L10.7344 3.04688C10.8438 2.9375 11.0078 2.9375 11.1172 3.04688ZM15.5469 2.41797C15.957 2.82812 15.957 3.51172 15.5469 3.92188L14.5898 4.87891C14.4805 4.98828 14.3164 4.98828 14.207 4.87891L11.7461 2.41797C11.6367 2.30859 11.6367 2.14453 11.7461 2.03516L12.7031 1.07812C13.1133 0.667969 13.7969 0.667969 14.207 1.07812L15.5469 2.41797ZM10.625 10.2383C10.625 10.1289 10.6523 10.0469 10.707 9.99219L11.8008 8.89844C12.0195 8.70703 12.375 8.84375 12.375 9.14453V13.4375C12.375 14.1758 11.7734 14.75 11.0625 14.75H1.4375C0.699219 14.75 0.125 14.1758 0.125 13.4375V3.8125C0.125 3.10156 0.699219 2.5 1.4375 2.5H9.23047C9.53125 2.5 9.66797 2.85547 9.47656 3.07422L8.38281 4.16797C8.32812 4.22266 8.24609 4.25 8.13672 4.25H1.875V13H10.625V10.2383Z" fill="#fff"/>
    </svg>
    return (
        <div className="setting-row">
            <p className="label">Email</p>


            <div className="emailAndPasswordNecessaryWrapper"
            // onBlur={(e) => !e.currentTarget.classList.contains("setting-input") ? setPasswordNecessary(false) : 
            >
                 
            <SimpleInput onClick={() => setPasswordNecessary(true)} set={setNewEmail} value={newEmail} />

                <div className={passwordNecessary ? "border-bottom-input-wrapper active" : "border-bottom-input-wrapper passive"}>
                    <input
                        value={currentPassword}
                        // readOnly={passwordNecessary ? false : true}
                        type={passwordVisible ? "text" : "password"}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        className={passwordNecessary ? "setting-input active" : "setting-input passive"}
                    />

                    {
                        passwordNecessary
                            ? passwordVisible ? tableLampActiveSvg : tableLampSvg
                            : null
                    }

                </div>
            </div>

            <button onClick={() => updateUserEmail()}
                disabled={pending ? true : false}
                className={pending ? "edit-button silence" : "edit-button"}
            >


                {
                    pending
                        ? <ClipLoader color="#2873b8" size={15} />
                        : editSvg
                }
            </button>

        </div>
    )
}

export default EmailChangeProfileInfoRow;