import "./MembershipPlans.css"
import headerPng from "../src/assets/membership_header.png"
import { useState } from "react"
import MembershipPlanBox from "./components/MembershipPlanBox"


const MembershipPlans = () => {
    const [nav, setNav] = useState(0)


    const checkSvg = <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.05469 9.8125L1.16406 5.92188C0.929688 5.6875 0.929688 5.28906 1.16406 5.05469L2.00781 4.21094C2.24219 3.97656 2.61719 3.97656 2.85156 4.21094L5.5 6.83594L11.125 1.21094C11.3594 0.976562 11.7344 0.976562 11.9688 1.21094L12.8125 2.05469C13.0469 2.28906 13.0469 2.6875 12.8125 2.92188L5.92188 9.8125C5.6875 10.0469 5.28906 10.0469 5.05469 9.8125Z" fill="#FA6342" />
    </svg>

    function write(a) {
        
    }



    return (
        <div id={"MembershipPlansWrapper"}>
            <div className="header">
                <h1>Paket Satın Alma</h1>
                <img src={headerPng} />
            </div>
            <div className="body">
                <div className="navRow">
                    <button
                        onClick={() => setNav(0)}
                        className={nav === 0 ? "navButton selected" : "navButton"}>
                        Paket Seçimi</button>
                    <button
                        onClick={() => setNav(1)}
                        className={nav === 1 ? "navButton selected" : "navButton"}>
                        Ödeme</button>
                    <button
                        onClick={() => setNav(2)}
                        className={nav === 2 ? "navButton selected" : "navButton"}>
                        Onay
                    </button>
                </div>
                <h1>ÜYEKLİK PLANLARI VE <span>FİYATLARI</span></h1>
                <div className="plansRow">
                    <MembershipPlanBox option={false} nickColor={"#23D2E2"} nick={"Ücretsiz Üyelik"} title={"FREE"} price={0} description={"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. "} />
                    <MembershipPlanBox option={true} optionPrice={100} nickColor={"#7750F8"} nick={"Popüler Üyelik"} title={"BASIC"} price={50} description={"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. "} />
                    <MembershipPlanBox option={false} nickColor={"#FA6342"} nick={"Değerli Üyelik"} title={"PREMIUM"} price={900} description={"Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. "} />
                </div>
            </div>

        </div>
    )


}

export default MembershipPlans;