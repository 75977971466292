import "./DesctopContentsSide.css"
import giftIcon from "../assets/gift.svg"
import Content from "./Content";
import { aboutDirectionStore, contentDirectionStore, postsDirectionStore, postsStore, profileStore, selectedContentStore, selectedSubTitleStore, selectedTitleStore, userStore } from "../Store";
import { useEffect, useState } from "react";
import { child, onValue, ref } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import TitleContent from "./TitleContent"
import { findContrastingColor, generateAndPlaceColorTones, generateAndPlaceColorTonesForText, generateColorTones, isValidAndNotEmptyArray, isValidObject, stringToMatColor, stringToMatColorAdvenced } from "../utils/utils";


const DesctopContentsSide = (props) => {
    const { setIsOpen, className, setContentMenuStatus } = props;


    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const content = selectedContentStore((state) => state.content)
    const setContent = selectedContentStore((state) => state.setContent)

    const selectedTitle = selectedTitleStore((state) => state.title)
    const setSelectedTitle = selectedTitleStore((state) => state.setTitle)


    const profile = profileStore((state) => state.profile)

    const postsDirection = postsDirectionStore((state) => state.postsDirection)
    const setPostsDirection = postsDirectionStore((state) => state.setPostsDirection)

    const aboutDirection = aboutDirectionStore((state) => state.aboutDirection)
    const setAboutDirection = aboutDirectionStore((state) => state.setAboutDirection)

    const setContentDirection = contentDirectionStore((state) => state.setContentDirection)

    const selectedSubTitle = selectedSubTitleStore((state) => state.selectedSubTitle)
    const setSelectedSubTitle = selectedSubTitleStore((state) => state.setSelectedSubTitle)

    const dataBasePosts = postsStore((state) => state.posts)
    const setDataBasePosts = postsStore((state) => state.setPosts)

    useEffect(() => {
        if (profile && profile.value) {

            // const contentRef = child(ref(dataBase), `contents/` + `${profile.value.replace("&", "-")}/`)
            // onValue(contentRef, (snapshot) => {
            //     if (snapshot.val()) {
            //         setContent(snapshot.val())
            //         console.error("Content get error: ")


            //     } else {
            //         console.error("Content get error: ")
            //         setContent({})
            //     }
            // });
            const contentRef = child(ref(dataBase), `contents/`)
            onValue(contentRef, (snapshot) => {
                if (snapshot.val()) {
                    
                    setContent(snapshot.val())
                    console.error("Content get error: ")


                } else {
                    console.error("Content get error: ")
                    setContent({})
                }
            });
        }
    }, [profile])



    const [selectedSubTitleArr, setSelectedSubTitleArr] = useState([])

    useEffect(() => {
        if (selectedTitle) {
            const selectedSubTitleArrsDummy = Object.entries(content).find((item) => item[0] == selectedTitle[0])
            setSelectedSubTitleArr(selectedSubTitleArrsDummy)
            // console.error("selected", Object.entries(content).find((item) => item[0] == selectedTitle[0]))

        }

        if (selectedTitle && selectedTitle[0] !== undefined) {
            localStorage.setItem("selectedTitle", JSON.stringify(selectedTitle))
        }

    }, [selectedTitle])



    const [height, setHeight] = useState(0)
    useEffect(() => {
        if (isValidObject(selectedTitle) && selectedTitle.hasOwnProperty("subTitles")) {
            setHeight(Object.keys(selectedTitle.subTitles).length * 41)
        }
    }, [selectedTitle])

    

    useEffect(() => {
        console.info(selectedSubTitle)

        if (selectedSubTitle && selectedSubTitle[0] !== undefined) {
            localStorage.setItem("selectedSubTitle", JSON.stringify(selectedSubTitle))
        }
    }, [selectedSubTitle])

    useEffect(() => {
        
     

        if(isValidAndNotEmptyArray(content)) {
            if (!isValidObject(selectedTitle) || !isValidObject(selectedSubTitle)) {
                setSelectedTitle(content[0])
                console.warn("çalışır mı?")
                setSelectedSubTitle(Object.entries(content[0].subTitles)[0])
            }else {
                console.warn("çalışmadı!")
            }
        }else {
            console.warn("çalışmadı!")

        }
    }, [content])



    useEffect(() => {
        console.warn("selectedSubTitle", selectedSubTitle, selectedTitle, profile, selectedTitle)
        // selectedSubTitle && selectedSubTitle[0] !== undefined &&
        if (isValidObject(selectedTitle) && selectedSubTitle && selectedSubTitle[0] !== undefined && isValidObject(profile)) {
            // console.log(profile?.value)
            setPostsDirection(`${profile.value}/${selectedTitle.id}/${selectedSubTitle[0]}/posts/`)
            setAboutDirection(`${profile.value}/${selectedTitle.id}/${selectedSubTitle[0]}/about/`)
            setContentDirection(`${profile.value}/${selectedTitle.id}/${selectedSubTitle[0]}/`)

            localStorage.setItem("selectedContentPath", `${profile.value}/${selectedTitle.id}/${selectedSubTitle[0]}`)
        }else {
            localStorage.removeItem("selectedContentPath")
        }

    }, [selectedSubTitle, profile])

    const titleColors = ["#FC8DCA", "#C37EDB", "#B7A6F6", "#88A3E2", "#AAECFC", "#FFA39C", "#FFBEAF"]

    return (
        <>
            {
                content.length !== 0 ?
                    <div id='contentSideWrapper' className={isValidAndNotEmptyArray(content) ? className : "none"}>
                        <div className={"scrollX"}>
                            <div className={"firstCol"}>
                                <div onClick={() => setContentMenuStatus(false)} className={"contentSideHeader"}>
                                    <button></button>
                                    <h1>Menüyü Kapat</h1>
                                </div>
                                {
                                    profile !== "" && isValidAndNotEmptyArray(content) && content?.map((item, index) => {
                                        // console.info(selectedSubTitle, selectedTitle, title)
                                        return (
                                            <div style={{ background: titleColors[index] }}>

                                                <div onClick={() => setSelectedTitle(item)}
                                                    className={isValidObject(selectedTitle) && selectedTitle.id === item.id ? "content-wrapper active" : "content-wrapper"}>
                                                    <p className="content-title">{
                                                        item.label
                                                    }</p>
                                                </div>

                                                {/* iki true yerinde selectedSubTitle vardı ve null dönüyordu.*/}
                                                <div className={selectedSubTitle && selectedTitle && item.id == selectedTitle.id ? "sub-title-wrapper open" : "sub-title-wrapper"}
                                                    style={
                                                        {
                                                            height: selectedTitle && item.id === selectedTitle.id ? `${height}px` : "0px"
                                                        }
                                                    }
                                                >
                                                    {
                                                         Object.entries(item.subTitles)?.map((subTitle, index) => {

                                                            // console.error(title[0].replaceAll("_", " ").toLocaleUpperCase("tr"))
                                                            return (
                                                                <div onClick={() => {
                                                                    setSelectedSubTitle(subTitle)
                                                                }}
                                                                    style={{ backgroundColor: "rgba(255, 255, 255, 0.4)" }}
                                                                    className={selectedSubTitle && subTitle[0] == selectedSubTitle[0] ? "content-wrapper selected" : "content-wrapper"}>
                                                                    <p
                                                                        className="content-title">{subTitle[1].label}</p>
                                                                </div >
                                                            )
                                                        })
                                                    }

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div> :
                    null
            }
        </>
    )
}

export default DesctopContentsSide;