import ReactQuill, {Quill} from "react-quill";


const CustomReactQuill = (props) => {
    const {html ,setHtml ,setP} = props;



    const handle = (editor) => {
        if (editor !== "") {
            setP(editor.getText())
            setHtml(editor.getHTML())
            
        }
    }
    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': ['50px', false, 'large', 'huge'] }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': ['Ubuntu', 'Raleway', 'Roboto'] }],
        [{ 'align': [] }],
        [{"something": ["2", "3"]}]

        ['clean']                                         // remove formatting button
    ];


    let formats = [
        "header",
        "font",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "background",
        "code",
        "script",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
      ];

    return (
        <div>
            <ReactQuill
                modules={
                    { toolbar: toolbarOptions }
                }
                theme="snow" style={{ width: "100%" }} formats={formats} value={html} onChange={(a, b, c, d, e) => handle(d)}
            />
        </div>
    )
}

export default CustomReactQuill;