import { Avatar, Box, Skeleton } from "@mui/material";


const BannerPlaceHolder = () => {




    const BoxStyle = {
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 1,
        borderRadius: 1,
        marginBottom: 2,
    }
    const HeaderStyle = {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 2,
    }

    return (
        <Box sx={BoxStyle}>
            <Skeleton variant="rectangle" height={"100%"} />
        </Box>
    )
}

export default BannerPlaceHolder;