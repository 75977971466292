import { useEffect, useState } from "react";
import DesctopContentsSide from "./DesctopContentsSide";
import "./SideBar.css"
import { Tooltip } from "@mui/material";
import { addProfileStatusStore, handleMessagePageStore, profileStore } from "../Store";
import { useLocation, useNavigate } from "react-router-dom";
import { isValidObject } from "../utils/utils";

const SideBar = () => {

    const location = useLocation()
    const navigate = useNavigate()

    const [contentMenuStatus, setContentMenuStatus] = useState(false)

    const messageData = handleMessagePageStore((state) => state.messageData)
    const setMessageData = handleMessagePageStore((state) => state.setMessageData)

    const addProfile = addProfileStatusStore((state) => state.addProfile)
    const setAddProfile = addProfileStatusStore((state) => state.setAddProfile)

    const profile = profileStore((state) => state.profile)


    useEffect(() => {
        if (location.pathname !== "/home" && location.pathname !== "/" || messageData.status === true || !isValidObject(profile)) {
            setContentMenuStatus(false)
        } else if (window?.innerWidth > 800) {
            setContentMenuStatus(true)
        }
    }, [location, messageData])

    const hamburgerMenuSvg = <svg width="13" height="11" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.375 1.375C0.375 0.910156 0.757812 0.5 1.25 0.5H11.75C12.2148 0.5 12.625 0.910156 12.625 1.375C12.625 1.86719 12.2148 2.25 11.75 2.25H1.25C0.757812 2.25 0.375 1.86719 0.375 1.375ZM0.375 5.75C0.375 5.28516 0.757812 4.875 1.25 4.875H11.75C12.2148 4.875 12.625 5.28516 12.625 5.75C12.625 6.24219 12.2148 6.625 11.75 6.625H1.25C0.757812 6.625 0.375 6.24219 0.375 5.75ZM11.75 11H1.25C0.757812 11 0.375 10.6172 0.375 10.125C0.375 9.66016 0.757812 9.25 1.25 9.25H11.75C12.2148 9.25 12.625 9.66016 12.625 10.125C12.625 10.6172 12.2148 11 11.75 11Z" fill="white" />
    </svg>
    const usersSvg = <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.875 5.875C1.89062 5.875 1.125 5.10938 1.125 4.125C1.125 3.16797 1.89062 2.375 2.875 2.375C3.83203 2.375 4.625 3.16797 4.625 4.125C4.625 5.10938 3.83203 5.875 2.875 5.875ZM15.125 5.875C14.1406 5.875 13.375 5.10938 13.375 4.125C13.375 3.16797 14.1406 2.375 15.125 2.375C16.082 2.375 16.875 3.16797 16.875 4.125C16.875 5.10938 16.082 5.875 15.125 5.875ZM16 6.75C16.957 6.75 17.75 7.54297 17.75 8.5V9.375C17.75 9.86719 17.3398 10.25 16.875 10.25H15.0703C14.8789 8.96484 14.1133 7.87109 12.9922 7.26953C13.3203 6.96875 13.7578 6.75 14.25 6.75H16ZM9 6.75C7.30469 6.75 5.9375 5.38281 5.9375 3.6875C5.9375 2.01953 7.30469 0.625 9 0.625C10.668 0.625 12.0625 2.01953 12.0625 3.6875C12.0625 5.38281 10.668 6.75 9 6.75ZM11.0781 7.625C12.8281 7.625 14.25 9.04688 14.25 10.7969V11.5625C14.25 12.3008 13.6484 12.875 12.9375 12.875H5.0625C4.32422 12.875 3.75 12.3008 3.75 11.5625V10.7969C3.75 9.04688 5.14453 7.625 6.89453 7.625H7.11328C7.6875 7.89844 8.31641 8.0625 9 8.0625C9.65625 8.0625 10.2852 7.89844 10.8594 7.625H11.0781ZM4.98047 7.26953C3.85938 7.87109 3.09375 8.96484 2.90234 10.25H1.125C0.632812 10.25 0.25 9.86719 0.25 9.375V8.5C0.25 7.54297 1.01562 6.75 2 6.75H3.75C4.21484 6.75 4.65234 6.96875 4.98047 7.26953Z" fill="white" />
    </svg>
    const partnersSvg = <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.97266 0.75C9.35547 0.75 9.65625 1.05078 9.62891 1.40625V14.0938C9.62891 14.4766 9.30078 14.75 8.97266 14.75C8.61719 14.75 8.34375 14.4766 8.34375 14.0938V1.40625C8.34375 1.05078 8.61719 0.75 8.97266 0.75ZM6.07422 5.45312L7.14062 8.78906C7.30469 9.25391 7.05859 9.74609 6.59375 9.91016C6.48438 9.9375 6.40234 9.96484 6.32031 9.96484C6.04688 9.96484 5.82812 9.82812 5.66406 9.63672V13.875C5.66406 14.3672 5.28125 14.75 4.81641 14.75C4.32422 14.75 3.94141 14.3672 3.94141 13.875V9.9375H3.50391V13.875C3.50391 14.3672 3.12109 14.75 2.65625 14.75C2.16406 14.75 1.78125 14.3672 1.78125 13.875V9.66406C1.53516 9.91016 1.17969 10.0195 0.824219 9.91016C0.386719 9.74609 0.140625 9.25391 0.277344 8.78906L1.39844 5.45312C1.64453 4.74219 2.30078 4.25 3.06641 4.25H4.40625C5.17188 4.25 5.82812 4.74219 6.07422 5.45312ZM3.75 3.375C3.01172 3.375 2.4375 2.80078 2.4375 2.0625C2.4375 1.35156 3.01172 0.75 3.75 0.75C4.46094 0.75 5.03516 1.35156 5.03516 2.0625C5.03516 2.80078 4.46094 3.375 3.75 3.375ZM14.2227 3.375C13.5117 3.375 12.9375 2.80078 12.9375 2.0625C12.9375 1.35156 13.457 0.75 14.2227 0.75C14.9609 0.75 15.5352 1.35156 15.5352 2.0625C15.5352 2.80078 14.9336 3.375 14.2227 3.375ZM17.6953 8.78906C17.832 9.25391 17.5859 9.74609 17.0391 9.91016C16.957 9.9375 16.8477 9.96484 16.7656 9.96484L17.2031 11.25H16.1367V13.875C16.1367 14.3672 15.7266 14.75 15.2617 14.75C14.7969 14.75 14.4141 14.3672 14.4141 13.875V11.25H13.9766V13.875C13.9766 14.3672 13.5391 14.75 13.1016 14.75C12.6367 14.75 12.2539 14.3672 12.2539 13.875V11.25H11.1602L11.625 9.9375C11.5156 9.9375 11.4336 9.9375 11.3242 9.91016C10.8867 9.74609 10.6406 9.25391 10.7773 8.78906L11.8984 5.45312C12.1445 4.74219 12.8008 4.25 13.5664 4.25H14.9062C15.6719 4.25 16.3281 4.74219 16.5742 5.45312L17.6953 8.78906Z" fill="white" />
    </svg>
    const questionsSvg = <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.77344 0.75C8.07031 0.75 10.5586 2.58203 10.5586 4.96094C10.5586 8.13281 7.14062 8.1875 7.14062 9.36328V9.5C7.14062 9.88281 6.83984 10.1562 6.48438 10.1562H4.48828C4.13281 10.1562 3.83203 9.88281 3.83203 9.5V9.25391C3.83203 7.55859 5.11719 6.875 6.10156 6.32812C6.92188 5.86328 7.44141 5.5625 7.44141 4.93359C7.44141 4.11328 6.40234 3.59375 5.55469 3.59375C4.48828 3.59375 3.96875 4.08594 3.28516 4.93359C3.06641 5.20703 2.65625 5.26172 2.38281 5.04297L1.20703 4.14062C0.90625 3.94922 0.851562 3.53906 1.04297 3.26562C2.16406 1.65234 3.58594 0.75 5.77344 0.75ZM5.5 10.9766C6.53906 10.9766 7.38672 11.8242 7.38672 12.8633C7.38672 13.9023 6.53906 14.75 5.5 14.75C4.43359 14.75 3.58594 13.9023 3.58594 12.8633C3.58594 11.8242 4.43359 10.9766 5.5 10.9766Z" fill="white" />
    </svg>
    const lightBulbSvg = <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.3125 13.1914V12.125H7.6875L7.66016 13.1914C7.66016 13.3281 7.60547 13.5469 7.52344 13.6562L7.05859 14.3672C6.92188 14.5859 6.59375 14.75 6.32031 14.75H4.65234C4.37891 14.75 4.05078 14.5859 3.91406 14.3672L3.44922 13.6562C3.33984 13.5195 3.3125 13.3555 3.3125 13.1914ZM5.47266 0.777344C8.125 0.75 10.3125 2.91016 10.3125 5.5625C10.3125 6.79297 9.84766 7.88672 9.10938 8.70703C8.64453 9.22656 7.96094 10.3203 7.6875 11.2227C7.6875 11.2227 7.6875 11.2227 7.6875 11.25H3.28516C3.28516 11.2227 3.28516 11.2227 3.28516 11.2227C3.01172 10.3203 2.32812 9.22656 1.86328 8.70703C1.125 7.85938 0.6875 6.76562 0.6875 5.5625C0.6875 3.01953 2.68359 0.777344 5.47266 0.777344ZM5.5 3.40234C5.71875 3.40234 5.9375 3.21094 5.9375 2.96484C5.9375 2.74609 5.71875 2.52734 5.5 2.52734C3.80469 2.52734 2.4375 3.89453 2.4375 5.5625C2.4375 5.80859 2.62891 6 2.875 6C3.09375 6 3.3125 5.80859 3.3125 5.5625C3.3125 4.35938 4.26953 3.40234 5.5 3.40234Z" fill="white" />
    </svg>

    const homeSvg = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.8477 7.75C15.8477 8.24219 15.4375 8.625 14.9727 8.625H14.0977L14.125 13C14.125 13.082 14.125 13.1641 14.125 13.2188V13.6562C14.125 14.2852 13.6328 14.75 13.0312 14.75H12.5938C12.5391 14.75 12.5117 14.75 12.4844 14.75C12.457 14.75 12.4023 14.75 12.375 14.75H10.8438C10.2148 14.75 9.75 14.2852 9.75 13.6562V11.25C9.75 10.7852 9.33984 10.375 8.875 10.375H7.125C6.63281 10.375 6.25 10.7852 6.25 11.25V13.6562C6.25 14.2852 5.75781 14.75 5.15625 14.75H3.625C3.57031 14.75 3.54297 14.75 3.48828 14.75C3.46094 14.75 3.43359 14.75 3.40625 14.75H2.96875C2.33984 14.75 1.875 14.2852 1.875 13.6562V10.5938C1.875 10.5938 1.875 10.5664 1.875 10.5391V8.625H1C0.507812 8.625 0.125 8.24219 0.125 7.75C0.125 7.50391 0.207031 7.28516 0.398438 7.09375L7.39844 0.996094C7.58984 0.804688 7.80859 0.75 8 0.75C8.19141 0.75 8.41016 0.832031 8.57422 0.96875L15.5469 7.09375C15.7656 7.28516 15.875 7.50391 15.8477 7.75Z" fill="white" />
    </svg>



    return (
        <div id={"sideBarWrapper"}>
            <div id="sideBar" className={contentMenuStatus ? "" : "open"}>
                {
                    messageData?.status === false && (location.pathname === "/home" || "/") && isValidObject(profile)
                        ? <Tooltip title="İçerik Menüsü" placement="right">
                            <button
                                onClick={() => setContentMenuStatus(!contentMenuStatus)}
                            >
                                {hamburgerMenuSvg}
                            </button>
                        </Tooltip>
                        : <span className="toDissapear" />
                }
                {
                    location.pathname !== "/" && location.pathName !== "/home" || messageData.status === true ?
                        <Tooltip title="Ana Menü" placement="right">
                            <button
                                onClick={() => messageData.status === true ? setMessageData({ whom: "", status: false }) : navigate("/home")}
                            >
                                {homeSvg}
                            </button>
                        </Tooltip>
                        : null
                }
                <Tooltip title="Yazar Arama" placement="right">
                    <button
                        onClick={() => {
                            navigate("/writer-search")
                        }}
                    >
                        {usersSvg}
                    </button>
                </Tooltip>

                <Tooltip title="Partner Ekle" placement="right">

                    <button
                        onClick={() => setAddProfile(true)}
                    >
                        {partnersSvg}
                    </button>
                </Tooltip>

                <Tooltip title="Sık Sorulanlar" placement="right">

                    <button
                        onClick={() => navigate("/s.s.s")}
                    >
                        {questionsSvg}
                    </button>
                </Tooltip>

                <Tooltip title="Ampul" placement="right">

                    <button
                        onClick={() => navigate("/security")}
                    >
                        {lightBulbSvg}
                    </button>
                </Tooltip>

            </div>
            <DesctopContentsSide
                setContentMenuStatus={setContentMenuStatus}
                className={contentMenuStatus ? "open" : ""}
            />
        </div>
    )
}

export default SideBar;