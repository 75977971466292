import React, { useEffect, useRef, useState } from 'react'
import {
    Link,
    BrowserRouter as Router,
    useNavigate
} from "react-router-dom";

import "./Register.css"
import Input from './components/Input';
import SelectorInput from './components/SelectorInput';
import { auth, fireStore, dataBase } from "./fireBaseConfig"
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { set, ref } from "firebase/database";
import TimePicker from "./components/TimePicker"
import { doc, getDoc } from "firebase/firestore";
import Modal from './components/Modal';
import { userStore, modalStore } from './Store';
import { Helmet } from "react-helmet";
import zodyakiSvg from "./assets/zodyaki.svg";
import loginCircle from "./assets/login-circle.svg";


const ForgotPassword = () => {
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [passwordConfirm, setPasswordConfirm] = useState(null)
    const [gender, setGender] = useState(null)
    const [birthDate, setBirthDate] = useState(null)
    const [togglePassword, setTogglePassword] = useState(false)


    const aaaa = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)
  



    useEffect(() => {
    }, [email, password, passwordConfirm, gender, birthDate])


    const resetPassword = () => {

        if (email != null) {
            sendPasswordResetEmail(auth, email)
                .then(() => {
                    // Password reset email sent!
                    // ..
                    setModal({
                        type: "success",
                        status: true,
                        message: `${email} adresine kontrol ediniz`
                    })

                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ..
                });


        }

    }

    const mailSvg = <svg width="800px" height="800px" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="10" y="2" width="20" height="28" rx="2" fill="#1066B5" />
        <rect x="10" y="2" width="20" height="28" rx="2" fill="url(#paint0_linear_87_7742)" />
        <rect x="10" y="5" width="10" height="10" fill="#32A9E7" />
        <rect x="10" y="15" width="10" height="10" fill="#167EB4" />
        <rect x="20" y="15" width="10" height="10" fill="#32A9E7" />
        <rect x="20" y="5" width="10" height="10" fill="#58D9FD" />
        <mask id="mask0_87_7742" maskUnits="userSpaceOnUse" x="8" y="14" width="24" height="16">
            <path d="M8 14H30C31.1046 14 32 14.8954 32 16V28C32 29.1046 31.1046 30 30 30H10C8.89543 30 8 29.1046 8 28V14Z" fill="url(#paint1_linear_87_7742)" />
        </mask>
        <g mask="url(#mask0_87_7742)">
            <path d="M32 14V18H30V14H32Z" fill="#135298" />
            <path d="M32 30V16L7 30H32Z" fill="url(#paint2_linear_87_7742)" />
            <path d="M8 30V16L33 30H8Z" fill="url(#paint3_linear_87_7742)" />
        </g>
        <path d="M8 12C8 10.3431 9.34315 9 11 9H17C18.6569 9 20 10.3431 20 12V24C20 25.6569 18.6569 27 17 27H8V12Z" fill="#000000" fill-opacity="0.3" />
        <rect y="7" width="18" height="18" rx="2" fill="url(#paint4_linear_87_7742)" />
        <path d="M14 16.0693V15.903C14 13.0222 11.9272 11 9.01582 11C6.08861 11 4 13.036 4 15.9307V16.097C4 18.9778 6.07278 21 9 21C11.9114 21 14 18.964 14 16.0693ZM11.6424 16.097C11.6424 18.0083 10.5665 19.1579 9.01582 19.1579C7.46519 19.1579 6.37342 17.9806 6.37342 16.0693V15.903C6.37342 13.9917 7.44937 12.8421 9 12.8421C10.5348 12.8421 11.6424 14.0194 11.6424 15.9307V16.097Z" fill="white" />
        <defs>
            <linearGradient id="paint0_linear_87_7742" x1="10" y1="16" x2="30" y2="16" gradientUnits="userSpaceOnUse">
                <stop stop-color="#064484" />
                <stop offset="1" stop-color="#0F65B5" />
            </linearGradient>
            <linearGradient id="paint1_linear_87_7742" x1="8" y1="26.7692" x2="32" y2="26.7692" gradientUnits="userSpaceOnUse">
                <stop stop-color="#1B366F" />
                <stop offset="1" stop-color="#2657B0" />
            </linearGradient>
            <linearGradient id="paint2_linear_87_7742" x1="32" y1="23" x2="8" y2="23" gradientUnits="userSpaceOnUse">
                <stop stop-color="#44DCFD" />
                <stop offset="0.453125" stop-color="#259ED0" />
            </linearGradient>
            <linearGradient id="paint3_linear_87_7742" x1="8" y1="23" x2="32" y2="23" gradientUnits="userSpaceOnUse">
                <stop stop-color="#259ED0" />
                <stop offset="1" stop-color="#44DCFD" />
            </linearGradient>
            <linearGradient id="paint4_linear_87_7742" x1="0" y1="16" x2="18" y2="16" gradientUnits="userSpaceOnUse">
                <stop stop-color="#064484" />
                <stop offset="1" stop-color="#0F65B5" />
            </linearGradient>
        </defs>
    </svg>

    const keySvg = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35 0H25C22.2383 0 20 2.23828 20 5V17L0.366455 36.6336C-0.121826 37.1219 -0.121826 37.9133 0.366455 38.4016L1.59849 39.6336C2.08677 40.1219 2.87817 40.1219 3.36646 39.6336L6.19849 36.8016L9.01958 39.6227C9.50786 40.1109 10.2993 40.1109 10.7875 39.6227L14.1594 36.2508C14.6477 35.7625 14.6477 34.9711 14.1594 34.4828L11.3383 31.6617L13.75 29.25L16.6336 32.1336C17.1219 32.6219 17.9133 32.6219 18.4016 32.1336L19.6336 30.9016C20.1219 30.4133 20.1219 29.6219 19.6336 29.1336L16.75 26.25L23 20H35C37.7618 20 40 17.7617 40 15V5C40 2.23828 37.7618 0 35 0ZM29.268 14.268C28.2915 15.2445 26.7086 15.2445 25.7321 14.268C24.7555 13.2914 24.7555 11.7086 25.7321 10.7328C26.7086 9.75625 28.2915 9.75625 29.268 10.7328C30.2438 11.7086 30.2438 13.2914 29.268 14.268ZM34.268 9.26797C33.2915 10.2445 31.7086 10.2445 30.7321 9.26797C29.7555 8.29141 29.7555 6.70859 30.7321 5.73281C31.7086 4.75625 33.2915 4.75625 34.268 5.73281C35.2438 6.70859 35.2438 8.29141 34.268 9.26797Z" fill="white" />
    </svg>
    const gmailSvg = <svg xmlns="http://www.w3.org/2000/svg"
        aria-label="Gmail" role="img"
        viewBox="0 0 512 512"><rect
            width="512" height="512"
            rx="15%"
            fill="#ffffff" /><path d="M158 391v-142l-82-63V361q0 30 30 30" fill="#4285f4" /><path d="M 154 248l102 77l102-77v-98l-102 77l-102-77" fill="#ea4335" /><path d="M354 391v-142l82-63V361q0 30-30 30" fill="#34a853" /><path d="M76 188l82 63v-98l-30-23c-27-21-52 0-52 26" fill="#c5221f" /><path d="M436 188l-82 63v-98l30-23c27-21 52 0 52 26" fill="#fbbc04" /></svg>

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Zodyaki | Şifremi Unuttum</title>
            </Helmet>
            
            <div id={"login-container"} className={"forgotPassword"}>
                <img id='login-circle' src={loginCircle}></img>
                <h1 className='title'>
                    {keySvg}
                    Şifremi unuttum
                </h1>
                <div className="form-wrapper">
                    <span className='line1'></span>
                    <span className='line2'></span>
                    <span className='line3'></span>

                    <Input set={setEmail} label={"Mail Adresiniz"} type={"email"} />
                    <button className="button" onClick={() => resetPassword()}>E-posta'yı Gönder</button>

                    <div className='register-forgot-password-wraper'>
                        <Link to='/login' >Giriş Yap</Link>

                        {/* <div className='gmailLinks'>
                            <Link target='_blank' className='mailLink' to='https://accounts.google.com/AccountChooser/signinchooser?service=mail&continue=https%3A%2F%2Fmail.google.com%2Fmail%2F&flowName=GlifWebSignIn&flowEntry=AccountChooser' >{gmailSvg}</Link>
                            <Link target='_blank' className='mailLink' to='https://login.live.com/login.srf' >{mailSvg}</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;
