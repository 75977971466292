import "./Followers.css"
import { userStore, modalStore, myFollowerStore } from '../Store';
import { useEffect, useState } from "react";
import { useRef } from "react";
import { child, get, ref } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import Follower from "./Follower";
import { getProfileUserFollowersByUid, getUsersNameByUid } from "../utils/utils";
import { Input } from "@mui/material";
import SearchInput from "./SearchInput";


const Followers = (props) => {

    const { uid } = props
    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const followers = myFollowerStore(state => state.followers)
    const setFollowers = myFollowerStore(state => state.setFollowers)

    useEffect(() => {
        getProfileUserFollowersByUid(uid).then(data => {
            setFollowersNames([])
            setFollowers(data)
        }
        )
    }, [uid])

    const [searchedValue, setSearchedValue] = useState("")
    const [followersNames, setFollowersNames] = useState([])
    const [foundedNames, setFoundNames] = useState([])

    function searchedTextDifference(str1, str2) {
        const m = str1.length;
        const n = str2.length;
        const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0));

        for (let i = 0; i <= m; i++) {
            for (let j = 0; j <= n; j++) {
                if (i === 0) {
                    dp[i][j] = j;
                } else if (j === 0) {
                    dp[i][j] = i;
                } else if (str1[i - 1] === str2[j - 1]) {
                    dp[i][j] = dp[i - 1][j - 1];
                } else {
                    dp[i][j] = 1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1]);
                }
            }
        }

        return dp[m][n];
    }

    function findSimilarTexts(searchedVal, ListInSearched) {
        const similarTexts = ListInSearched.filter((text) => {
            return searchedTextDifference(searchedVal.toLowerCase(), text[1].toLowerCase()) <= 2 || text[1].toLowerCase().includes(searchedVal.toLowerCase());
        });

        return similarTexts;
    }

    useEffect(() => {
        setFoundNames(findSimilarTexts(searchedValue, followersNames))


    }, [searchedValue])


    useEffect(() => {
        // findSimilarTexts(searchedValue, Object.entries(followers))

    }, [followersNames])

    useEffect(() => {
        // findSimilarTexts(searchedValue, Object.entries(followers))

    }, [foundedNames])
    useEffect(() => {
        if (!followers) return
        Object.entries(followers).forEach((follower) => {
            getUsersNameByUid(follower[0]).then((name) => { setFollowersNames((prev) => prev.find((item) => item[0] === follower[0]) ? prev : [...prev, [follower[0], name]]) })
        })
        
    }, [followers])



    return (
        <div id={"followersWrapper"}>
            <div className="header">
                <p>Takipçiler</p>
                <SearchInput set={setSearchedValue} />
            </div>

            <div className="body">
                {
                    user && followers && followersNames.filter(item => foundedNames.map(item => item[1]).includes(item[1]) || searchedValue === "").map((item) => {
                        return <Follower key={item[0]} uid={item[0]} />
                    })
                }
            </div>
        </div>
    )
}

export default Followers;