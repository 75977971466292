import { useEffect, useState } from "react"
import "./SearchedUserRow.css"
import { calcAge, checkIfIFollow, cities, dateFormatter, getBirthDateByUid, getCityLabelFromCityValue, getProfilePhotoByUid, getProfileUserCityByUid, getUsersNameByUid, getZodiacSign, handleFallow, stringToMatColor, zodiakIndexArr } from "../utils/utils"
import zodiakSigns from "../assets/svgs"
import { handleMessagePageStore, userStore } from "../Store"

const SearchedUserRow = (props) => {
    const { userUid } = props;

    const user = userStore((state) => state.user)
    const setMessageData = handleMessagePageStore((state) => state.setMessageData)

    const [photo, setPhoto] = useState("")
    const [name, setName] = useState("")
    const [age, setAge] = useState("")
    const [birthDate, setBirthDate] = useState("")
    const [city, setCity] = useState("")

    const [a, setA] = useState("")

    useEffect(() => {
        getUsersNameByUid(userUid).then(name => setName(name))
        getProfilePhotoByUid(userUid).then(url => setPhoto(url))
        getBirthDateByUid(userUid).then(date => {
            setAge(calcAge(date))
            setBirthDate(date)
        })
        getProfileUserCityByUid(userUid).then(city => setCity(city))

    }, [userUid])


    useEffect(() => {
        if (birthDate && birthDate !== "") {
            if (getZodiacSign(birthDate) && getZodiacSign(birthDate) !== "") {
                setA(getZodiacSign(birthDate).toLocaleLowerCase())
            }
        }
    }, [birthDate])



    const heartSvg = <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.7305 1.22461C14.3301 2.5957 14.4062 5.0332 12.9844 6.50586L8.05859 11.584C7.75391 11.9141 7.2207 11.9141 6.91602 11.584L1.99023 6.50586C0.568359 5.0332 0.644531 2.5957 2.24414 1.22461C3.64062 0.03125 5.72266 0.259766 6.99219 1.58008L7.5 2.08789L7.98242 1.58008C9.27734 0.259766 11.334 0.03125 12.7305 1.22461Z" fill="#B3B1C5" />
    </svg>
    const dummy = <svg width="23" height="15" viewBox="0 0 23 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.6193 4.44842C13.6193 3.26512 12.6603 2.30609 11.4763 2.30609C10.293 2.30609 9.3332 3.26586 9.3332 4.44842C9.3332 5.51147 10.1085 6.39157 11.1229 6.55977L8.52393 15H13.1206L11.4578 6.59149C11.4645 6.59149 11.4711 6.59223 11.4763 6.59223C12.6603 6.59223 13.6193 5.63246 13.6193 4.44842Z" fill="#B3B1C5" />
        <path d="M7.63081 10.8489L4.3974 6.96848C4.34281 6.90356 4.26018 6.8652 4.17535 6.87037C4.08903 6.87258 4.0101 6.91537 3.96067 6.98545L1.33883 10.6467C1.3012 10.6312 0.986935 13.8602 4.20191 13.8602C7.41688 13.8602 7.71049 10.9588 7.6854 10.9662C7.67581 10.9263 7.65958 10.8835 7.63081 10.8489ZM4.50216 11.4767C3.83821 11.4767 2.61581 11.0923 1.88105 10.84L4.20191 7.59776L7.1122 11.0908C6.37743 11.2443 5.16389 11.4767 4.50216 11.4767Z" fill="#B3B1C5" />
        <path d="M17.9118 4.84975C16.9129 4.21679 15.0805 1.60822 12.8253 0.420499C12.4756 0.175577 11.9791 0 11.4258 0C11.397 0 11.369 0.00295095 11.3402 0.00368867C11.3107 0.00295095 11.2819 0 11.2524 0C10.6991 0 10.2027 0.175577 9.85299 0.420499C7.59779 1.60896 5.54988 4.50893 4.67348 5.01648C3.79707 5.52403 0.293651 5.10869 0.039876 3.22382C-0.306113 5.06221 1.609 7.22373 5.41119 6.29051C8.98691 5.41337 8.91535 1.74249 11.5283 1.74249C14.1413 1.74249 13.6913 5.41337 17.2648 6.29051C21.067 7.22373 22.7734 4.61442 22.6996 3.25776C21.5495 4.84828 18.9129 5.48345 17.9118 4.84975Z" fill="#B3B1C5" />
        <path d="M18.7197 6.98544C18.6703 6.91536 18.5906 6.87331 18.5051 6.87036C18.4188 6.8652 18.3361 6.90429 18.283 6.96921L15.0481 10.8496C15.0186 10.8843 15.0031 10.9263 14.995 10.9669C14.9699 10.9603 15.2621 13.861 18.4778 13.861C21.6905 13.861 21.3785 10.632 21.3408 10.6482L18.7197 6.98544ZM18.1783 11.4767C17.5173 11.4767 16.303 11.2458 15.5675 11.0923L18.4778 7.59922L20.7986 10.8408C20.0639 11.0916 18.8429 11.4767 18.1783 11.4767Z" fill="#B3B1C5" />
    </svg>
    const markerSvg = <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.36719 13.3711C0.660156 8.03906 0 7.48047 0 5.5C0 2.80859 2.1582 0.625 4.875 0.625C7.56641 0.625 9.75 2.80859 9.75 5.5C9.75 7.48047 9.06445 8.03906 5.35742 13.3711C5.12891 13.7266 4.5957 13.7266 4.36719 13.3711Z" fill="#B3B1C5" />
    </svg>

    const letterWrapperStyle = {
        width: window.innerWidth <= 800 ? 90 : 115,
        aspectRatio: 115 / 90,
        borderRadius: 5,
        background: `${stringToMatColor(name) || "#EDE9E9"}`
    }
    const letterPStyle = {
        fontSize: `${(window.innerWidth <= 800 ? 90 : 115) * 50 / 100}px`
    }
    return (
        <div className="searchedUserRowWrapper">
            <div className={"left"}>
                {
                    typeof photo === "string" && photo.length > 0
                        ? <div className={"squareProfileImage"}>
                            <img src={photo} />
                        </div>
                        : <div className='letter-wrapper' style={letterWrapperStyle}>
                            <p className='letter big'
                                style={letterPStyle}
                            >
                                {name?.charAt(0)}
                            </p>
                        </div>
                }

                <div className={"nameAndStatWrapper"}>
                    <div className="nameAndisOnlineWrapper">
                        <span className="isOnlineDot" />
                        <p>{name} | {age}</p>
                    </div>
                    {
                        window.innerWidth > 550 ?
                            <div className={"iconAndStatRowsWrapper"}>
                                <div className="iconAndStatRow">
                                    {heartSvg}
                                    <p>1.6K</p>
                                </div>
                                <div className={"iconAndStatRow"}>
                                    <div className={"zodiakSignWrapper"}>
                                        {zodiakSigns[zodiakIndexArr.indexOf(a)]}
                                    </div>
                                </div>
                                <div className="iconAndStatRow">
                                    {markerSvg}
                                    <p>{getCityLabelFromCityValue(city)}</p>
                                </div>
                            </div> :
                            null
                    }
                </div>
                {
                    window.innerWidth <= 550 ?
                        <div className={"iconAndStatRowsWrapper"}>
                            <div className="iconAndStatRow">
                                {heartSvg}
                                <p>1.6K</p>
                            </div>
                            <div className={"iconAndStatRow"}>
                                <div className={"zodiakSignWrapper"}>
                                    {zodiakSigns[zodiakIndexArr.indexOf(a)]}
                                </div>
                            </div>
                            <div className="iconAndStatRow">
                                {markerSvg}
                                <p>{city}</p>
                            </div>
                        </div> :
                        null
                }
            </div>
            <div className={"right"}>
                <button
                    onClick={() => setMessageData({ whom: userUid, status: true })}
                    className="button outlined"
                >
                    Mesaj Gönder
                </button>

                <button
                    onClick={() => handleFallow(user?.uid, userUid, user?.i_follow)}
                    className={checkIfIFollow(userUid, user?.i_follow) ? "button" : "button outlined"}
                >Arkadaş ol</button>
            </div>
        </div>
    )
}

export default SearchedUserRow;