import React, { useEffect, useRef, useState } from 'react'
import {
    Link,
    BrowserRouter as Router,
    useLocation,
    useNavigate
} from "react-router-dom";

import "./Register.css"
import Input from './components/Input';
import SelectorInput from './components/SelectorInput';
import { auth, fireStore, database, dataBase } from "./fireBaseConfig"
import { FacebookAuthProvider, GoogleAuthProvider, createUserWithEmailAndPassword, getAdditionalUserInfo, sendEmailVerification, signInWithEmailAndPassword, signInWithPopup } from 'firebase/auth';
import { set, ref, child, onValue } from "firebase/database";
import TimePicker from "./components/TimePicker"
import { doc, getDoc } from "firebase/firestore";
import Modal from './components/Modal';
import { userStore, modalStore, navigationStore, pendingStore } from './Store';
import { Helmet } from "react-helmet";
import { Checkbox } from '@mui/material';
import AdSense from './components/AdSense';
import loginCircle from "./assets/login-circle.svg";
import facebookSvg from "./assets/facebook.svg";
import googleSvg from "./assets/google.svg";
import twitterSvg from "./assets/twitter.svg";
import instagramSvg from "./assets/instagram.svg";
import zodyakiSvg from "./assets/zodyaki.svg";
import filePenSvg from "./assets/file-pen.svg";
import squareCheckSvg from "./assets/square-check.svg";
import usersSvg from "./assets/users.svg";
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import LoginMissingInfoModal from './components/LoginMissingInfoModal';
import { getUserByUid, isValidObject } from './utils/utils';



const Login = () => {

    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)
    const [passwordConfirm, setPasswordConfirm] = useState(null)
    const [gender, setGender] = useState(null)
    const [birthDate, setBirthDate] = useState(null)
    const [togglePassword, setTogglePassword] = useState(true)
    const [pageLoaded, setPageLoaded] = useState(false);



    const aaaa = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)
    const setUserAll = userStore((state) => state.setUserAll)

    const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)

    const signInButtonRef = useRef(null)

    const navigate = useNavigate()
    const location = useLocation()


    const isPending = pendingStore((state) => state.isPending)
    const setPending = pendingStore((state) => state.setPending)

    const setPage = navigationStore((state) => state.setPage)
    const user = userStore((state) => state.user)



    useEffect(() => {
        if (window.location.href.split("?")[1] && window.location.href.split("?")[1].includes("@")) {
            setEmail(window.location.href.split("?")[1])
        }
        window.addEventListener("keydown", function (event) {
            if (event.key === "Enter" && signInButtonRef.current != null) {
                signInButtonRef.current.click()
            }
        });
    }, [])

    const [emailVerifyMode, setEmailVerifyMode] = useState(false)

    
    const signIn = async () => {
        setPending(true)
        if (email != null && password != null) {
            try {

                let userCredential = await signInWithEmailAndPassword(auth, email.toLowerCase(), password)

                const user = userCredential.user;
                console.log("user", user.emailVerified)

                if(user.emailVerified) {
                    setModal({
                        type: "success",
                        status: true,
                        message: "Giriş başarılı..."
                    })
                    setUser({ uid: user.uid })
                    navigate("/home");
                } else {
                    await sendEmailVerification(auth.currentUser)
                    setModal({
                        type: "success",
                        status: true,
                        message: "E-postanıza onay linki gönderdik."
                    })
                }
              
            }
            catch (error) {
                const errorCode = error.code;
                const errorMessage = error.message;

                setModal({
                    type: "error",
                    status: true,
                    message: "Kullanıcı adı veya şifre hatalı!"
                })
            }
        } else {
            setModal({
                type: "error",
                status: true,
                message: "Kullanıcı adı veya şifre girmediniz!"
            })
        }

        setPending(false)
    }


    const [rememberMe, setRememberMe] = useState(true)

    useEffect(() => {
        sessionStorage.setItem("rememberMe", `${rememberMe}`)

    }, [rememberMe])

    const keySvg = <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M35 0H25C22.2383 0 20 2.23828 20 5V17L0.366455 36.6336C-0.121826 37.1219 -0.121826 37.9133 0.366455 38.4016L1.59849 39.6336C2.08677 40.1219 2.87817 40.1219 3.36646 39.6336L6.19849 36.8016L9.01958 39.6227C9.50786 40.1109 10.2993 40.1109 10.7875 39.6227L14.1594 36.2508C14.6477 35.7625 14.6477 34.9711 14.1594 34.4828L11.3383 31.6617L13.75 29.25L16.6336 32.1336C17.1219 32.6219 17.9133 32.6219 18.4016 32.1336L19.6336 30.9016C20.1219 30.4133 20.1219 29.6219 19.6336 29.1336L16.75 26.25L23 20H35C37.7618 20 40 17.7617 40 15V5C40 2.23828 37.7618 0 35 0ZM29.268 14.268C28.2915 15.2445 26.7086 15.2445 25.7321 14.268C24.7555 13.2914 24.7555 11.7086 25.7321 10.7328C26.7086 9.75625 28.2915 9.75625 29.268 10.7328C30.2438 11.7086 30.2438 13.2914 29.268 14.268ZM34.268 9.26797C33.2915 10.2445 31.7086 10.2445 30.7321 9.26797C29.7555 8.29141 29.7555 6.70859 30.7321 5.73281C31.7086 4.75625 33.2915 4.75625 34.268 5.73281C35.2438 6.70859 35.2438 8.29141 34.268 9.26797Z" fill="white" />
    </svg>

    const facebookProvider = new FacebookAuthProvider();
    facebookProvider.addScope('user_birthday');
    facebookProvider.setCustomParameters({
        'display': 'popup'
    });

    const facebookLogin = () => {
        signInWithPopup(auth, provider)
            .then((result) => {
                // The signed-in user info.
                const user = result.user;

                // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                const credential = FacebookAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                // console.log
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            })
            .catch((error) => {
                // Handle Errors here.
                const errorCode = error.code;
                const errorMessage = error.message;
                // The email of the user's account used.
                const email = error.customData.email;
                // The AuthCredential type that was used.
                const credential = FacebookAuthProvider.credentialFromError(error);

                // ...
            });
    }
    const provider = new GoogleAuthProvider();

    provider.addScope('https://www.googleapis.com/auth/user.gender.read');
    provider.addScope('https://www.googleapis.com/auth/user.birthday.read');
    provider.addScope('https://www.googleapis.com/auth/user.addresses.read');

    const [missingInfoModal, setMissingInfoModal] = useState(false)
    const [userWithMissingInfo, setUserWithMissingInfo] = useState({})

    const handleSignInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, provider)
            const user = result.user;
            const additionalUserInfo = getAdditionalUserInfo(result)
            const credential = GoogleAuthProvider.credentialFromResult(result);
            
            const userData = await getUserByUid(user.uid)
            if (additionalUserInfo.isNewUser === true || !isValidObject(userData)) {
                console.log(credential, user, additionalUserInfo, additionalUserInfo.profile.id, user.accessToken)
                setUserWithMissingInfo(user)
                setMissingInfoModal(true)
            }
            console.log("google login user:", user)
            setUser({ uid: userData.uid })
            navigate("/home");
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.error("google sign in err: ", errorCode + errorMessage)
        }
    }


    return (
        <div id={"loginStatsAndContainerWrapper"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Zodyaki | Giriş Yap</title>
            </Helmet>
            {
                missingInfoModal
                    ? <LoginMissingInfoModal userWithMissingInfo={userWithMissingInfo} navigate={navigate} />
                    : null
            }
            <div className={"loginStatsWrapper"}>
                <img src={zodyakiSvg} />
                <h1>Zodyaki’ye Hoşgeldiniz</h1>
                <p>
                    Zodyaki bir sosyal platformdur.Burç eşleşmeleriyle birbirinize
                    bir platformdur.Hemen üye ol.
                </p>
                <div className={"statsWrapper"}>
                    <div className={"statWrapper"}>
                        <div>
                            <img src={usersSvg} />
                        </div>
                        <p>Kayıtlı Kişiler</p>
                        <p>36.278</p>
                    </div>
                    <div className={"statWrapper"}>
                        <div>
                            <img src={squareCheckSvg} />
                        </div>
                        <p>Kayıtlı Kişiler</p>
                        <p>36.278</p>
                    </div>
                    <div className={"statWrapper"}>
                        <div>
                            <img src={filePenSvg} />
                        </div>
                        <p>Kayıtlı Kişiler</p>
                        <p>36.278</p>
                    </div>
                </div>
            </div>
            {
                !pageLoaded
                    ? <div id={"login-container"}>
                        
                        {/* <AdSense /> */}
                        <img id='login-circle' src={loginCircle}></img>
                        <h1 className='title'>
                            {keySvg}
                            Giriş
                        </h1>
                        <div className="form-wrapper">
                            <p className="login-p">
                                Şimdi Oturum Açın ve Dünyadaki
                                Harika İnsanlarla Tanışın
                            </p>
                            <span className='line1'></span>
                            <span className='line2'></span>
                            <span className='line3'></span>

                            <Input defaultValue={email != null ? email : null} set={setEmail} label={"Mail Adresiniz"} type={"email"} />
                            <Input set={setPassword} value={password} label={"Şifre"} toggle={setTogglePassword} type={togglePassword ? "password" : "text"} />
                            <div id={"remember-me-wrapper"}>
                                <Checkbox
                                    onChange={() => setRememberMe(!rememberMe)}
                                    checked={rememberMe}
                                    size="small"
                                    sx={{
                                        color: "#fff",
                                        padding: 0,
                                        "& > svg": {
                                            fill: "#fff",
                                        },
                                        '&.Mui-checked > svg': {
                                            fill: "#fff",
                                        },
                                    }}

                                />
                                <a href='https://youtu.be/i092TZhC_lU' target='_blank'>
                                    <p className="remember-me-text">Unutma Beni</p>
                                </a>
                            </div>
                            <div className={"buttonAndForgotPasswordWrapper"}>
                                <button ref={signInButtonRef} type="submit" className="button" onClick={() => signIn()}>Giriş Yap</button>
                                <Link className="ssoTextButton" to='/forgot-password' >Şifremi Unuttum</Link>
                            </div>
                            <div className={"singUpMethodsWrapper"}>
                                <img src={facebookSvg} />
                                <button className='socialLoginButton' onClick={() => handleSignInWithGoogle()}>
                                    <img src={googleSvg} />
                                </button>
                                <img src={instagramSvg} />
                                <img src={twitterSvg} />
                            </div>
                            <div className='register-forgot-password-wraper'>
                                <Link to='/register'>Hesabınız yok mu? Şimdi üye ol</Link>
                            </div>
                        </div>
                    </div>
                    : <div style={{ background: "red", width: "100vw", height: "100vh" }}>sadasds</div>

            }
        </div>
    )
}

export default Login
