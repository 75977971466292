import "./SimpleInput.css"

const SimpleInput = (props) => {
    const { label, placeholder, set , value, onClick, type } = props
    return (
        <div onClick={onClick} className={"simpleInputWrapper"}>
            <label>{label}</label>
            <div className="inputWrapper">
                <input type={type || "text"} defaultValue={value} placeholder={placeholder} className="simpleInput" onChange={(e) => set(e.target.value)} />
            </div>
        </div>
    )
}

export default SimpleInput;