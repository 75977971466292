import React, { useEffect, useRef, useState } from 'react'
import { Link, json } from 'react-router-dom'
import { child, get, onValue, push, ref, set } from 'firebase/database'
import { dataBase } from './fireBaseConfig'
import SideMenu from './components/SideMenu'
import "./Post.css"
import likePng from "./assets/like.svg"
import dissLikePng from "./assets/dissLike.svg"
import likeNotFilledPng from "./assets/likeNotFilled.svg"
import dissLikeNotFilledPng from "./assets/dissLikeNotFilled.svg"


import { usersStore, userStore, postsStore, dataBaseUserStore, profileStore, postsDirectionStore, hadnleProfilePageStore, navigationStore, mostLikedPostStore, currentMostLikedPostStore } from "./Store"

import defaultProfilePhoto from './assets/defaultProfilePhoto.png'
import { getUsersNameByUid, stringToMatColor } from './utils/utils'
import ProfileCircle from './components/ProfileCircle'

const Post = (props) => {

    const { postUid, postMessage, postTime, userName, userUid, direction, isMostLiked } = props
    const profile = profileStore((state) => state.profile)

    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const postsDirection = postsDirectionStore((state) => state.postsDirection)


    const profileData = hadnleProfilePageStore((state) => state.profileData)
    const setProfileData = hadnleProfilePageStore((state) => state.setProfileData)




    const users = usersStore((state) => state.users)
    const setUsers = usersStore((state) => state.setUsers)

    const [post, setPost] = useState(null)
    const [whoLikedArr, setWhoLikedArr] = useState(null)


    const [like, setLike] = useState(undefined)
    const [dissLike, setDissLike] = useState(null)
    const [isLikeOpen, setIslikeOpen] = useState(true)
    const [isDissLikeOpen, setIsDisslikeOpen] = useState(true)

    const postRef = child(ref(dataBase), "posts/" + `${postUid}`)
    const postsLikeRef = child(ref(dataBase), `posts/` + `${postUid}`)
    const postsDissLikeRef = child(ref(dataBase), `posts/` + `${postUid}`)
    const usersRef = child(ref(dataBase), `users/`)

    const currentMostLikedPost = currentMostLikedPostStore(state => state.currentMostLikedPost)




    const updatePost = () => {
        get(postRef,).then((snapshot) => {
            if (snapshot.exists()) {
                setPost(snapshot.val())
                // 
            } else {
            }
        }).catch((error) => {

        });
    }

    useEffect(() => {
        onValue(postRef, (snapshot) => {
            if (snapshot.val()) {
                setPost(snapshot.val())
            }
        })
        updatePost()
        listWhoLike()
    }, [])





    const listWhoLike = async () => {
        let whoLikeDummyArr = []
        

        if (post?.like != null) {
            

                    let LikeArr = Object.keys(post?.like)
                    LikeArr?.forEach((item) => {
                        getUsersNameByUid(item).then(name => {
                            whoLikeDummyArr.push({ "name": name, "uid": item })
                            console.log({ "name": name, "uid": item })
                        })
                    })

                    setWhoLikedArr(whoLikeDummyArr)



        } else {
            setLike(undefined)
            setWhoLikedArr(null)
        }



    }

    useEffect(() => {
        
    }, [whoLikedArr])


    const updateLikeStuff = () => {
        if (post?.like != undefined) {
            setLike(post?.like)

            let isILikedThatPost = Object.keys(post?.like).includes(user.uid)
            if (isILikedThatPost) {
                setIslikeOpen(false)
            } else {
                setIslikeOpen(true)
            }

        } else {
            setLike(undefined)
            setIslikeOpen(true)
        }

        listWhoLike()

        if (post?.dissLike != undefined) {
            setDissLike(Object.values(post?.dissLike))

            let isIDissLikedThatPost = Object.keys(post?.dissLike).includes(user.uid)
            if (isIDissLikedThatPost) {
                setIsDisslikeOpen(false)
            } else {
                setIsDisslikeOpen(true)
            }
        } else {
            setDissLike(undefined)
            setIsDisslikeOpen(true)

        }
    }



    useEffect(() => {
        updateLikeStuff()
    }, [post])


    useEffect(() => {

    }, [isDissLikeOpen])


    // ref(dataBase, "posts/" + `${profile.value}/` + "Başlangıçta_Nasıl_birisidir/Nasıl_tavlanır_etkilenir/" + 'posts/')
    const handleLike = (postUid) => {

        if (isLikeOpen) {
            set(ref(dataBase, "posts/" + `${postUid}/` + "like/" + `${user.uid}`), {
                date: new Date().getTime(),
                whoLiked: user.uid,
            }).then(() => setIslikeOpen(false))

        } else if (!isLikeOpen) {
            set(ref(dataBase, "posts/" + `${postUid}/` + "like/" + `${user.uid}`), {

            }).then(() => setIslikeOpen(true))

        }

        if (!isDissLikeOpen) {
            set(ref(dataBase, "posts/" + `${postUid}/` + "dissLike/" + `${user.uid}`), {

            }).then(() => setIsDisslikeOpen(!isDissLikeOpen))

        }

        updatePost()
    }
    const handleDissLike = (postUid) => {
        if (isDissLikeOpen) {
            set(ref(dataBase, "posts/" + `${postUid}/` + "dissLike/" + `${user.uid}`), {
                date: new Date().getTime(),
                whoDissLiked: user.uid,
            }).then(() => {
                setIsDisslikeOpen(!isDissLikeOpen)
            })

        } else if (!isDissLikeOpen) {
            set(ref(dataBase, "posts/" + `${postUid}/` + "dissLike/" + `${user.uid}`), {

            }).then(() => setIsDisslikeOpen(!isDissLikeOpen))
        }

        if (!isLikeOpen) {
            set(ref(dataBase, "posts/" + `${postUid}/` + "like/" + `${user.uid}`), {

            }).then(() => setIslikeOpen(!isLikeOpen))
        }

    }


    function findUserProfilePhoto() {
        return Object.values(users).find((item) => item.uid == userUid)?.profilePhoto
    }
    const standPost = mostLikedPostStore((state) => state.mostLikedPost)

    const flagSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M229.825-120Q217-120 208.5-128.625T200-150v-620q0-12.75 8.625-21.375T230-800h289q10.5 0 18.75 6.5T548-776l14 62h208q12.75 0 21.375 8.625T800-684v310q0 12.75-8.625 21.375T770-344H568q-10.5 0-18.75-6.5T539-367l-14-62H260v279q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625Z" /></svg>
    // setProfileData({ whom: userUid, status: true }) 

    const [maxCharLimit, setMaxCharLimit] = useState(150)
    const [fullHeight, setFullHeight] = useState(false)
    const [lineCount, setLineCount] = useState(3)
    const paragraphRef = useRef()

    useEffect(() => {
        // console.warn("testesftesf", Math.round(paragraphRef.current.clientHeight / 18))
        setLineCount(Math.round(paragraphRef.current.clientHeight / 18))
    }, [])

    return (
        <>
            {/* mostLiked class ===> sarı rengi veriri */}
            <div data-uid={postUid} className={currentMostLikedPost?.postUid && currentMostLikedPost?.postUid === postUid ? 'postWrapper' : 'postWrapper'}>
                <div className={"postHeaderAndBody"}>
                    <div className={"postHeader"}>

                        <ProfileCircle size={40} onlineEnable={false} userUid={userUid} />
                        <div className={"post-header-userNameAndPostTime"}>

                            {
                                false // direction vardı burda 
                                    ? null
                                    : <Link className='post-header-user-name' to="/profil" state={{ whom: userUid }}>
                                        {userName}
                                    </Link>
                            }

                        </div>

                    </div>

                    <div className='postBody'>
                        <p ref={paragraphRef} className={fullHeight ? 'postParagraph fullHeight' : 'postParagraph'}>
                            {
                                postMessage?.length <= maxCharLimit || lineCount <= 3 || fullHeight
                                    ? postMessage?.trim() + " "
                                    : postMessage?.slice(0, maxCharLimit)?.trim() + "... "

                            }
                            {
                                postMessage?.length > maxCharLimit
                                    ? <span className='showMoreButton' onClick={() => setFullHeight(!fullHeight)}>{!fullHeight ? "daha fazla" : "daha az"}</span>
                                    : null
                            }
                        </p>
                    </div>
                </div>

                <div className="postFooter">
                    {/* <p style={{ color: "#fff" }}>{userName}</p> */}
                    <p className='post-header-post-time' >{postTime}</p>

                    <div className={"likeAndDissLikeWrapper"}>
                        <div className={"likeButtonAndCountWrapper"}>
                            <div className='tooltip'>
                                {whoLikedArr?.map((who) => {
                                    return <p key={who.uid} >{who.name}</p>
                                })}
                            </div>
                            <button onClick={() => handleLike(postUid)} className='likeButton'>
                                {isLikeOpen ? <img src={likeNotFilledPng} /> : <img src={likePng} />}
                                <p className='count'>{post?.like ? Object.entries(post?.like)?.length : 0}</p>
                            </button>
                        </div>
                        <div className={"likeButtonAndCountWrapper"}>
                            <button onClick={() => handleDissLike(postUid)} className='dissLikeButton'>
                                {isDissLikeOpen ? <img src={dissLikeNotFilledPng} /> : <img src={dissLikePng} />}
                                <p className='count'>{dissLike?.length == undefined ? 0 : dissLike?.length}</p>
                            </button>
                        </div>
                    </div>

                </div>

            </div >
        </>
    )
}


export default Post;