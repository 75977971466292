import { useState, useEffect } from "react";
import "./Pagination.css";

const Pagination = (props) => {

    const { setPage, page, count, way } = props;

    const [dropdownOpen, setDropdownOpen] = useState(false)
    const [countArr, setCountArr] = useState([])

    const leftArrow = <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.847656 4.28516L4.56641 0.566406C4.83984 0.320312 5.25 0.320312 5.49609 0.566406L6.125 1.19531C6.37109 1.44141 6.37109 1.87891 6.125 2.125L3.47266 4.75L6.125 7.40234C6.37109 7.64844 6.37109 8.05859 6.125 8.33203L5.49609 8.93359C5.25 9.20703 4.83984 9.20703 4.56641 8.93359L0.847656 5.21484C0.601562 4.96875 0.601562 4.55859 0.847656 4.28516Z" fill="#515365" />
    </svg>
    const rightArrow = <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.125 5.21484L2.40625 8.93359C2.13281 9.20703 1.72266 9.20703 1.47656 8.93359L0.847656 8.33203C0.601562 8.05859 0.601562 7.64844 0.847656 7.40234L3.5 4.77734L0.847656 2.125C0.601562 1.87891 0.601562 1.46875 0.847656 1.19531L1.47656 0.566406C1.72266 0.320312 2.13281 0.320312 2.40625 0.566406L6.125 4.28516C6.37109 4.55859 6.37109 4.96875 6.125 5.21484Z" fill="#515365" />
    </svg>
    const downArrow = <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.2207 0.5H8.75C9.27734 0.5 9.54102 1.14453 9.16016 1.52539L5.41016 5.27539C5.17578 5.50977 4.79492 5.50977 4.56055 5.27539L0.810547 1.52539C0.429688 1.14453 0.693359 0.5 1.2207 0.5Z" fill="#515365" />
    </svg>
    const seperator = <svg width="6" height="11" viewBox="0 0 6 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.44043 0.046875L1.56445 10.8545H0.272461L4.15527 0.046875H5.44043Z" fill="#515365" />
    </svg>

    useEffect(() => {
        const a = []
        for (let i = 1; i < count + 1; i++) {
            a.push(i)
        }
        setCountArr(a)

        if(page > count) {
            setPage(count)
        }
    }, [count])

    const handleChangePage = (direction) => {
        if (direction === "prev") {
            setPage(page - 1)
        } else if (direction === "next") {
            setPage(page + 1)
        } else if (typeof direction === "number") {
            setPage(direction)
        }
    }
    return (
        <div className={way === "up" ? "paginationWrapper up" : "paginationWrapper"}>
            <div className={"left"}>
                <button
                    onClick={() => handleChangePage("prev")}
                    className={"paginationButton left"}
                    disabled={page === 1}
                >
                    {leftArrow}
                </button>
            </div>
            <div className={"center"}>
                <button
                    onClick={() => setDropdownOpen(!dropdownOpen)}
                    className={"paginationButton selected"}
                >
                    <span>
                        {page}
                    </span>
                    {downArrow}
                    <div className={dropdownOpen ? "dropdown open" : "dropdown"}>
                        {
                            countArr.map((item, index) => {
                                return (
                                    <>
                                        <button
                                            className="option"
                                            key={index}
                                            onClick={() => handleChangePage(item)}
                                        >
                                            {item}
                                        </button>
                                    </>
                                )
                            })
                        }
                    </div>
                </button>
                {seperator}
                <button
                    onClick={() => handleChangePage(count)}
                    className={"paginationButton allPages"}
                >
                    {count}
                </button>
            </div>
            <div
                className={"right"}>
                <button
                    onClick={() => handleChangePage("next")}
                    disabled={page === count}
                    className={"paginationButton right"}
                >
                    {rightArrow}
                </button>
            </div>
        </div>
    )
}

export default Pagination;