import { useEffect, useRef, useState } from "react";
import "./Messages.css"
import { hadnleProfilePageStore, handleMessagePageStore, interactStore, totalUnreadedMessagesCountStore, userStore, usersStore } from "../Store";
import { child, get, onValue, push, ref, set } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import { dateFormatter, playNotificationSound } from "../utils/utils";

const SmallMessages = () => {

    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const users = usersStore((state) => state.users)
    const setUsers = usersStore((state) => state.setUsers)

    const profileData = hadnleProfilePageStore((state) => state.profileData)
    const setProfileData = hadnleProfilePageStore((state) => state.setProfileData)

    const messageData = handleMessagePageStore((state) => state.messageData)
    const setMessageData = handleMessagePageStore((state) => state.setMessageData)

    const messageSvg = <svg width="21" height="19" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11 0.75C16.5078 0.75 21 4.42188 21 8.875C21 13.3672 16.5078 17 11 17C9.47656 17 8.07031 16.7266 6.78125 16.2578C5.84375 17.0391 3.89062 18.25 1.3125 18.25C1.15625 18.25 1.07812 18.2109 1 18.0938C0.960938 17.9766 1 17.8203 1.07812 17.7422C1.07812 17.7031 2.71875 15.9844 3.22656 13.9922C1.82031 12.5859 1 10.8281 1 8.875C1 4.42188 5.45312 0.75 11 0.75Z" fill="white" />
    </svg>


    const totalUnreadedMessagesCount = totalUnreadedMessagesCountStore(state => state.totalUnreadedMessagesCount)
    const interact = interactStore(state => state.interact)
    
    useEffect(() => {
        if (interact === true && totalUnreadedMessagesCount > 0)  {
            playNotificationSound()
        }
    }, [totalUnreadedMessagesCount]);

    return (
        <div id="SmallMessagesWrapper">
            {
                totalUnreadedMessagesCount && totalUnreadedMessagesCount !== 0
                    ?
                    <div className="unReadedMessagesCount">
                        <span>{totalUnreadedMessagesCount}</span>
                    </div>
                    :
                    null
            }

            <button id="smalMessagesButton" onClick={() => setMessageData({ whom: "", status: true })}>{messageSvg}</button>
        </div>
    )
}

export default SmallMessages;