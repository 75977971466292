import { getDownloadURL, ref as sRef, uploadBytes } from 'firebase/storage';
import { auth, dataBase, storage } from "../fireBaseConfig";
import { child, equalTo, get, orderByChild, query, ref, remove, set } from 'firebase/database';
import { signOut } from 'firebase/auth';
import Login from "../Login"
import Home from '../Home';
import Register from "../Register"
// import ForgotPassword from "../ForgotPassword"
import ProfilePage from '../ProfilePage';
import ProfileSettings from '../ProfileSettings';
import WriterSearch from '../WriterSearch';
import MembershipPlans from '../MembershipPlans';
import FAQ from '../FAQ';
import SecurityPage from '../SecurityPage';

export const allRoutes = [
    { path: "/login", isPrivate: false, isAdminRoleNeccesary: false },
    { path: "/register", isPrivate: false, isAdminRoleNeccesary: false },
    { path: "/forgot-password", isPrivate: false, isAdminRoleNeccesary: false },
    { path: "/home", isPrivate: true, isAdminRoleNeccesary: false },
    { path: "/writer-search", isPrivate: true, isAdminRoleNeccesary: false },
    { path: "/profil", isPrivate: true, isAdminRoleNeccesary: false },
    { path: "/", isPrivate: true, isAdminRoleNeccesary: false },
    { path: "/profile-settings", isPrivate: true, isAdminRoleNeccesary: false },
    { path: "/profile-settings", isPrivate: true, isAdminRoleNeccesary: false },
    { path: "/s.s.s", isPrivate: true, isAdminRoleNeccesary: false },
    { path: "/security", isPrivate: true, isAdminRoleNeccesary: false },
]

export function isPrivate(path) {
    const a = allRoutes.find((item) => item.path === path) ? allRoutes.find((item) => item.path === path).isPrivate : false
    return a
}

export async function handleSignOut() {
    try {
        const ress = await signOut(auth)
        window.location.pathname = "/login"
    } catch (err) {
        console.error("signOut error: ", err)
    }
}

export function isValidObject(obj) {
    if (
        obj !== null &&
        obj !== undefined &&
        typeof obj === "object" &&
        JSON.stringify(obj) !== "[]" &&
        JSON.stringify(obj) !== "{}"
    ) {
        return true;
    } else {
        return false;
    }
}

export function isValidArray(arr) {
    if (
        arr !== null &&
        arr !== undefined &&
        typeof arr === "object" &&
        JSON.stringify(arr) !== "{}" &&
        JSON.stringify(arr)[0] === "[" &&
        JSON.stringify(arr)[JSON.stringify(arr).length - 1] === "]"
    ) {
        return true;
    } else {
        return false;
    }
}

export function isValidAndNotEmptyArray(arr) {
    if (
        arr !== null &&
        arr !== undefined &&
        typeof arr === "object" &&
        JSON.stringify(arr) !== "{}" &&
        JSON.stringify(arr)[0] === "[" &&
        JSON.stringify(arr)[JSON.stringify(arr).length - 1] === "]" &&
        arr.length > 0
    ) {
        return true;
    } else {
        return false;
    }
}


export function isValidAndNotEmptyString(str) {
    if (
        str !== null &&
        str !== undefined &&
        typeof str === "string" &&
        str !== ""
    ) {
        return true;
    } else {
        return false;
    }
}

export function validatePhoneNumber(phoneNumber) {
    // Telefon numarasında sadece rakamların olup olmadığını kontrol eder
    if (!/^\d+$/.test(phoneNumber)) {
        return false;
    }

    // Telefon numarasının uzunluğunu kontrol eder (genellikle 7 ila 15 arasında olabilir)
    if (phoneNumber.length < 7 || phoneNumber.length > 15) {
        return false;
    }

    // Belirli ülke kodlarına sahip telefon numaralarını kontrol edebilirsiniz
    // Örneğin, Amerika Birleşik Devletleri'ndeki numaralar 1 ile başlar
    // Diğer ülkelere göre kontrolü buraya ekleyebilirsiniz

    // Örnek olarak, Amerika Birleşik Devletleri numaralarını kontrol edelim
    if (phoneNumber.startsWith("1")) {
        // Amerika Birleşik Devletleri numarasının doğrulama koşullarını kontrol edin
        // Örneğin, 10 haneli olmalıdır ve alan kodu 2 ile başlamalıdır
        if (phoneNumber.length !== 10 || !/^[2-9]\d{2}/.test(phoneNumber.slice(1))) {
            return false;
        }
    }

    // Tüm koşulları geçtiyse, telefon numarası geçerlidir
    return true;
}

export function dateFormatter(timeStamp) {
    return new Date(timeStamp).getDate() + "/" + (new Date(timeStamp).getMonth() + 1) + "/" + new Date(timeStamp).getFullYear() + " · " + new Date(timeStamp).getHours() + ":" + new Date(timeStamp).getMinutes()
}

export async function getProfilePhotoByUid(uid) {
    return await getDownloadURL(sRef(storage, `photos/${uid}/profilePhoto`))
        .then((url) => url)
        // .catch(() => getDownloadURL(sRef(storage, `photos/${"default"}/profilePhoto`)))
        .catch(() => null)
}
export async function getProfileBannerByUid(uid) {
    return await getDownloadURL(sRef(storage, `photos/${uid}/bannerPhoto`))
        .then((url) => url)
        .catch(() => getDownloadURL(sRef(storage, `photos/${"default"}/bannerPhoto`)))

}

export async function getUsersNameByUid(uid) {
    const userNameRef = child(ref(dataBase), 'users/' + `${uid}/name`)
    return get(userNameRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    }).catch((err) => {
        console.error(err)
    });
}

export async function getUsersEmailByUid(uid) {
    const userNameRef = child(ref(dataBase), 'users/' + `${uid}/email`)
    return get(userNameRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    }).catch((err) => {
        console.error(err)
    });
}

export async function getUsersBioByUid(uid) {
    const userNameRef = child(ref(dataBase), 'users/' + `${uid}/bio`)
    return get(userNameRef).then((snapshot) => {
        if (snapshot.exists()) {
            console.info(snapshot.val())
            return snapshot.val()
        } else return {
            aboutMe: "Hakkımda yazısı bulunmuyor.",
            education: "Eğitim bilgisi bulunmuyor.",
            hoby: "Hobi bilgisi bulunmuyor."
        }
    }).catch((err) => {
        console.error(err)
    });
}


export async function getIsOnlineByUid(uid) {
    const userOnlineRef = child(ref(dataBase), 'is-online/' + `${uid}/`)
    return get(userOnlineRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    }).catch((err) => {
        console.error(err)
    });
}


export async function getProfileUserCityByUid(uid) {
    // console.error("sehir")

    const userCityRef = child(ref(dataBase), 'users/' + `${uid}/city`)
    return get(userCityRef).then((snapshot) => {
        if (snapshot.exists()) {

            return snapshot.val()
        }
    }).catch((err) => {
        console.error("sehir", err)
    });
}


export async function getProfileUserFollowersByUid(uid) {
    const userIFollowRef = child(ref(dataBase), 'users/' + `${uid}/followers`)
    return get(userIFollowRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    }).catch((err) => {
        console.error(err)
    });
}


export function stringToMatColor(input) {
    if (!input || input === "" || input === undefined || input === null) return

    const primeColors = [
        "#E57373", "#81C784", "#64B5F6", "#FFD54F", "#9575CD", "#4DB6AC", "#FF8A65", "#A1887F"
    ];

    const asciiSum = input.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    const colorIndex = asciiSum % primeColors.length;

    return primeColors[colorIndex];
}

export function stringToMatColorAdvenced(input) {
    if (!input || input === "" || input === undefined || input === null) return

    const primeColors = [
        "#ffafbd ", "#2193b0 ", "#cc2b5e ", "#ee9ca7 ", "#ffdde1", "#42275a", "#734b6d",
    ];

    const asciiSum = input.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    const colorIndex = asciiSum % primeColors.length;

    return primeColors[colorIndex];
}


export async function getUserByEmail(email) {
    console.info("email", email)
    try {
        const userRef = query(ref(dataBase, `users/`),
            orderByChild("email"),
            equalTo(email)
        )
        const snapshot = await get(userRef)
        if (snapshot.exists()) {
            return snapshot.val()
        }
    } catch (err) {
        console.log("getUserByEmail err: ", err)
        return {}
    }
}

export async function getUserByUid(uid) {
    const userIFollowRef = child(ref(dataBase), 'users/' + `${uid}/`)
    return get(userIFollowRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    }).catch((err) => {
        console.error(err)
    });
}
export async function getProfileUserIFollowByUid(uid) {
    const userIFollowRef = child(ref(dataBase), 'users/' + `${uid}/i_follow`)
    return get(userIFollowRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    }).catch((err) => {
        console.error(err)
    });
}

export function findContrastingColor(hexColor) {
    // Convert the hexadecimal color code to RGB components
    const hex = hexColor.replace(/^#/, ''); // Remove '#' if present
    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);

    // Calculate the luminance of the color
    const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

    // Determine the threshold for choosing white or black as the contrasting color
    const threshold = 0.5;

    // Choose white or black based on the luminance threshold
    const contrastingColor = luminance > threshold ? '#000000' : '#FFFFFF';

    return contrastingColor;
}

export function generateColorTones(hexColor) {
    // Convert the hexadecimal color code to RGB components
    const hex = hexColor.replace(/^#/, ''); // Remove '#' if present
    const red = parseInt(hex.substring(0, 2), 16);
    const green = parseInt(hex.substring(2, 4), 16);
    const blue = parseInt(hex.substring(4, 6), 16);

    const numTones = 15; // Number of tones

    const step = Math.floor(255 / (numTones - 1));

    const colorTones = [];

    // Generate tones by adjusting the color values to become lighter
    for (let i = 0; i < numTones; i++) {
        const toneRed = Math.min(255, red + step * i);
        const toneGreen = Math.min(255, green + step * i);
        const toneBlue = Math.min(255, blue + step * i);

        // Convert the RGB values to a hexadecimal color code
        const toneHex = `#${toneRed.toString(16).padStart(2, '0')}${toneGreen.toString(16).padStart(2, '0')}${toneBlue.toString(16).padStart(2, '0')}`;

        colorTones.push(toneHex);
    }

    return colorTones;
}

export function generateAndPlaceColorTones(input, index, length) {
    if (!input || input === "" || input === undefined || input === null) return
    const primeColors = generateColorTones(stringToMatColorAdvenced(input))
    primeColors.length = length

    // const asciiSum = subTitle.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    const colorIndex = index;
    return primeColors[colorIndex];
}


export function generateAndPlaceColorTonesForText(input, index, length) {
    if (!input || input === "" || input === undefined || input === null) return
    const primeColors = generateColorTones(stringToMatColorAdvenced(input))
    primeColors.length = length

    // const asciiSum = subTitle.split('').reduce((sum, char) => sum + char.charCodeAt(0), 0);
    const colorIndex = index;
    const textColor = findContrastingColor(primeColors[colorIndex])
    return textColor;
}




export function calcAge(timestamp) {
    if (!timestamp) return

    const birth = timestamp?.split('/')
    const birthDay = new Date(birth[2], birth[1] - 1, birth[0])

    var birthDate = new Date(birthDay); // Saniye cinsinden zaman damgasını milisaniye cinsine dönüştürme
    var now = new Date(); // Şu anki zamanı al

    var age = now.getFullYear() - birthDate.getFullYear(); // Yıl farkını hesapla

    // Şu anki tarih, doğum gününden önceyse yaşını bir azalt
    if (
        now.getMonth() < birthDate.getMonth() ||
        (now.getMonth() === birthDate.getMonth() && now.getDate() < birthDate.getDate())
    ) {
        age--;
    }

    return age;
}
export function getBirthDateByUid(uid) {
    const userBirthDateRef = child(ref(dataBase), 'users/' + `${uid}/birthDate`)
    return get(userBirthDateRef).then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    }).catch((err) => {
        console.error(err)
    });
}

export function findZodiacFromBirthDate(tarih) {
    // Tarihi parçalara ayır
    const tarihParcalari = tarih.split('/');

    // Parçalardan gün, ay ve yılı al
    const gun = parseInt(tarihParcalari[0]);
    const ay = parseInt(tarihParcalari[1]);
    const yil = parseInt(tarihParcalari[2]);

    // Burçların tarih aralıkları
    const burcTarihleri = {
        "Koç": { baslangic: [3, 21], bitis: [4, 19] },
        "Boğa": { baslangic: [4, 20], bitis: [5, 20] },
        "İkizler": { baslangic: [5, 21], bitis: [6, 20] },
        "Yengeç": { baslangic: [6, 21], bitis: [7, 22] },
        "Aslan": { baslangic: [7, 23], bitis: [8, 22] },
        "Başak": { baslangic: [8, 23], bitis: [9, 22] },
        "Terazi": { baslangic: [9, 23], bitis: [10, 22] },
        "Akrep": { baslangic: [10, 23], bitis: [11, 21] },
        "Yay": { baslangic: [11, 22], bitis: [12, 21] },
        "Oğlak": { baslangic: [12, 22], bitis: [1, 19] },
        "Kova": { baslangic: [1, 20], bitis: [2, 18] },
        "Balık": { baslangic: [2, 19], bitis: [3, 20] }
    };

    // Verilen tarihle burçları karşılaştır
    for (const burc in burcTarihleri) {
        const baslangicTarihi = burcTarihleri[burc].baslangic;
        const bitisTarihi = burcTarihleri[burc].bitis;

        if (
            (ay === baslangicTarihi[0] && gun >= baslangicTarihi[1]) ||
            (ay === bitisTarihi[0] && gun <= bitisTarihi[1])
        ) {
            return burc;
        }
    }

    return "Geçersiz tarih veya burç bulunamadı.";
}

export function handleFallow(myUid, otherUid, myFollowsObj) {
    if (myFollowsObj && typeof myFollowsObj === "object" && Object.keys(myFollowsObj)?.includes(otherUid)) {
        set(ref(dataBase, 'users/' + `${myUid}/i_follow/` + `${otherUid}`), null);
        set(ref(dataBase, 'users/' + `${otherUid}/followers/` + `${myUid}`), null);
    } else {
        set(ref(dataBase, 'users/' + `${myUid}/i_follow/` + `${otherUid}`), true);
        set(ref(dataBase, 'users/' + `${otherUid}/followers/` + `${myUid}`), true);
    }
}

export function checkIfIFollow(otherUid, myFollowsObj) {
    if (myFollowsObj && typeof myFollowsObj === "object" && Object.keys(myFollowsObj)?.includes(otherUid)) {
        return true;
    } else {
        return false;
    }
}


export function getZodiacSign(birthDate, language) {
    // Parse the birth date
    if (!birthDate) {
        return null
    }
    var dateParts = birthDate.split('/');
    var day = parseInt(dateParts[0]);
    var month = parseInt(dateParts[1]);
    var year = parseInt(dateParts[2]);

    // Define the zodiac sign date ranges and names in Turkish and English
    var zodiacSigns = [
        {
            tr: "Kova",
            en: "Aquarius",
            startMonth: 1,
            startDay: 20,
            endMonth: 2,
            endDay: 18
        },
        {
            tr: "Balık",
            en: "Pisces",
            startMonth: 2,
            startDay: 19,
            endMonth: 3,
            endDay: 20
        },
        {
            tr: "Koç",
            en: "Aries",
            startMonth: 3,
            startDay: 21,
            endMonth: 4,
            endDay: 19
        },
        {
            tr: "Boğa",
            en: "Taurus",
            startMonth: 4,
            startDay: 20,
            endMonth: 5,
            endDay: 20
        },
        {
            tr: "İkizler",
            en: "Gemini",
            startMonth: 5,
            startDay: 21,
            endMonth: 6,
            endDay: 20
        },
        {
            tr: "Yengeç",
            en: "Cancer",
            startMonth: 6,
            startDay: 21,
            endMonth: 7,
            endDay: 22
        },
        {
            tr: "Aslan",
            en: "Leo",
            startMonth: 7,
            startDay: 23,
            endMonth: 8,
            endDay: 22
        },
        {
            tr: "Başak",
            en: "Virgo",
            startMonth: 8,
            startDay: 23,
            endMonth: 9,
            endDay: 22
        },
        {
            tr: "Terazi",
            en: "Libra",
            startMonth: 9,
            startDay: 23,
            endMonth: 10,
            endDay: 22
        },
        {
            tr: "Akrep",
            en: "Scorpio",
            startMonth: 10,
            startDay: 23,
            endMonth: 11,
            endDay: 21
        },
        {
            tr: "Yay",
            en: "Sagittarius",
            startMonth: 11,
            startDay: 22,
            endMonth: 12,
            endDay: 21
        },
        {
            tr: "Oğlak",
            en: "Capricorn",
            startMonth: 12,
            startDay: 22,
            endMonth: 1,
            endDay: 19
        }
    ];

    // Find the zodiac sign based on the birth date and language
    for (var i = 0; i < zodiacSigns.length; i++) {
        var sign = zodiacSigns[i];
        if (
            (month === sign.startMonth && day >= sign.startDay) ||
            (month === sign.endMonth && day <= sign.endDay)
        ) {
            return language === "en" ? sign.en : sign.tr;
        }
    }

    return "Invalid date";
}



export function getWhenFromTimeStamp(timeStamp) {
    // Şu anki zamanı al
    const now = new Date();
    // Verilen zaman damgasını milisaniyeye çevir
    const givenTime = new Date(timeStamp);

    // Zaman farkını hesapla (şu an ile verilen zaman arasındaki fark)
    const diff = now - givenTime;

    const oneDay = 24 * 60 * 60 * 1000;

    // Bir dakika milisaniye cinsinden değeri
    const oneMinute = 60 * 1000;
    // Bir saat milisaniye cinsinden değeri
    const oneHour = 60 * 60 * 1000;

    if (diff < oneMinute) {
        return 'henüz';
    } else if (diff < oneHour) {
        const minutes = Math.floor(diff / oneMinute);
        if (minutes === 1) {
            return 'bir dakika önce';
        } else {
            return `${minutes} dakika önce`;
        }
    } else if (diff < oneDay) {
        const hours = Math.floor(diff / oneHour);
        if (hours === 1) {
            return 'bir saat önce';
        } else {
            return `${hours} saat önce`;
        }
    } else if (diff < 2 * oneDay) {
        return 'dün';
    } else if (diff < 7 * oneDay) {
        // Hafta günleri
        const days = ['Pazar', 'Pazartesi', 'Salı', 'Çarşamba', 'Perşembe', 'Cuma', 'Cumartesi'];
        const indexOfDay = givenTime.getDay();
        return days[indexOfDay];
    } else if (diff < 30 * oneDay) {
        // Geçen hafta
        return 'geçen hafta';
    } else if (diff < 365 * oneDay) {
        // Geçen ay
        return 'geçen ay';
    } else {
        // Daha da eski
        return 'daha da eski';
    }
}

export function playNotificationSound() {
    const sesDosyasi = new Audio(require('../assets/sounds/get-message.wav'));

    // Ses seviyesini ayarlayın (0.5, yani yarı yükseklikte bir ses)
    sesDosyasi.volume = 1;

    // Ses çalma işlemi bir Promise ile sarmak
    let promise = undefined;
    try {
        promise = sesDosyasi.play();
    } catch (err) {
        console.error("ses çalma hatası: ", err)
    }

    if (promise !== undefined) {
        promise.then(() => {
            // Autoplay başladı!
            // console.warn('Ses çalma başladı.');
        }).catch(error => {
            // Autoplay engellendi.
            console.error('Ses çalma engellendi: ', error);

            // Kullanıcıya sesi başlatma seçeneği sunabilirsiniz.
            // Örneğin, bir "Ses Çal" düğmesini görünür hale getirebilirsiniz.
        });
    }

    // Ses çalma işlemi tamamlandığında sesi durdurmak için cleanup yapın
    return () => {
        sesDosyasi.pause();
        sesDosyasi.currentTime = 0;
    };
}
export function playMessageSendedSound() {
    const sesDosyasi = new Audio(require('../assets/sounds/send-message.wav'));

    // Ses seviyesini ayarlayın (0.5, yani yarı yükseklikte bir ses)
    sesDosyasi.volume = 1;

    // Ses çalma işlemi bir Promise ile sarmak
    const promise = sesDosyasi.play();

    if (promise !== undefined) {
        promise.then(() => {
            // Autoplay başladı!
            // console.warn('Ses çalma başladı.');
        }).catch(error => {
            // Autoplay engellendi.
            console.error('Ses çalma engellendi: ', error);

            // Kullanıcıya sesi başlatma seçeneği sunabilirsiniz.
            // Örneğin, bir "Ses Çal" düğmesini görünür hale getirebilirsiniz.
        });
    }

    // Ses çalma işlemi tamamlandığında sesi durdurmak için cleanup yapın
    return () => {
        sesDosyasi.pause();
        sesDosyasi.currentTime = 0;
    };
}

export const zodiakIndexArr = ["akrep", "aslan", "balık", "başak", "boğa", "i̇kizler", "koç", "kova", "oğlak", "terazi", "yay", "yengeç"]
export const zodiakArr = [
    { label: "Koç", value: 0 },
    { label: "Boğa", value: 1 },
    { label: "İkizler", value: 2 },
    { label: "Yengeç", value: 3 },
    { label: "Aslan", value: 4 },
    { label: "Başak", value: 5 },
    { label: "Terazi", value: 6 },
    { label: "Akrep", value: 7 },
    { label: "Yay", value: 8 },
    { label: "Oğlak", value: 9 },
    { label: "Kova", value: 10 },
    { label: "Balık", value: 11 }
];


export const cities = [
    { value: 1, label: "Adana" },
    { value: 2, label: "Adıyaman" },
    { value: 3, label: "Afyon" },
    { value: 4, label: "Ağrı" },
    { value: 5, label: "Amasya" },
    { value: 6, label: "Ankara" },
    { value: 7, label: "Antalya" },
    { value: 8, label: "Artvin" },
    { value: 9, label: "Aydın" },
    { value: 10, label: "Balıkesir" },
    { value: 11, label: "Bilecik" },
    { value: 12, label: "Bingöl" },
    { value: 13, label: "Bitlis" },
    { value: 14, label: "Bolu" },
    { value: 15, label: "Burdur" },
    { value: 16, label: "Bursa" },
    { value: 17, label: "Çanakkale" },
    { value: 18, label: "Çankırı" },
    { value: 19, label: "Çorum" },
    { value: 20, label: "Denizli" },
    { value: 21, label: "Diyarbakır" },
    { value: 22, label: "Edirne" },
    { value: 23, label: "Elazığ" },
    { value: 24, label: "Erzincan" },
    { value: 25, label: "Erzurum" },
    { value: 26, label: "Eskişehir" },
    { value: 27, label: "Gaziantep" },
    { value: 28, label: "Giresun" },
    { value: 29, label: "Gümüşhane" },
    { value: 30, label: "Hakkari" },
    { value: 31, label: "Hatay" },
    { value: 32, label: "Isparta" },
    { value: 33, label: "İçel (Mersin)" },
    { value: 34, label: "İstanbul" },
    { value: 35, label: "İzmir" },
    { value: 36, label: "Kars" },
    { value: 37, label: "Kastamonu" },
    { value: 38, label: "Kayseri" },
    { value: 39, label: "Kırklareli" },
    { value: 40, label: "Kırşehir" },
    { value: 41, label: "Kocaeli" },
    { value: 42, label: "Konya" },
    { value: 43, label: "Kütahya" },
    { value: 44, label: "Malatya" },
    { value: 45, label: "Manisa" },
    { value: 46, label: "Kahramanmaraş" },
    { value: 47, label: "Mardin" },
    { value: 48, label: "Muğla" },
    { value: 49, label: "Muş" },
    { value: 50, label: "Nevşehir" },
    { value: 51, label: "Niğde" },
    { value: 52, label: "Ordu" },
    { value: 53, label: "Rize" },
    { value: 54, label: "Sakarya" },
    { value: 55, label: "Samsun" },
    { value: 56, label: "Siirt" },
    { value: 57, label: "Sinop" },
    { value: 58, label: "Sivas" },
    { value: 59, label: "Tekirdağ" },
    { value: 60, label: "Tokat" },
    { value: 61, label: "Trabzon" },
    { value: 62, label: "Tunceli" },
    { value: 63, label: "Şanlıurfa" },
    { value: 64, label: "Uşak" },
    { value: 65, label: "Van" },
    { value: 66, label: "Yozgat" },
    { value: 67, label: "Zonguldak" },
    { value: 68, label: "Aksaray" },
    { value: 69, label: "Bayburt" },
    { value: 70, label: "Karaman" },
    { value: 71, label: "Kırıkkale" },
    { value: 72, label: "Batman" },
    { value: 73, label: "Şırnak" },
    { value: 74, label: "Bartın" },
    { value: 75, label: "Ardahan" },
    { value: 76, label: "Iğdır" },
    { value: 77, label: "Yalova" },
    { value: 78, label: "Karabük" },
    { value: 79, label: "Kilis" },
    { value: 80, label: "Osmaniye" },
    { value: 81, label: "Düzce" }
];


export function getCityLabelFromCityValue(val) {
    return cities.find(item => item.value === val)?.label || "-"
}
