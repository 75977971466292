import "./MessagesSearchInput.css";

const MessagesSearchInput = (props) => {

    const { placeHolder } = props;


    const searchSvg = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.6719 12.8906C15.082 13.3281 15.082 14.0117 14.6719 14.4492C14.2344 14.8594 13.5508 14.8594 13.1133 14.4492L9.85938 11.168C8.73828 11.9062 7.37109 12.2891 5.89453 12.0977C3.37891 11.7422 1.35547 9.69141 1.02734 7.20312C0.5625 3.48438 3.70703 0.339844 7.42578 0.804688C9.91406 1.13281 11.9648 3.15625 12.3203 5.67188C12.5117 7.14844 12.1289 8.51562 11.3906 9.60938L14.6719 12.8906ZM3.16016 6.4375C3.16016 8.37891 4.71875 9.9375 6.66016 9.9375C8.57422 9.9375 10.1602 8.37891 10.1602 6.4375C10.1602 4.52344 8.57422 2.9375 6.66016 2.9375C4.71875 2.9375 3.16016 4.52344 3.16016 6.4375Z" fill="#515365"/>
    </svg>
    
    return (
        <div id={"messagesSearchInputWrapper"}>
            <input placeholder={placeHolder} className="messagesSearchInput" />
            {searchSvg}
        </div>
    )
}

export default MessagesSearchInput;