import { useEffect, useState } from "react";
import { modalStore, userStore } from "../Store";
import { isValidAndNotEmptyString } from "../utils/utils";

const VanillaDateInput = (props) => {
    const { set, label, labelBackground } = props



    const [month, setMonth] = useState("")
    const [day, setDay] = useState("")
    const [year, setYear] = useState("")

    const [error, setError] = useState(false)

    function isDateValid(tarihStr) {
        const tarihParcalari = tarihStr.split('/');

        if (tarihParcalari.length !== 3) {
            return false; // Geçersiz tarih formatı
        }

        const [gun, ay, yil] = tarihParcalari.map(Number);
        const tarih = new Date(yil, ay - 1, gun); // Ay 0-11 arasında olduğu için ayı düzeltiyoruz

        return (
            tarih.getDate() === gun &&
            tarih.getMonth() === ay - 1 &&
            tarih.getFullYear() === yil
        );
    }

    // useEffect(() => { 

    const checkMonthInput = () => {
        if (month.length === 1) setMonth(`0${month}`)
    }
    const checkDayInput = () => {
        if (day.length === 1) setDay(`0${day}`)
    }
    const checkYearInput = () => {
        if (year.length < 4) setYear("")
    }


    useEffect(() => {
        const formattedDate = `${day}/${month}/${year}`

        if (isDateValid(formattedDate)) {

            set(formattedDate)
        } else {
            set("")
        }
    }, [day, month, year])



    const handleSetMonth = (value) => {
        if (value <= 12 && value >= 0) {
            setMonth(value)
        } else if (value > 12) {
            setMonth(12)
        } else if (value < 0) {
            setMonth(0)
        }
    }

    const handleSetDay = (value) => {
        let lastDayOfMonth = null
        if (new Date(year, month, 0)) {
            lastDayOfMonth = new Date(year, month, 0).getDate()
        } else {
            setDay(0)
            return
        }


        if (value <= lastDayOfMonth && value >= 0) {
            setDay(value)
        } else if (value > lastDayOfMonth) {
            setDay(lastDayOfMonth)
        } else if (value < 0) {
            setDay(0)
        }
    }

    const handleSetYear = (value) => {
        const currentYear = new Date().getFullYear()
        if (value <= currentYear && value >= 0) {
            setYear(value)
        } else if (value > currentYear) {
            setYear(currentYear)
        } else if (value < 0) {
            setYear(0)
        }
    }

    const calendarSvg = <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.4375 14.4688V6.5H13.5625V14.4688C13.5625 15.2598 12.918 15.875 12.1562 15.875H1.84375C1.05273 15.875 0.4375 15.2598 0.4375 14.4688ZM9.8125 8.72656V9.89844C9.8125 10.1035 9.95898 10.25 10.1641 10.25H11.3359C11.5117 10.25 11.6875 10.1035 11.6875 9.89844V8.72656C11.6875 8.55078 11.5117 8.375 11.3359 8.375H10.1641C9.95898 8.375 9.8125 8.55078 9.8125 8.72656ZM9.8125 12.4766V13.6484C9.8125 13.8535 9.95898 14 10.1641 14H11.3359C11.5117 14 11.6875 13.8535 11.6875 13.6484V12.4766C11.6875 12.3008 11.5117 12.125 11.3359 12.125H10.1641C9.95898 12.125 9.8125 12.3008 9.8125 12.4766ZM6.0625 8.72656V9.89844C6.0625 10.1035 6.20898 10.25 6.41406 10.25H7.58594C7.76172 10.25 7.9375 10.1035 7.9375 9.89844V8.72656C7.9375 8.55078 7.76172 8.375 7.58594 8.375H6.41406C6.20898 8.375 6.0625 8.55078 6.0625 8.72656ZM6.0625 12.4766V13.6484C6.0625 13.8535 6.20898 14 6.41406 14H7.58594C7.76172 14 7.9375 13.8535 7.9375 13.6484V12.4766C7.9375 12.3008 7.76172 12.125 7.58594 12.125H6.41406C6.20898 12.125 6.0625 12.3008 6.0625 12.4766ZM2.3125 8.72656V9.89844C2.3125 10.1035 2.45898 10.25 2.66406 10.25H3.83594C4.01172 10.25 4.1875 10.1035 4.1875 9.89844V8.72656C4.1875 8.55078 4.01172 8.375 3.83594 8.375H2.66406C2.45898 8.375 2.3125 8.55078 2.3125 8.72656ZM2.3125 12.4766V13.6484C2.3125 13.8535 2.45898 14 2.66406 14H3.83594C4.01172 14 4.1875 13.8535 4.1875 13.6484V12.4766C4.1875 12.3008 4.01172 12.125 3.83594 12.125H2.66406C2.45898 12.125 2.3125 12.3008 2.3125 12.4766ZM12.1562 2.75C12.918 2.75 13.5625 3.39453 13.5625 4.15625V5.5625H0.4375V4.15625C0.4375 3.39453 1.05273 2.75 1.84375 2.75H3.25V1.34375C3.25 1.10938 3.45508 0.875 3.71875 0.875H4.65625C4.89062 0.875 5.125 1.10938 5.125 1.34375V2.75H8.875V1.34375C8.875 1.10938 9.08008 0.875 9.34375 0.875H10.2812C10.5156 0.875 10.75 1.10938 10.75 1.34375V2.75H12.1562Z" fill="#515365" />
    </svg>


    return (
        <div className="border-bottom-date-wrapper">
            {
                isValidAndNotEmptyString(labelBackground)
                    ? <label style={{ background: labelBackground }}>Doğum Tarihi</label>
                    : null
            }

            <div className={"border-bottom-input-wrapper small"}>
                <input
                    placeholder={!day?.length ? "gg" : null}
                    onChange={(e) => e.target.value.length <= 2 ? handleSetDay(e.target.value) : null}
                    value={day}
                    type="number"
                    max={new Date(year, month, 0).getDate()}
                    min={0}
                    onBlur={() => checkDayInput()}
                    className={error === "day" ? "setting-input error" : "setting-input"} />
                <span>/</span>

            </div>
            <div className={"border-bottom-input-wrapper small"}>
                <input
                    placeholder={!month?.length ? "aa" : null}
                    onChange={(e) => e.target.value.length <= 2 ? handleSetMonth(e.target.value) : null}
                    value={month}
                    type="number"
                    max={12}
                    min={0}
                    onBlur={() => checkMonthInput()}
                    className={error === "month" ? "setting-input error" : "setting-input"} />
                <span>/</span>
            </div>

            <div className={"border-bottom-input-wrapper small"}>
                <input
                    placeholder={!year?.length ? "yyyy" : null}
                    onChange={(e) => e.target.value.length <= 4 ? handleSetYear(e.target.value) : null}
                    value={year}
                    type="number"
                    max={new Date().getFullYear()}
                    min={new Date().getFullYear() - 100}
                    onBlur={() => checkYearInput()}
                    className={error === "year" ? "setting-input error" : "setting-input"} />
            </div>

            {calendarSvg}
        </div>
    )
}

export default VanillaDateInput;

