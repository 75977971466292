import { useState } from "react"
import { useSwipeable } from "react-swipeable"

const MembershipPlanBox = (props) => {
    const { option, optionPrice, nickColor, nick, title, price, description } = props


    const [plan, setPlan] = useState(0)



    const checkSvg = <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.05469 9.8125L1.16406 5.92188C0.929688 5.6875 0.929688 5.28906 1.16406 5.05469L2.00781 4.21094C2.24219 3.97656 2.61719 3.97656 2.85156 4.21094L5.5 6.83594L11.125 1.21094C11.3594 0.976562 11.7344 0.976562 11.9688 1.21094L12.8125 2.05469C13.0469 2.28906 13.0469 2.6875 12.8125 2.92188L5.92188 9.8125C5.6875 10.0469 5.28906 10.0469 5.05469 9.8125Z" fill="#FA6342" />
    </svg>

const handlers = useSwipeable({
    onSwipedLeft: (a) => setPlan(0),
    onSwipedRight: (a) =>  setPlan(1),
    trackMouse: true,
});
    return (

        <div  className="planBox">
            <div className="nickTitlePriceWrapper">
                <p className="nick" style={{ background: `${nickColor}` }}>{nick}</p>
                <h1 className="title">{title}</h1>
                <p className={
                    option ?
                        plan === 0 ?
                            "price month"
                            : plan === 1 ?
                                "price year"
                                : "price"
                        : "price"
                }
                >{plan === 0 ? price : optionPrice}₺</p>
            </div>

            {
                option
                    ? <div {...handlers}  className="optionWrapper">
                        <button
                            onClick={() => setPlan(0)}
                            className={plan === 0 ? "selected" : ""}
                        >
                            Aylık
                        </button>
                        <button
                            onClick={() => setPlan(1)}
                            className={plan === 1 ? "selected" : ""}
                        >
                            Yıllık
                        </button>
                        <span  className={plan === 0 ? "left" : "right"}></span>
                    </div>
                    : <p className="description">{description}</p>
            }

            <div className="addsOddsList">
                <div className="iconAndText">
                    {checkSvg}
                    <p>Anim pariatur cliche reprehenderit</p>
                </div>
                <div className="iconAndText">
                    {checkSvg}
                    <p>Anim pariatur cliche reprehenderit</p>
                </div>
                <div className="iconAndText">
                    {checkSvg}
                    <p>Anim pariatur cliche reprehenderit</p>
                </div>
                <div className="iconAndText">
                    {checkSvg}
                    <p>Anim pariatur cliche reprehenderit</p>
                </div>
                <div className="iconAndText">
                    {checkSvg}
                    <p>Anim pariatur cliche reprehenderit</p>
                </div>
            </div>
        </div>
    )
}

export default MembershipPlanBox;