import { useEffect } from "react"
const AdSense = (props) => {
    const { dataAdSlot } = props;


    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});

        }

        catch (e) {

        }

    }, []);




    return (
        <amp-ad width="200" height="320"
            type="adsense"
            data-ad-client="ca-pub-1967437277296884"
            data-ad-slot="8985653245"
            data-auto-format="rspv"
            data-full-width="">
            <div overflow=""></div>
        </amp-ad>

    );
};

export default AdSense;
