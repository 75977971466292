import { ref, set } from "firebase/database"
import { useEffect, useState } from "react"
import { auth, dataBase } from "../../fireBaseConfig"
import { modalStore, userStore } from "../../Store"
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail } from "firebase/auth"
import { ClipLoader } from "react-spinners"
import { ca } from "date-fns/locale"



const BirthDateChangeProfileInfoRow = () => {
    const setModal = modalStore((state) => state.setModal)

    const [month, setMonth] = useState("")
    const [day, setDay] = useState("")
    const [year, setYear] = useState("")

    const [pending, setPending] = useState(false)
    const [error, setError] = useState(false)

    const user = userStore((state) => state.user)

    // useEffect(() => { 

    const checkMonthInput = () => {
        if (month.length === 1) setMonth(`0${month}`)
    }
    const checkDayInput = () => {
        if (day.length === 1) setDay(`0${day}`)
    }
    const checkYearInput = () => {
        if (year.length < 4) setYear("")
    }

   

    useEffect(() => {
        if (!user && typeof user.birthDate !== "string") return
        setDay(user.birthDate?.split("/")[0])
        setMonth(user.birthDate?.split("/")[1])
        setYear(user.birthDate?.split("/")[2])
    }, [user])



    useEffect(() => {
        let lastDayOfMonth = null
        if (new Date(year, month, 0)) {
            lastDayOfMonth = new Date(year, month, 0).getDate()
        }
        if (lastDayOfMonth !== null && day?.length > 0 && Number(day) > new Date(year, month, 0).getDate()) {
            setDay(`${lastDayOfMonth}`)
        }
    }, [year, month])


    function isDateValid(month, day, year) {
        const date = new Date(Number(year), Number(month) - 1, Number(day));


        if (
            date.getFullYear() === Number(year) &&
            date.getMonth() === Number(month) - 1 &&
            date.getDate() === Number(day)
        ) {

        } else {
            if (date.getFullYear() !== Number(year)) setError("year")
            if (date.getMonth() !== Number(month) - 1) setError("month")
            if (date.getDate() !== Number(day)) setError("day")
        }

        return (
            date.getFullYear() === Number(year) &&
            date.getMonth() === Number(month) - 1 &&
            date.getDate() === Number(day)
        );
    }

    const updateUserBirthDate = async () => {
        // isDateValid()
        if (!isDateValid(month, day, year)) return
        setPending(true)
        try {
            await set(ref(dataBase, 'users/' + `${user.uid}/birthDate/`), `${day}/${month}/${year}`)
            setModal({
                type: "success",
                status: true,
                message: `Doğum günü değiştirildi.`
            })
        }
        catch (e) {
            setModal({
                type: "error",
                status: true,
                message: `${e.message}`
            })
        }
        setError(false)
        setPending(false)
    }

    // useEffect(() => console.log("error", error), [error])


    const editSvg = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1172 3.04688L13.5781 5.50781C13.6875 5.61719 13.6875 5.78125 13.5781 5.89062L7.61719 11.8516L5.07422 12.125C4.74609 12.1797 4.44531 11.8789 4.5 11.5508L4.77344 9.00781L10.7344 3.04688C10.8438 2.9375 11.0078 2.9375 11.1172 3.04688ZM15.5469 2.41797C15.957 2.82812 15.957 3.51172 15.5469 3.92188L14.5898 4.87891C14.4805 4.98828 14.3164 4.98828 14.207 4.87891L11.7461 2.41797C11.6367 2.30859 11.6367 2.14453 11.7461 2.03516L12.7031 1.07812C13.1133 0.667969 13.7969 0.667969 14.207 1.07812L15.5469 2.41797ZM10.625 10.2383C10.625 10.1289 10.6523 10.0469 10.707 9.99219L11.8008 8.89844C12.0195 8.70703 12.375 8.84375 12.375 9.14453V13.4375C12.375 14.1758 11.7734 14.75 11.0625 14.75H1.4375C0.699219 14.75 0.125 14.1758 0.125 13.4375V3.8125C0.125 3.10156 0.699219 2.5 1.4375 2.5H9.23047C9.53125 2.5 9.66797 2.85547 9.47656 3.07422L8.38281 4.16797C8.32812 4.22266 8.24609 4.25 8.13672 4.25H1.875V13H10.625V10.2383Z" fill="#fff" />
    </svg>

    const calendarSvg = <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.4375 14.4688V6.5H13.5625V14.4688C13.5625 15.2598 12.918 15.875 12.1562 15.875H1.84375C1.05273 15.875 0.4375 15.2598 0.4375 14.4688ZM9.8125 8.72656V9.89844C9.8125 10.1035 9.95898 10.25 10.1641 10.25H11.3359C11.5117 10.25 11.6875 10.1035 11.6875 9.89844V8.72656C11.6875 8.55078 11.5117 8.375 11.3359 8.375H10.1641C9.95898 8.375 9.8125 8.55078 9.8125 8.72656ZM9.8125 12.4766V13.6484C9.8125 13.8535 9.95898 14 10.1641 14H11.3359C11.5117 14 11.6875 13.8535 11.6875 13.6484V12.4766C11.6875 12.3008 11.5117 12.125 11.3359 12.125H10.1641C9.95898 12.125 9.8125 12.3008 9.8125 12.4766ZM6.0625 8.72656V9.89844C6.0625 10.1035 6.20898 10.25 6.41406 10.25H7.58594C7.76172 10.25 7.9375 10.1035 7.9375 9.89844V8.72656C7.9375 8.55078 7.76172 8.375 7.58594 8.375H6.41406C6.20898 8.375 6.0625 8.55078 6.0625 8.72656ZM6.0625 12.4766V13.6484C6.0625 13.8535 6.20898 14 6.41406 14H7.58594C7.76172 14 7.9375 13.8535 7.9375 13.6484V12.4766C7.9375 12.3008 7.76172 12.125 7.58594 12.125H6.41406C6.20898 12.125 6.0625 12.3008 6.0625 12.4766ZM2.3125 8.72656V9.89844C2.3125 10.1035 2.45898 10.25 2.66406 10.25H3.83594C4.01172 10.25 4.1875 10.1035 4.1875 9.89844V8.72656C4.1875 8.55078 4.01172 8.375 3.83594 8.375H2.66406C2.45898 8.375 2.3125 8.55078 2.3125 8.72656ZM2.3125 12.4766V13.6484C2.3125 13.8535 2.45898 14 2.66406 14H3.83594C4.01172 14 4.1875 13.8535 4.1875 13.6484V12.4766C4.1875 12.3008 4.01172 12.125 3.83594 12.125H2.66406C2.45898 12.125 2.3125 12.3008 2.3125 12.4766ZM12.1562 2.75C12.918 2.75 13.5625 3.39453 13.5625 4.15625V5.5625H0.4375V4.15625C0.4375 3.39453 1.05273 2.75 1.84375 2.75H3.25V1.34375C3.25 1.10938 3.45508 0.875 3.71875 0.875H4.65625C4.89062 0.875 5.125 1.10938 5.125 1.34375V2.75H8.875V1.34375C8.875 1.10938 9.08008 0.875 9.34375 0.875H10.2812C10.5156 0.875 10.75 1.10938 10.75 1.34375V2.75H12.1562Z" fill="#515365" />
    </svg>

    return (
        <div className="setting-row dateSide">
            <p className="label">Doğum Tarhi</p>
            <div className="border-bottom-date-wrapper">
               
                <div className={"border-bottom-input-wrapper small"}>
                    <input
                        placeholder={!day?.length ? "gg" : null}
                        onChange={(e) => e.target.value.length <= 2 ? setDay(e.target.value) : null}
                        value={day}
                        type="number"
                        max={new Date(year, month, 0).getDate()}
                        min={0}
                        onBlur={() => checkDayInput()}
                        className={error === "day" ? "setting-input error" : "setting-input"} />
                    <span>/</span>

                </div>
                <div className={"border-bottom-input-wrapper small"}>
                    <input
                        placeholder={!month?.length ? "aa" : null}
                        onChange={(e) => e.target.value.length <= 2 ? setMonth(e.target.value) : null}
                        value={month}
                        type="number"
                        max={12}
                        min={0}
                        onBlur={() => checkMonthInput()}
                        className={error === "month" ? "setting-input error" : "setting-input"} />
                    <span>/</span>
                </div>
                <div className={"border-bottom-input-wrapper small"}>
                    <input
                        placeholder={!year?.length ? "yyyy" : null}
                        onChange={(e) => e.target.value.length <= 4 ? setYear(e.target.value) : null}
                        value={year}
                        type="number"
                        max={new Date().getFullYear()}
                        min={new Date().getFullYear() - 100}
                        onBlur={() => checkYearInput()}
                        className={error === "year" ? "setting-input error" : "setting-input"} />
                </div>

                {calendarSvg}
            </div>
            <button onClick={() => updateUserBirthDate()}
                disabled={pending ? true : false}
                className={pending ? "edit-button silence" : "edit-button"}
            >


                {
                    pending
                        ? <ClipLoader color="#2873b8" size={15} />
                        : editSvg
                }
            </button>
        </div>
    )
}

export default BirthDateChangeProfileInfoRow;