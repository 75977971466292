import "./PictureSelector.css"
import { userStore, modalStore, dataBaseUserStore, profileStore, usersStore, pictureSelectorStrore } from '../Store';
import { useEffect, useState } from "react";
import { useRef } from "react";
import { getDownloadURL, ref as sRef, uploadBytes } from 'firebase/storage';
import { dataBase, storage } from "../fireBaseConfig";
import { ref, set } from "firebase/database";
import editSvg from "../assets/edit-square.svg"

import CustomCrop from "./CustomCrop.tsx"
import { upload } from "@testing-library/user-event/dist/upload";
import { handleResizeAndConver } from "../utils/utils";
import ImageResize from "image-resize";

const PictureSelector = (props) => {
    const { Aspect } = props


    async function handleResizeAndConver(file, photoType) {
        let width = 0
        {
            photoType == "profilePhoto"
                ? width = 500
                : width = 620
        }
        var imageResize = new ImageResize({
            format: 'jpeg',
            width: width
        });

        // await imageResize.play(file).then((res) => console.warn("res", res))

    }
    async function resizeAndConvertToJPEG(blob, width, height) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg');
            };
            img.onerror = (error) => {
                reject(error);
            };
            img.src = URL.createObjectURL(blob);
        });
    }

    const user = userStore((state) => state.user)


    const setPictureSelector = pictureSelectorStrore((state) => state.setPictureSelector)
    const pictureSelector = pictureSelectorStrore((state) => state.pictureSelector)


    function uploadImages(file) {
        // 

        if (file.type !== "image/png") return;
        let size = { "width": 0, "height": 0 }
        {
            pictureSelector?.which === "profilePhoto"
                ? size = { width: 90, height: 90 }
                : size = { width: 1250, height: 320 }
        }

        resizeAndConvertToJPEG(file, size.width, size.height).then(res => {

            let selectedFile = res;
            const mountainImagesRef = sRef(storage, `photos/${user.uid}/${pictureSelector?.which}`);



            console.warn("selected file: ", selectedFile);



            uploadBytes(mountainImagesRef, selectedFile)
                .then((snapshot) => {

                })
                .then(() => {
                    getDownloadURL(sRef(storage, `photos/${user.uid}/${pictureSelector?.which}`))
                        .then((url) => {
                            set(ref(dataBase, "users/" + `${user?.uid}/${pictureSelector?.which}`), url)
                                .then(() => {

                                })
                                .catch((error) => {
                                    console.error("Veri eklenirken bir hata oluştu:", error);
                                });
                        });
                });
        })

    }


    const [file, setFile] = useState("")

    useEffect(() => uploadImages(file), [file])

    const closeButtonSvg = <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.3594 10.6406C12.8672 11.1094 12.8672 11.9297 12.3594 12.3984C12.125 12.6328 11.8125 12.75 11.5 12.75C11.1484 12.75 10.8359 12.6328 10.6016 12.3984L6.5 8.29688L2.35938 12.3984C2.125 12.6328 1.8125 12.75 1.5 12.75C1.14844 12.75 0.835938 12.6328 0.601562 12.3984C0.09375 11.9297 0.09375 11.1094 0.601562 10.6406L4.70312 6.5L0.601562 2.39844C0.09375 1.92969 0.09375 1.10938 0.601562 0.640625C1.07031 0.132812 1.89062 0.132812 2.35938 0.640625L6.5 4.74219L10.6016 0.640625C11.0703 0.132812 11.8906 0.132812 12.3594 0.640625C12.8672 1.10938 12.8672 1.92969 12.3594 2.39844L8.25781 6.53906L12.3594 10.6406Z" fill="#DADEE1"/>
    </svg>

    return (
        <>
            {
                pictureSelector?.status
                    ? < div id="pictureSelectorWrapper">
                        <CustomCrop Aspect={pictureSelector.aspect} which={pictureSelector?.which} setFile={setFile} />
                        <button onClick={() => setPictureSelector({ status: false })} className='cropCloseButton'>{closeButtonSvg}</button>
                    </div >
                    : null
            }
        </>
    )
}

export default PictureSelector;