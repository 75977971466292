import { useState, useEffect } from "react"
import "./PersonInfoModal.css"
import ProfileCircle from "./ProfileCircle"
import { getBirthDateByUid, getProfileUserCityByUid, getUsersBioByUid, getUsersEmailByUid, getUsersNameByUid, getZodiacSign, isValidObject } from "../utils/utils";

const PersonInfoModal = (props) => {
    const { uid, name, isOnline } = props;

    const [open, setOpen] = useState(false)
    const [city, setCity] = useState("")
    const [zodiac, setZodiac] = useState("")
    const [email, setEmail] = useState("")
    const [bio, setBio] = useState("")

    useEffect(() => {
        getProfileUserCityByUid(uid).then(data => setCity(data))
        getBirthDateByUid(uid).then(data => setZodiac(getZodiacSign(data)))
        getUsersEmailByUid(uid).then(data => setEmail(data))
        getUsersBioByUid(uid).then(data => setBio(data))
    }, [uid])

    const infoSvg = <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.0714 1.28571H1.92857C0.863437 1.28571 0 2.14914 0 3.21428V17.3571C0 18.4223 0.863437 19.2857 1.92857 19.2857H16.0714C17.1366 19.2857 18 18.4223 18 17.3571V3.21428C18 2.14914 17.1366 1.28571 16.0714 1.28571ZM9 4.74106C9.93198 4.74106 10.6875 5.49658 10.6875 6.42856C10.6875 7.36054 9.93198 8.11606 9 8.11606C8.06802 8.11606 7.3125 7.36054 7.3125 6.42856C7.3125 5.49658 8.06802 4.74106 9 4.74106ZM11.25 14.9464C11.25 15.2127 11.0341 15.4286 10.7679 15.4286H7.23214C6.96588 15.4286 6.75 15.2127 6.75 14.9464V13.9821C6.75 13.7159 6.96588 13.5 7.23214 13.5H7.71429V10.9286H7.23214C6.96588 10.9286 6.75 10.7127 6.75 10.4464V9.48213C6.75 9.21587 6.96588 8.99999 7.23214 8.99999H9.80357C10.0698 8.99999 10.2857 9.21587 10.2857 9.48213V13.5H10.7679C11.0341 13.5 11.25 13.7159 11.25 13.9821V14.9464Z" fill="#FA6342" />
    </svg>
    const closeSvg= <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.125 0.75C19.1406 0.75 20 1.60938 20 2.625V16.375C20 17.4297 19.1406 18.25 18.125 18.25H1.875C0.820312 18.25 0 17.4297 0 16.375V2.625C0 1.60938 0.820312 0.75 1.875 0.75H18.125ZM18.125 16.1406V2.85938C18.125 2.74219 18.0078 2.625 17.8906 2.625H2.10938C1.95312 2.625 1.875 2.74219 1.875 2.85938V16.1406C1.875 16.2969 1.95312 16.375 2.10938 16.375H17.8906C18.0078 16.375 18.125 16.2969 18.125 16.1406ZM13.9062 7.11719L11.5234 9.5L13.9062 11.9219C14.1016 12.0781 14.1016 12.3906 13.9062 12.5859L13.0469 13.4453C12.8516 13.6406 12.5781 13.6406 12.3828 13.4453L10 11.0625L7.57812 13.4453C7.42188 13.6406 7.10938 13.6406 6.91406 13.4453L6.05469 12.5859C5.85938 12.3906 5.85938 12.1172 6.05469 11.9219L8.4375 9.5L6.05469 7.11719C5.85938 6.92188 5.85938 6.64844 6.05469 6.45312L6.91406 5.59375C7.10938 5.39844 7.38281 5.39844 7.57812 5.59375L10 7.97656L12.3828 5.59375C12.5781 5.39844 12.8516 5.39844 13.0469 5.59375L13.9062 6.45312C14.1016 6.64844 14.1016 6.92188 13.9062 7.11719Z" fill="#FA6342"/>
    </svg>
    

    return (
        <div id={"personInfoModalWrapper"} className={open ? "open" : ""}>
            <div className={"header"}>
                <button
                    onClick={() => setOpen(!open)}
                    className={open ? "toggle-button inOpen" : "toggle-button" }
                >
                    { open ? closeSvg : infoSvg}
                </button>
            </div>
            <div className={"body"}>
                <div className="sec-1">
                    <ProfileCircle size={120} onlineEnable={false} userUid={uid} />
                    <h1>{name}</h1>
                    <p>{isOnline ? "Online" : "Offline"}</p>
                </div>
                <div className="sec-2">
                    <div className="row">
                        <label>Hakkında</label>
                        <p>
             {
                isValidObject(bio) ? bio.aboutMe : null
             }
                        </p>
                    </div>
                    <div className="infoTableWrapper">
                        <div className={"keys"}>
                            <label>Şehir:</label>
                            <label>Burç:</label>
                            <label>Email:</label>
                        </div>
                        <div className="values">
                            <p>{city}</p>
                            <p>{zodiac}</p>
                            <p>{email}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PersonInfoModal;