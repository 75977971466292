import { ref, set } from "firebase/database"
import "./ProfileSettings.css"
import { useEffect, useState } from "react"
import { auth, dataBase } from "./fireBaseConfig"
import { modalStore, userStore } from "./Store"
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail } from "firebase/auth"
import NameChangeProfileInfoRow from "./components/ChangeAcountSettingsInputs/NameChangeProfileInfoRow"
import EmailChangeProfileInfoRow from "./components/ChangeAcountSettingsInputs/EmailChangeProfileInfoRow"
import BirthDateChangeProfileInfoRow from "./components/ChangeAcountSettingsInputs/BirthDateChangeProfileInfoRow"
import ChangePassword from "./components/ChangeAcountSettingsInputs/ChangePassword"
import CityChangeProileInfoRow from "./components/ChangeAcountSettingsInputs/CityChangeProileInfoRow"
import ErrorBoundary from "./ErrorBoundary"
import { Link } from "react-router-dom"
import Modal from "./components/Modal"

const ProfileSettings = () => {

    const settingsSvg = <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3164 9.39062C13.4531 9.47266 13.5078 9.63672 13.4805 9.77344C13.1523 10.7578 12.6602 11.6328 11.9766 12.3711C11.8672 12.4805 11.7031 12.5078 11.5664 12.4258L10.3906 11.7695C9.89844 12.1797 9.35156 12.5078 8.75 12.7266V14.0664C8.75 14.2031 8.64062 14.3398 8.47656 14.3945C7.51953 14.5859 6.50781 14.6133 5.49609 14.3945C5.35938 14.3398 5.25 14.2031 5.25 14.0664V12.7266C4.62109 12.5078 4.07422 12.1797 3.58203 11.7695L2.40625 12.4258C2.26953 12.5078 2.10547 12.4805 1.99609 12.3711C1.33984 11.6328 0.820312 10.7578 0.519531 9.77344C0.464844 9.63672 0.519531 9.47266 0.65625 9.39062L1.83203 8.73438C1.72266 8.07812 1.72266 7.44922 1.83203 6.79297L0.65625 6.13672C0.519531 6.05469 0.464844 5.89062 0.519531 5.75391C0.820312 4.76953 1.33984 3.89453 1.99609 3.15625C2.10547 3.04688 2.26953 3.01953 2.40625 3.10156L3.58203 3.78516C4.07422 3.34766 4.62109 3.01953 5.25 2.80078V1.46094C5.25 1.32422 5.33203 1.1875 5.49609 1.16016C6.45312 0.941406 7.46484 0.914062 8.47656 1.16016C8.64062 1.1875 8.75 1.32422 8.75 1.46094V2.80078C9.35156 3.01953 9.89844 3.34766 10.3906 3.75781L11.5664 3.10156C11.7031 3.01953 11.8672 3.04688 11.9766 3.15625C12.6328 3.89453 13.1523 4.76953 13.4531 5.75391C13.5078 5.89062 13.4531 6.05469 13.3164 6.13672L12.1406 6.79297C12.2773 7.44922 12.2773 8.07812 12.1406 8.73438L13.3164 9.39062ZM7 9.9375C8.20312 9.9375 9.1875 8.98047 9.1875 7.75C9.1875 6.54688 8.20312 5.5625 7 5.5625C5.76953 5.5625 4.8125 6.54688 4.8125 7.75C4.8125 8.98047 5.76953 9.9375 7 9.9375Z" fill="#515365" />
    </svg>
    const editSvg = <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.1172 3.04688L13.5781 5.50781C13.6875 5.61719 13.6875 5.78125 13.5781 5.89062L7.61719 11.8516L5.07422 12.125C4.74609 12.1797 4.44531 11.8789 4.5 11.5508L4.77344 9.00781L10.7344 3.04688C10.8438 2.9375 11.0078 2.9375 11.1172 3.04688ZM15.5469 2.41797C15.957 2.82812 15.957 3.51172 15.5469 3.92188L14.5898 4.87891C14.4805 4.98828 14.3164 4.98828 14.207 4.87891L11.7461 2.41797C11.6367 2.30859 11.6367 2.14453 11.7461 2.03516L12.7031 1.07812C13.1133 0.667969 13.7969 0.667969 14.207 1.07812L15.5469 2.41797ZM10.625 10.2383C10.625 10.1289 10.6523 10.0469 10.707 9.99219L11.8008 8.89844C12.0195 8.70703 12.375 8.84375 12.375 9.14453V13.4375C12.375 14.1758 11.7734 14.75 11.0625 14.75H1.4375C0.699219 14.75 0.125 14.1758 0.125 13.4375V3.8125C0.125 3.10156 0.699219 2.5 1.4375 2.5H9.23047C9.53125 2.5 9.66797 2.85547 9.47656 3.07422L8.38281 4.16797C8.32812 4.22266 8.24609 4.25 8.13672 4.25H1.875V13H10.625V10.2383Z" fill="#FA6342" />
    </svg>

    const messageSvg = <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 0.625C11.8555 0.625 15 3.19531 15 6.3125C15 9.45703 11.8555 12 8 12C6.93359 12 5.94922 11.8086 5.04688 11.4805C4.39062 12.0273 3.02344 12.875 1.21875 12.875C1.10938 12.875 1.05469 12.8477 1 12.7656C0.972656 12.6836 1 12.5742 1.05469 12.5195C1.05469 12.4922 2.20312 11.2891 2.55859 9.89453C1.57422 8.91016 1 7.67969 1 6.3125C1 3.19531 4.11719 0.625 8 0.625Z" fill="#515365" />
    </svg>


    return (
        <div id={"acount-seting"}>
            
            <div className="container">
               <div className="leftWrapper">
               <div className={"left"}>
                    <div className={"iconAndTitle"}>
                        {settingsSvg}
                        <p>Genel Ayarlar</p>
                    </div>
                    <div className={"iconAndTitle"}>
                        {editSvg}
                        <p>Profil Ayarları</p>
                    </div>
                    <div className={"iconAndTitle"}>
                        {messageSvg}
                        <p>Mesajlar</p>
                    </div>
                </div>
               </div>
                <div className={"right"}>
                    <div className="header">
                        <h1>Profil Ayarları</h1>
                        <p>Profil ayarlarını düzenle</p>
                    </div>
                    <div className="body">
                    <div className={"inputsRow"}>
                        <NameChangeProfileInfoRow />
                        <EmailChangeProfileInfoRow />
                    </div>
                    <div className={"inputsRow"}>
                        <BirthDateChangeProfileInfoRow />
                        <CityChangeProileInfoRow />
                    </div>

                    <div className={"inputsRow"}>
                        <ChangePassword />
                    </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ProfileSettings