import "./YouHaveNoProfile.css"
import png1 from "../assets/no_profile_1.png"
import png2 from "../assets/no_profile_2.png"
import png3 from "../assets/no_profile_3.png"
import bg from "../assets/no_profile_bg.png"


const partnersSvg = <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.97266 0.75C9.35547 0.75 9.65625 1.05078 9.62891 1.40625V14.0938C9.62891 14.4766 9.30078 14.75 8.97266 14.75C8.61719 14.75 8.34375 14.4766 8.34375 14.0938V1.40625C8.34375 1.05078 8.61719 0.75 8.97266 0.75ZM6.07422 5.45312L7.14062 8.78906C7.30469 9.25391 7.05859 9.74609 6.59375 9.91016C6.48438 9.9375 6.40234 9.96484 6.32031 9.96484C6.04688 9.96484 5.82812 9.82812 5.66406 9.63672V13.875C5.66406 14.3672 5.28125 14.75 4.81641 14.75C4.32422 14.75 3.94141 14.3672 3.94141 13.875V9.9375H3.50391V13.875C3.50391 14.3672 3.12109 14.75 2.65625 14.75C2.16406 14.75 1.78125 14.3672 1.78125 13.875V9.66406C1.53516 9.91016 1.17969 10.0195 0.824219 9.91016C0.386719 9.74609 0.140625 9.25391 0.277344 8.78906L1.39844 5.45312C1.64453 4.74219 2.30078 4.25 3.06641 4.25H4.40625C5.17188 4.25 5.82812 4.74219 6.07422 5.45312ZM3.75 3.375C3.01172 3.375 2.4375 2.80078 2.4375 2.0625C2.4375 1.35156 3.01172 0.75 3.75 0.75C4.46094 0.75 5.03516 1.35156 5.03516 2.0625C5.03516 2.80078 4.46094 3.375 3.75 3.375ZM14.2227 3.375C13.5117 3.375 12.9375 2.80078 12.9375 2.0625C12.9375 1.35156 13.457 0.75 14.2227 0.75C14.9609 0.75 15.5352 1.35156 15.5352 2.0625C15.5352 2.80078 14.9336 3.375 14.2227 3.375ZM17.6953 8.78906C17.832 9.25391 17.5859 9.74609 17.0391 9.91016C16.957 9.9375 16.8477 9.96484 16.7656 9.96484L17.2031 11.25H16.1367V13.875C16.1367 14.3672 15.7266 14.75 15.2617 14.75C14.7969 14.75 14.4141 14.3672 14.4141 13.875V11.25H13.9766V13.875C13.9766 14.3672 13.5391 14.75 13.1016 14.75C12.6367 14.75 12.2539 14.3672 12.2539 13.875V11.25H11.1602L11.625 9.9375C11.5156 9.9375 11.4336 9.9375 11.3242 9.91016C10.8867 9.74609 10.6406 9.25391 10.7773 8.78906L11.8984 5.45312C12.1445 4.74219 12.8008 4.25 13.5664 4.25H14.9062C15.6719 4.25 16.3281 4.74219 16.5742 5.45312L17.6953 8.78906Z" fill="white" />
</svg>
const YouHaveNoProfile = () => {
    return (
        <div id="youHaveNoProfileWrapper">
            <h1>Henüz bir Eşleşme eklemediniz !</h1>
            <div className="noProfileRow">
                <img src={png1} />
                <div className="textAndIcon">
                    <p>1 - Soldaki menüden <span>Partner Ekle</span> butonuna tıklayın.</p>
                    {partnersSvg}
                </div>
            </div>
            <div className="noProfileRow">
                <p>2 - Açılan pencerede kendi bucunuz ve
                    partnerinizin burcunu seçerek bir eşleşme
                    oluşturun.</p>
                <img src={png2} />
            </div>
            <div className="noProfileRow">
                <img src={png3} />
                <p>3 - Artık hazırsınız, ana sayfaya dönerek
                    oluşturduğunuz eşleşmeyle ilgili
                    konulara göz atıp sizde görüşlerinizi
                    paylaşabilirsiniz</p>
            </div>
            <img src={bg} />
        </div>
    )
}

export default YouHaveNoProfile;