import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import NavigationIcon from '@mui/icons-material/Navigation';
import Fab from '@mui/material/Fab';

const GoBackButton = (props) => {

    const { onClick, height } = props;

    return (
        <Fab
            sx={{
                "&": {
                    height: height,
                    width: "auto",
                    aspectRatio: 1,
                    minHeight: 0,
                    backgroundColor: "transparent",
                    boxShadow: "none",
                },

                "& svg": {
                    fill: "#2873b8",
                },

                "&:hover": {
                    height: height,
                    width: "auto",
                    aspectRatio: 1,
                    backgroundColor: "#e3f2ff",
                },
                "&:hover svg": {
                    fill: "#1d90f5",
                },
            }}
            onClick={onClick}
            color="primary"
            aria-label="add"
        >
            <ArrowBackIosRoundedIcon fill='#FA6342' />
        </Fab>
    )
}


export default GoBackButton;