import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCWnCJ0CFpogXmNNzh2Bw2HkJEC0jG1QY4",
    authDomain: "zodyaki.firebaseapp.com",
    projectId: "zodyaki",
    storageBucket: "zodyaki.appspot.com",
    messagingSenderId: "272230614814",
    appId: "1:272230614814:web:cfb0ba6145442a32352b0f",
    measurementId: "G-MPGPN58HK4",
    databaseURL: "https://zodyaki-default-rtdb.europe-west1.firebasedatabase.app"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const fireStore = getFirestore(app)
export const dataBase = getDatabase(app);
const analytics = getAnalytics(app);

export const storage = getStorage();

export const handleSignOut = () => {
    const auth = getAuth();
    signOut(auth).then(() => {
        // Sign-out successful.
        window.location.href = "/";
    }).catch((error) => {
        // An error happened.
    });
}