import { useEffect, useRef, useState } from "react";
import "./AdminAboutEditForm.css"
import { isValidAndNotEmptyString } from "../utils/utils";
import { ref, set } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import { aboutDirectionStore, modalStore, userStore, whichSideStore } from "../Store";
import ReactQuill, {Quill} from "react-quill";
import 'react-quill/dist/quill.snow.css';

const AdminAboutEditForm = (props) => {

    const { setEdit, edit, paragraph, title, currentHtml } = props;

    const user = userStore((state) => state.user)

    const setModal = modalStore((state) => state.setModal)
    const aboutDirection = aboutDirectionStore((state) => state.aboutDirection)
    const whichSide = whichSideStore(state => state.whichSide)

    const [editedTitle, setEditedTitle] = useState("")
    const [p, setP] = useState("")
    const [html, setHtml] = useState("")

    const sendAbout = async () => {
        try {
            await set(ref(dataBase, "abouts/" + aboutDirection + "/" + whichSide), {
                which: whichSide,
                title: editedTitle || title,
                paragraph: p || paragraph,
                html: html || currentHtml,
                aboutDirection: aboutDirection,
                whichSide: whichSide,
            })
            setModal({
                type: "success",
                status: true,
                message: `About güncelledi`
            })
        } catch (err) {
            console.error("about update error: ", err)
            setModal({
                type: "error",
                status: true,
                message: `Bir hata oluştu`
            })
        }

    }



    const handle = (editor) => {
        if (editor !== "") {
            setP(editor.getText())
            setHtml(editor.getHTML())

            
        }
    }

    var pxArr = [];

for (var i = 8; i <= 40; i++) {
    pxArr.push(`${i}px`);
}

var Size = Quill.import("attributors/style/size");
const FontAttributor = Quill.import('attributors/class/font');

Size.whitelist = pxArr
Quill.register(Size, true);
FontAttributor.whitelist = [
  'Calibri',
  'Verdana',
  'Tahoma',
  'NewRoman',
  'Arial',
  'Ubuntu',
  'Roboto',
];
Quill.register(FontAttributor, true);
// , 'smals', false, 'large', 'huge'
    var toolbarOptions = [
        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
        ['blockquote', 'code-block'],

        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction

        [{ 'size': pxArr }],  // custom dropdown
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'font': FontAttributor.whitelist }],
        [{ 'align': [] }],

        ['clean']                                         // remove formatting button
    ];


    

    return (
        <>
            {
                user.hasOwnProperty("isAdmin") && user.isAdmin === true
                    ?
                    <div className={"aboutContentEditForm"}>
                        <input defaultValue={isValidAndNotEmptyString(title) ? title : "boş"} onChange={(e) => setEditedTitle(e.target.value)} />
                        {/* <textarea defaultValue={isValidAndNotEmptyString(paragraph) ? paragraph : "boş"} onChange={(e) => setP(e.target.value)} /> */}
                        <ReactQuill
                            modules={
                                { toolbar: toolbarOptions }
                            }
                            theme="snow" style={{ width: "100%" }} value={html || currentHtml} onChange={(a, b, c, d, e) => handle(d)}
                        />
                        <div className="contentEditButtonsWrapper">
                            <button className="contentEditButton" onClick={() => setEdit(!edit)}>{edit ? "kapat" : "edit"}</button>
                            <button className="contentEditButton" onClick={() => sendAbout()}>Gönder</button>
                        </div>
                    </div>
                    :
                    null
            }
        </>
    )
}

export default AdminAboutEditForm;