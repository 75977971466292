import "./HamburgerMenu.css"

const HamburgerMenu = (props) => {
    const {onClick} = props;

    return (
        <div onClick={onClick} className="hamburgerMenuWrapper">

            <div id="menuToggle">
                <input type="checkbox" />
                <span className="span"></span>
                <span className="span"></span>
                <span className="span"></span>
            </div>

        </div>
    )
}

export default HamburgerMenu;