import React, { useState, useRef, useEffect } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { canvasPreview } from './canvasPreview.ts'
import { useDebounceEffect } from './useDebounceEffect.ts'

import 'react-image-crop/dist/ReactCrop.css'
import { dataBase, storage } from '../fireBaseConfig.jsx'
import { getDownloadURL, ref as sRef, uploadBytes } from 'firebase/storage';
import { set } from 'date-fns'
import { ref } from 'firebase/database'
import { userStore } from '../Store.jsx'
import "./CustomCrop.css"

import { getBlobForFile, imageProcessor, resize, sharpen, output, applyExifOrientation } from 'ts-image-processor';
import ImageResize from 'image-resize';


// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

export default function CustumCrop(props) {

  const { Aspect, setFile, which } = props

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(Aspect)

  // function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
  //   if (e.target.files && e.target.files.length > 0) {

  //     setCrop(undefined) // Makes crop preview update between images.
  //     const reader = new FileReader()
  //     reader.addEventListener('load', () =>
  //       setImgSrc(reader.result?.toString() || ''),
  //       // console.log("teete", reader.result?.toString())
  //     )
  //     reader.readAsDataURL(e.target.files[0])

  //   } else if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
  //     setCrop(undefined) // Makes crop preview update between images.
  //     const reader = new FileReader()
  //     reader.addEventListener('load', () =>
  //       setImgSrc(reader.result?.toString() || ''),
  //     )
  //     reader.readAsDataURL(e.dataTransfer.files[0])
  //   }
  // }

   function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    setCrop(undefined); // Görüntü kesme önizlemesini görüntüler arasında günceller.

    const file = e.target.files?.[0] || e.dataTransfer.files?.[0];
    if (file) {
      const reader = new FileReader();
       reader.onload = () => {
        setImgSrc(reader.result?.toString() || '');
      };
       reader.readAsDataURL(file);
       
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  const user = userStore((state) => state.user)



  function onDownloadCropClick() {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      setFile(blob)
    })
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(16 / 9)
      setCrop(centerAspectCrop(width, height, 16 / 9))
    }
  }

  function test(event) {
    onSelectFile(event)
  }


  const handleFileChange = (event) => {
    event.preventDefault();
    onSelectFile(event)
  };

  const handleDrop = (event) => {
    event.preventDefault();
    onSelectFile(event);

  };

  const handleDragOver = (event) => {
    event.preventDefault();
    
    onSelectFile(event);
  };

  const [img, setImg] = useState(false)
  useEffect(() => !!imgSrc ? setImg(true) : setImg(false), [imgSrc])

  const imagesSvg = <svg id='imagesSvg' xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M100-120q-24.75 0-42.375-17.625T40-180v-530q0-12.75 8.675-21.375Q57.351-740 70.175-740 83-740 91.5-731.375T100-710v530h710q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T810-120H100Zm120-120q-24.75 0-42.375-17.625T160-300v-520q0-24.75 17.625-42.375T220-880h215q12.444 0 23.722 5T478-862l62 62h320q24.75 0 42.375 17.625T920-740v440q0 24.75-17.625 42.375T860-240H220Zm0-60h640v-440H515l-80-80H220v520Zm0 0v-520 520Zm284-150-62-73q-5-5-11.5-4.5T419-522l-65 77q-6 8-1.875 16.5T366-420h350q9.208 0 13.104-8Q733-436 728-444L626-580q-5-6-12-6t-12 6l-98 130Z" /></svg>
  const cropFrameSvg = <svg id="cropFrameSvg" xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M226-172q-22.244 0-38.122-15.878T172-226v-121.5q0-4.075 3.193-7.287 3.193-3.213 8-3.213t7.807 3.213q3 3.212 3 7.287V-226q0 12 10 22t22 10h121.5q4.075 0 7.287 3.268 3.213 3.268 3.213 7.425 0 5.307-3.213 8.307-3.212 3-7.287 3H226Zm508 0H613.5q-5.225 0-8.362-3.193-3.138-3.193-3.138-8t3.138-7.807q3.137-3 8.362-3H734q12 0 22-10t10-22v-121.5q0-4.075 3.193-7.287 3.193-3.213 8-3.213t7.807 3.213q3 3.212 3 7.287V-226q0 22.244-15.878 38.122T734-172ZM172-613.5V-734q0-22.244 15.878-38.122T226-788h121.5q4.075 0 7.287 3.268 3.213 3.268 3.213 7.425 0 5.307-3.213 8.307-3.212 3-7.287 3H226q-12 0-22 10t-10 22v120.5q0 5.225-3.268 8.362-3.268 3.138-7.425 3.138-5.307 0-8.307-3.138-3-3.137-3-8.362Zm594 0V-734q0-12-10-22t-22-10H613.5q-5.225 0-8.362-3.193-3.138-3.193-3.138-8t3.138-7.807q3.137-3 8.362-3H734q22.244 0 38.122 15.878T788-734v120.5q0 5.225-3.268 8.362-3.268 3.138-7.425 3.138-5.307 0-8.307-3.138-3-3.137-3-8.362Z" /></svg>
  const uploadFileSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M450-313v-371L330-564l-43-43 193-193 193 193-43 43-120-120v371h-60ZM220-160q-24 0-42-18t-18-42v-143h60v143h520v-143h60v143q0 24-18 42t-42 18H220Z" /></svg>
  const cloudUploadFileSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M250-160q-86 0-148-62T40-370q0-78 49.5-137.5T217-579q20-97 94-158.5T482-799q113 0 189.5 81.5T748-522v24q72-2 122 46.5T920-329q0 69-50 119t-119 50H510q-24 0-42-18t-18-42v-258l-62 62q-9 9-21 8.5t-21-9.5q-9-9-9-21.5t9-21.5l113-113q5-5 10.133-7 5.134-2 11-2Q486-582 491-580q5 2 10 7l114 114q9 9 9 21t-9 21q-9 9-21.5 9t-21.5-9l-62-61v258h241q45 0 77-32t32-77q0-45-32-77t-77-32h-63v-84q0-89-60.5-153T478-739q-89 0-150 64t-61 153h-19q-62 0-105 43.5T100-371q0 62 43.929 106.5Q187.857-220 250-220h110q12.75 0 21.375 8.675 8.625 8.676 8.625 21.5 0 12.825-8.625 21.325T360-160H250Zm230-290Z" /></svg>

  
  return (
    <div id="CropWrapper">
      {/* <input type="file" accept="image/*" onChange={onSelectFile} /> */}

      <div
        className={completedCrop ? "dropzone none" : "dropzone"}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        {
          !!imgSrc ?
            <div
              className='crop-wrapper'
            >
              <ReactCrop
                crop={crop}
                onChange={(_, percentCrop) => setCrop(percentCrop)}
                onComplete={(c) => setCompletedCrop(c)}
                aspect={aspect}
              >
                <div className='image-wrapper'>
                  <img
                    ref={imgRef}
                    // alt="Crop me"
                    src={imgSrc}
                    style={{
                      transform: `scale(${scale}) rotate(${rotate}deg)`,
                    }}
                    onLoad={onImageLoad}
                  />
                </div>
              </ReactCrop>
              {/* <button onClick={() => setIsOpen(false)} className='cropCloseButton'>close</button> */}
            </div>

            : 
             <div id='select-image-wrapper' 
              className={!!imgSrc ? "true" : "false"}>
              <div id='select-image-svg-wrapper'>
                {cropFrameSvg}
                {imagesSvg}
              </div>
              <p id='image-upload-text'>Dosyayı sürükle veya seçmek için tıkla.</p>
              <input
                className='custom-file-input'
                id="image-file-1"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
              <label id="image-file-1-label" for="image-file-1">
                {uploadFileSvg}
                <span>Select file</span>
              </label>
            </div>

        }
      </div>
      {/* <div className="Crop-Controls">
        <div>
          <label htmlFor="scale-input">Scale: </label>
          <input
            id="scale-input"
            type="number"
            step="0.1"
            value={scale}
            disabled={!imgSrc}
            onChange={(e) => setScale(Number(e.target.value))}
          />
        </div>
        <div>
          <label htmlFor="rotate-input">Rotate: </label>
          <input
            id="rotate-input"
            type="number"
            value={rotate}
            disabled={!imgSrc}
            onChange={(e) =>
              setRotate(Math.min(180, Math.max(-180, Number(e.target.value))))
            }
          />
        </div>
        <div>
          <button onClick={handleToggleAspectClick}>
            Toggle aspect {aspect ? 'off' : 'on'}
          </button>
        </div>
      </div> */}

      {!!completedCrop && (
        <>
          <div id='previewCanvas'>
            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: 0,
                height: 0,
              }}
            />
          </div>

          <div className='cropFooter'>
            <button id='selectedCrop' onClick={onDownloadCropClick}>
              {cloudUploadFileSvg}
              <span>resmi yükle</span>
            </button>
            <a
              ref={hiddenAnchorRef}
              download
              style={{
                position: 'absolute',
                top: '-200vh',
                visibility: 'hidden',
              }}
            >
              Hidden download
            </a>
          </div>
        </>
      )}
    </div>
  )
}
