import React, { useEffect, useRef, useState } from 'react'

import { usersStore, userStore, postsStore, dataBaseUserStore, profileStore, modalStore, hadnleProfilePageStore, handleMessagePageStore, usersOnlineStatusStore, navigationStore, pictureSelectorStrore } from "../Store"
import { child, get, onValue, push, ref, set } from 'firebase/database'
import { dataBase, handleSignOut } from '../fireBaseConfig'
import "../ProfilePage.css"

import { dateFormatter, getBirthDateByUid, getProfileBannerByUid, getProfilePhotoByUid, getUsersNameByUid, validatePhoneNumber } from "../utils/utils"
import { Textarea } from '@mui/joy'


const AboutMe = (props) => {
    const { uid, isMyPage } = props
    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const setPage = navigationStore((state) => state.setPage)
    const page = navigationStore((state) => state.page)

    const profileData = hadnleProfilePageStore((state) => state.profileData)
    const setProfileData = hadnleProfilePageStore((state) => state.setProfileData)

    const messageData = handleMessagePageStore((state) => state.messageData)
    const setMessageData = handleMessagePageStore((state) => state.setMessageData)
    const [myMostLikedPosts, setMyMostLikedPosts] = useState([])

    // const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)

    const [isEditBio, setIsEditBio] = useState(false)
    const [isEditContact, setIsEditContact] = useState(false)
    const [aboutMe, setAboutMe] = useState("")
    const [hoby, setHoby] = useState("")
    const [education, setEducation] = useState("")


    const [contactInfo, setContactInfo] = useState("")
    const [bioInfos, setBioInfos] = useState("")

    const [birthDay, setBirthDay] = useState("")


    const saveBio = () => {
        if (isEditBio) {
            set(ref(dataBase, 'users/' + `${uid}/bio`), {
                aboutMe: aboutMe,
                hoby: hoby,
                education: education
            });
        }
        setIsEditBio(!isEditBio)
    }
    const saveContactInfo = () => {
        if (isEditContact && validatePhoneNumber(contactInfo)) {
            set(ref(dataBase, 'users/' + `${uid}/contact`), {
                phone: contactInfo,
            });
        } else if (!validatePhoneNumber(contactInfo)) {
            setModal({
                type: "error",
                status: true,
                message: "Telefon numarası hatalı"
            })
            getContactInfo()
        }
        setIsEditContact(!isEditContact)
    }

    const usersOnlineStatus = usersOnlineStatusStore((state) => state.usersOnlineStatus)
    const setUsersOnlineStatus = usersOnlineStatusStore((state) => state.setUsersOnlineStatus)


    useEffect(() => {
        const bioRef = child(ref(dataBase), 'users/' + `${uid}/bio`)

        get(bioRef).then((snapshot) => {
            if (snapshot.exists()) {
                // 
                setBioInfos(snapshot.val())
            }
        }).catch((err) => {
            setBioInfos("")
            console.error(err)
        });
        getContactInfo()

        getBirthDateByUid(uid).then(data => setBirthDay(data))

    }, [uid])

    function getContactInfo() {
        const contactRef = child(ref(dataBase), 'users/' + `${uid}/contact`)

        get(contactRef).then((snapshot) => {
            if (snapshot.exists()) {
                // 
                setContactInfo(snapshot.val().phone)
            }
        }).catch((err) => {
            setContactInfo("")
            console.error(err)
        });
    }

    useEffect(() => {
        setAboutMe(bioInfos?.aboutMe)
        setHoby(bioInfos?.hoby)
        setEducation(bioInfos?.education)
    }, [bioInfos])

    const [profileName, setProfileName] = useState("")

    const profilePhotoRef = useRef("")
    const profileBannerRef = useRef("")




    const closeSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M480-448.001 266.923-234.924q-6.692 6.693-15.807 6.885-9.116.192-16.192-6.885-7.077-7.076-7.077-15.999 0-8.923 7.077-16L448.001-480 234.924-693.077q-6.693-6.692-6.885-15.807-.192-9.116 6.885-16.192 7.076-7.077 15.999-7.077 8.923 0 16 7.077L480-511.999l213.077-213.077q6.692-6.693 15.807-6.885 9.116-.192 16.192 6.885 7.077 7.076 7.077 15.999 0 8.923-7.077 16L511.999-480l213.077 213.077q6.693 6.692 6.885 15.807.192 9.116-6.885 16.192-7.076 7.077-15.999 7.077-8.923 0-16-7.077L480-448.001Z" /></svg>




    const editSvg = <svg xmlns="http://www.w3.org/2000/svg" height="48" viewBox="0 -960 960 960" width="48"><path d="M180-12q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h405l-60 60H180v600h600v-348l60-60v408q0 24-18 42t-42 18H180Zm300-360Zm182-352 43 42-285 284v86h85l286-286 42 42-285 286q-8 8-19.5 13t-23.5 5H390q-13 0-21.5-8.5T360-282v-115q0-12 5-23.5t13-19.5l284-284Zm171 168L662-724l100-100q17-17 42.5-17t42.5 18l84 85q17 18 17 42.5T930-654l-97 98Z" /></svg>
    const birthCakeSvg = <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.625 11.25C11.832 11.25 11.75 10.375 10.5742 10.375C9.39844 10.375 9.28906 11.25 8.52344 11.25C7.78516 11.25 7.67578 10.375 6.5 10.375C5.32422 10.375 5.1875 11.25 4.44922 11.25C3.68359 11.25 3.60156 10.375 2.39844 10.375C1.22266 10.375 1.14062 11.25 0.375 11.25V9.0625C0.375 8.35156 0.949219 7.75 1.6875 7.75H2.125V3.8125H3.875V7.75H5.625V3.8125H7.375V7.75H9.125V3.8125H10.875V7.75H11.3125C12.0234 7.75 12.625 8.35156 12.625 9.0625V11.25ZM12.625 14.75H0.375V12.125C1.55078 12.125 1.63281 11.25 2.39844 11.25C3.16406 11.25 3.27344 12.125 4.44922 12.125C5.625 12.125 5.73438 11.25 6.5 11.25C7.26562 11.25 7.34766 12.125 8.52344 12.125C9.72656 12.125 9.80859 11.25 10.5742 11.25C11.3125 11.25 11.4219 12.125 12.625 12.125V14.75ZM3 3.375C2.50781 3.375 2.125 2.99219 2.125 2.5C2.125 1.65234 3 1.87109 3 0.75C3.32812 0.75 3.875 1.57031 3.875 2.28125C3.875 3.01953 3.46484 3.375 3 3.375ZM6.5 3.375C6.00781 3.375 5.625 2.99219 5.625 2.5C5.625 1.65234 6.5 1.87109 6.5 0.75C6.82812 0.75 7.375 1.57031 7.375 2.28125C7.375 3.01953 6.96484 3.375 6.5 3.375ZM10 3.375C9.50781 3.375 9.125 2.99219 9.125 2.5C9.125 1.65234 10 1.87109 10 0.75C10.3281 0.75 10.875 1.57031 10.875 2.28125C10.875 3.01953 10.4648 3.375 10 3.375Z" fill="#FA6342" />
    </svg>
    const userSvg = <svg width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.5 7.75C4.55859 7.75 3 6.19141 3 4.25C3 2.33594 4.55859 0.75 6.5 0.75C8.41406 0.75 10 2.33594 10 4.25C10 6.19141 8.41406 7.75 6.5 7.75ZM8.93359 8.625C10.957 8.625 12.625 10.293 12.625 12.3164V13.4375C12.625 14.1758 12.0234 14.75 11.3125 14.75H1.6875C0.949219 14.75 0.375 14.1758 0.375 13.4375V12.3164C0.375 10.293 2.01562 8.625 4.03906 8.625H4.50391C5.10547 8.92578 5.78906 9.0625 6.5 9.0625C7.21094 9.0625 7.86719 8.92578 8.46875 8.625H8.93359Z" fill="#FA6342" />
    </svg>

    const puzzelPartSvg = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.3164 8.65234C15.3555 8.65234 15.875 9.52734 15.875 10.457C15.875 11.3594 15.3281 12.125 14.3711 12.125C13.2773 12.125 12.9766 11.1406 11.9922 11.1406C10.3516 11.1406 11.2812 14.4219 11.2812 14.4219C9.88672 14.4219 6.33203 15.4062 6.33203 13.7383C6.33203 12.7539 7.34375 12.4531 7.34375 11.3867C7.34375 10.4023 6.55078 9.88281 5.64844 9.88281C4.69141 9.88281 3.89844 10.4023 3.89844 11.4141C3.89844 12.5625 4.99219 13.0273 4.99219 13.6562C4.99219 15.5703 0.125 14.4219 0.125 14.4219V5.31641C0.125 5.31641 4.91016 6.4375 4.91016 4.55078C4.91016 3.92188 4.0625 3.42969 4.0625 2.30859C4.0625 1.26953 4.91016 0.75 5.86719 0.75C6.76953 0.75 7.5625 1.29688 7.5625 2.25391C7.5625 3.34766 6.55078 3.62109 6.55078 4.60547C6.55078 6.875 11.9375 4.6875 11.9375 4.6875C11.9375 4.6875 10.4336 9.52734 12.0742 9.52734C12.6758 9.52734 13.168 8.65234 14.3164 8.65234Z" fill="#515365" />
    </svg>
    const mortarBoardSvg = <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2852 3.49609C17.5586 3.60547 17.75 3.85156 17.75 4.125C17.75 4.42578 17.5586 4.67188 17.2852 4.78125L9.57422 7.54297C9.19141 7.67969 8.78125 7.67969 8.39844 7.54297L3.20312 5.68359C2.875 6.03906 2.62891 6.47656 2.51953 6.96875C2.71094 7.10547 2.875 7.35156 2.875 7.625C2.875 7.89844 2.73828 8.14453 2.54688 8.30859L3.20312 12.3828C3.25781 12.6562 3.03906 12.875 2.79297 12.875H1.17969C0.933594 12.875 0.714844 12.6562 0.769531 12.3828L1.42578 8.30859C1.23438 8.14453 1.125 7.89844 1.125 7.625C1.125 7.26953 1.34375 6.96875 1.64453 6.83203C1.75391 6.28516 2 5.79297 2.32812 5.35547L0.6875 4.78125C0.414062 4.67188 0.25 4.42578 0.25 4.125C0.25 3.85156 0.414062 3.60547 0.6875 3.49609L8.37109 0.734375C8.75391 0.597656 9.16406 0.597656 9.54688 0.734375L17.2852 3.49609ZM9.84766 8.36328L13.8398 6.94141L14.25 10.8516C14.25 11.9727 11.8711 12.875 9 12.875C6.07422 12.875 3.75 11.9727 3.75 10.8516L4.13281 6.94141L8.125 8.36328C8.39844 8.47266 8.69922 8.5 9 8.5C9.27344 8.5 9.57422 8.47266 9.84766 8.36328Z" fill="#515365" />
    </svg>

    const readCss = {
        width: "100%",
        background: "transparent",
        border: "0px solid #E4E4E4",
        outlineStyle: "none",
    }
    const editCss = { width: "100%", background: "#EDF2F6", border: "1px solid #E4E4E4" }

    return (
        <div id={"bio-side"}>
            <div className={"bio-side-box left"}>
                <div className={"titleRow"}>
                    <span className='right'></span>
                    <span className='bottom'></span>
                    <h1 className='bio-title'>Profil Bilgileri</h1>
                </div>

                <div className="editable-text-area-wrapper">
                    <div className={"icon-and-title"}>
                        {userSvg}
                        <h1>Hakkımda</h1>
                    </div>
                    <Textarea
                        autoCorrect={"on"}
                        autoComplete={"of"}
                        minRow={1}
                        maxRow={5}
                        value={aboutMe || "Hakkımda bilgisi bulunmuyor."}
                        sx={isMyPage && isEditBio ? editCss : readCss}
                        readOnly={isEditBio && isMyPage ? false : true}
                        onChange={isEditBio && isMyPage ? (e) => setAboutMe(e.target.value) : null}
                    />

                    {
                        isMyPage
                            ? <button className={isEditBio ? "editButton active" : "editButton"} onClick={() => saveBio()}>{editSvg}</button>
                            : null
                    }

                </div>

                <div className="editable-text-area-wrapper">
                    <div className={"icon-and-title"}>
                        {birthCakeSvg}
                        <h1>Doğum Günü</h1>
                    </div>
                    <Textarea
                        rows={1}
                        sx={readCss}
                        value={birthDay}
                        readOnly={true}
                    />
                </div>
            </div>
            <div className={"bio-side-box right row"}>
                <div className={"titleRow"}>
                    <span className='right'></span>
                    <span className='bottom'></span>
                    <h1 className='bio-title'>Genel Bakış</h1>
                </div>

                <div className={"textAreaWrapper"}>
                    <div className="editable-text-area-wrapper">
                        <div className={"icon-and-title"}>
                            {puzzelPartSvg}
                            <h1>Hobiler</h1>
                        </div>
                        <Textarea
                            autoCorrect={"on"}
                            autoComplete={"of"}
                            minRow={1}
                            sx={isEditBio ? editCss : readCss}

                            maxRow={5}
                            rows={window.innerWidth <= 600 ? 1 : 3}
                            value={hoby || "Hobi bilgisi bulunmuyor."}
                            readOnly={isEditBio ? false : true}
                            onChange={isEditBio ? (e) => setHoby(e.target.value) : null}
                        />
                        {
                            isMyPage
                                ? <button className={isEditBio ? "editButton active" : "editButton"} onClick={() => saveBio()}>{editSvg}</button>
                                : null
                        }
                    </div>

                    <div className="editable-text-area-wrapper">
                        <div className={"icon-and-title"}>
                            {mortarBoardSvg}
                            <h1>Eğitim</h1>
                        </div>
                        <Textarea
                            autoCorrect={"on"}
                            autoComplete={"of"}
                            rows={1}
                            sx={isEditBio ? editCss : readCss}
                            value={education || "Eğitim bilgisi bulunmuyor."}
                            readOnly={isEditBio ? false : true}
                            onChange={isEditBio ? (e) => setEducation(e.target.value) : null}
                        />
                        {
                            isMyPage
                                ? <button className={isEditBio ? "editButton active" : "editButton"} onClick={() => saveBio()}>{editSvg}</button>
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}


export default AboutMe 