import "./loginMissingInfoModal.css";
import headerPng from "../assets/login-missing-info-modal-header.png"
import { useEffect, useState } from "react";
import VanillaDateInput from "./VanillaDateInput";
import SimpleSelectInput from "./SimpleSelectInput";
import { cities, isValidAndNotEmptyString, isValidObject } from "../utils/utils";
import { set, ref } from "firebase/database";
import { dataBase } from "../fireBaseConfig";
import { modalStore, pendingStore } from "../Store";

const LoginMissingInfoModal = (props) => {


    const { userWithMissingInfo, navigate } = props
    const setModal = modalStore((state) => state.setModal)
    const pending = pendingStore((state) => state.pending)
    const setPending = pendingStore((state) => state.setPending)


    const genderArr = [{ value: "male", label: "erkek" }, { value: "feMale", label: "kadın" }]

    const [birthDate, setBirthDate] = useState("")
    const [gender, setGender] = useState("")
    const [city, setCity] = useState("")

    const isOkToGo = isValidObject(city) && isValidObject(gender) && isValidAndNotEmptyString(birthDate)
        
    const saveUserToDatabase = async () => {
        setPending(true)
      
        if (
            isValidObject(userWithMissingInfo) && isValidAndNotEmptyString(userWithMissingInfo.uid) &&
            isValidAndNotEmptyString(userWithMissingInfo.displayName) &&
            isValidAndNotEmptyString(userWithMissingInfo.email) &&
            isValidObject(gender) && isValidAndNotEmptyString(gender.value) &&
            isValidObject(city) && isValidAndNotEmptyString(city.value.toString()) &&
            isValidAndNotEmptyString(userWithMissingInfo.email)
        ) {
            try {
               
                await set(ref(dataBase, 'users/' + `${userWithMissingInfo.uid}`), {
                    name: userWithMissingInfo.displayName,
                    email: userWithMissingInfo.email,
                    gender: gender.value,
                    birthDate: birthDate,
                    city: city.value.toString(),
                    uid: userWithMissingInfo.uid,
                });
                navigate("/home")
                setModal({
                    type: "success",
                    status: true,
                    message: "Giriş başarılı..."
                })
            } catch (err) {
                console.error("google login err: ", err)
                setModal({
                    type: "error",
                    status: true,
                    message: "Bir hata oluştu !"
                })
            }

        } else {
            setModal({
                type: "error",
                status: true,
                message: "Bir hata oluştu !"
            })
        }

        setPending(false)
    }
    return (
        isValidObject(userWithMissingInfo)
            ? <div className={"loginMissingInfoModalWrapper"}>
                <div className={"loginMissingInfoModal"}>
                    <div className={"header"}>
                        <img src={headerPng} />
                        <div className="titleAndPWrapper">
                            <h1>Profil Bilgileri</h1>
                            <p>Eksik profil bilgilerinizi girin</p>
                        </div>
                    </div>
                    <div className="body">
                        <div className="labelAndInput">
                            <label>Doğum Tarihi</label>
                            <VanillaDateInput set={setBirthDate} />
                        </div>
                        <div className="labelAndInput">
                            <label>Şehir</label>
                            <SimpleSelectInput width={window?.innerWidth <= 500 ? "100%" : 200} options={cities} set={setCity} selectedOption={city.label || "Seçmek için tıklayın"} />

                        </div>
                        <div className="labelAndInput">
                            <label>Cinsiyet</label>
                            <SimpleSelectInput width={window?.innerWidth <= 500 ? "100%" : 200} options={genderArr} set={setGender} selectedOption={gender.label || "Seçmek için tıklayın"} />
                        </div>
                        <button onClick={() => saveUserToDatabase()} disabled={pending || !isOkToGo} className="saveButton">Kaydet</button>
                    </div>
                </div>
            </div>
            : null
    )
}

export default LoginMissingInfoModal;