
import React, { useEffect, useRef, useState } from 'react'

import { usersStore, userStore, postsStore, dataBaseUserStore, profileStore, modalStore, hadnleProfilePageStore, handleMessagePageStore, usersOnlineStatusStore, navigationStore, pictureSelectorStrore, myPostsStore } from "../Store"
import { child, equalTo, get, onValue, orderByChild, orderByValue, push, query, ref, set } from 'firebase/database'
import { dataBase, handleSignOut } from '../fireBaseConfig'
import "../ProfilePage.css"

import Post from '../Post'
import { Box, Skeleton, Tab } from '@mui/material'
import { TabList, TabContext, TabPanel } from '@mui/lab'
import { isValidAndNotEmptyArray, isValidArray, isValidObject } from '../utils/utils'
import Pagination from './Pagination'
import PostPlaceHolder from './PlaceHolders/PostPlaceHolder'



const ProfilePagePosts = (props) => {

    const { uid } = props

    const user = userStore((state) => state.user)
    const setUser = userStore((state) => state.setUser)

    const profileData = hadnleProfilePageStore((state) => state.profileData)
    const setProfileData = hadnleProfilePageStore((state) => state.setProfileData)


    const messageData = handleMessagePageStore((state) => state.messageData)
    const setMessageData = handleMessagePageStore((state) => state.setMessageData)

    const [myMostLikedPosts, setMyMostLikedPosts] = useState([])

    // const modal = modalStore((state) => state.modal)
    const setModal = modalStore((state) => state.setModal)

    useEffect(() => {
        const postsRef = query(ref(dataBase, `posts/`),
                orderByChild("userUid"),
                equalTo(uid)
            )
        onValue(postsRef, (snapshot) => {
            if (snapshot.exists()) {

                setMyPosts(snapshot.val())
            } else {

            }
        })
    }, [uid])

    const myPosts = myPostsStore(state => state.myPosts)
    const setMyPosts = myPostsStore(state => state.setMyPosts)

    useEffect(() => {
        const a = Object.entries(myPosts).filter(item => item[1].hasOwnProperty("like")) || []

        if (a.length > 0) {
            setMyMostLikedPosts(a)
        } else {
            setMyMostLikedPosts([])
        }
    }, [myPosts])





    const dateFormatter = (timeStamp) => {
        return new Date(timeStamp).getDate() + "/" + (new Date(timeStamp).getMonth() + 1) + "/" + new Date(timeStamp).getFullYear() + "    " + new Date(timeStamp).getHours() + ":" + new Date(timeStamp).getMinutes() + ":" + new Date(timeStamp).getSeconds()
    }

    const [whichPost, setWhichPost] = useState("all")
    const [page, setPage] = useState(1)

    return (

        <div className='second-row'>

            <div className={"header"}>
            <div className={"sortingButtonsWrapper"}>
                <button
                    onClick={() => setWhichPost("popular")}
                    className={whichPost === "popular" ? 'sortingButton active' : "sortingButton"}
                >EN POPÜLER</button>
                <button
                    onClick={() => setWhichPost("all")}
                    className={whichPost === "all" ? 'sortingButton active' : "sortingButton"}
                >EN YENİLER</button>
            </div>
            {
                isValidObject(myPosts) ?
                    <Pagination
                        count={ whichPost === "all" ? Math.ceil(Object.keys(myPosts).length / 5) : Math.ceil(myMostLikedPosts.length / 5)}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={5}
                    />
                    : null
            }
            </div>
            
            {/* <div className={"customSwitchWrapper"}>
                <span className={whichPost === "all" ? "customSwitchSelector" : "customSwitchSelector right"}></span>
                <button className={whichPost === "all" ? "customSwitchButton active" : "customSwitchButton"} onClick={() => setWhichPost("all")}>Hepsi</button>
                <button className={whichPost === "popular" ? "customSwitchButton active" : "customSwitchButton"} onClick={() => setWhichPost("popular")}>En beğenilenler</button>
            </div> */}
            {
                whichPost === "all"
                    ? <div id='my-posts-side'>
                        {
                            user && user.uid ?
                                <>
                                    <div className={"postsScrollView"}>
                                        <div className='fadeEffect'></div>
                                        {/* <p style={{ color: "#fff" }}>{user.isLoggedIn ? "Hazır" : "Yükleniyor"}</p> */}
                                        {
                                            isValidObject(myPosts) ?
                                                (
                                                    Object.entries(myPosts).filter((item, index) => index <= (page) * 5 - 1 && index > (page - 1) * 5 - 1).map((post) => {
                                                       
                                                       return <>
                                                            <Post key={post[0]} userUid={post[1].userUid} postUid={post[0]} postMessage={post[1]?.post} postTime={dateFormatter(post[1]?.date)} userName={post[1]?.userName} />
                                                        </>
                                                    })
                                                ) : <PostPlaceHolder howMany={10} />
                                                
                                        }
                                    </div>
                                </> :
                                null
                        }
                    </div>
                    : <div id='my-best-posts-side'>
                        {
                            user && user.uid ?
                                <>
                                    <div className={"postsScrollView"}>
                                        <div className='fadeEffect'></div>
                                        {/* <p style={{ color: "#fff" }}>{user.isLoggedIn ? "Hazır" : "Yükleniyor"}</p> */}
                                        {
                                            isValidAndNotEmptyArray(myMostLikedPosts) ?
                                                (
                                                    myMostLikedPosts.map((post) => {
                                                        return <>
                                                            <Post key={post[0]} userUid={post[1].userUid} postUid={post[0]} postMessage={post[1]?.post} postTime={dateFormatter(post[1]?.date)} userName={post[1]?.userName} />
                                                        </>
                                                    })
                                                ) : <PostPlaceHolder howMany={10} />
                                        }
                                    </div>
                                </> :
                                null
                        }
                    </div>
            }
        </div>
    )
}




export default ProfilePagePosts